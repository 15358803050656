<template>
<div class="footer"
     style="color: white !important; background-color: black !important;"
     v-if="!user">
  <div class="row mx-auto" style="max-width: 987px;">

    <div class="col-6 px-4" style="color: white !important; background-color: black !important;">
      <h3 class="text-white">Structure of Success</h3>
      <p class="text-white">
<!--        <a href="https://charlygoodman.com/" class="text-white">-->
<!--          <img src="./CG_Logo.png" width="34">-->
<!--        </a> -->
        Charly Goodman explains how to use the Structure of Success.</p>
      <ul class="text-left">
        <li>Find out what your dream is.</li>
        <li>Make a plan to go after it.</li>
        <li>Enjoy the process of realizing your dream.</li>
      </ul>
      <div class="left-space" style="margin-left: 89px;">
        <p><a href="https://charlygoodman.com/archive/84" class="text-white">1. Guideline</a></p>
        <p><a href="https://charlygoodman.com/archive/72" class="text-white">2. Habits</a></p>
        <p><a href="https://charlygoodman.com/archive/79" class="text-white">3. Milestones</a></p>
        <p><a href="https://charlygoodman.com/archive/76" class="text-white">4. Timeline</a></p>
        <p><a href="https://charlygoodman.com/archive/634" class="text-white">5. Process</a></p>
        <p><a href="https://charlygoodman.com/archive/738" class="text-white">6. Best Day Loop</a></p>
        <p class="text-white">Download ALL Material for Free.</p>
        <p>
          <a href="https://charlygoodman.com/" class="text-white">
          <img src="./CG_Logo_Text_White.png" width="233">
          </a>
        </p>
         <p>
          <a href="https://charlygoodman.com/" class="text-white">
          <img src="./insta.png" width="34">&ensp;@CharlyGoodman90
          </a>
        </p>

      </div>
    </div>

    <div class="col-6" >

      <p class="w-100 text-center mx-auto">
          <img src="./2021_12_supersuccesslogo.png" width="144">
        </p>

      <!-- Begin Mailchimp Signup Form -->
      <div id="mc_embed_signup" style="color: white !important; background-color: black !important;"
      class="w-100">
      <form action="https://mysupersuccess.us5.list-manage.com/subscribe/post?u=42f84de0fd657c4fb5f0dd5cc&amp;id=b8a0926921"
            method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
            class="validate mx-auto w-100" target="_blank" novalidate>
          <div id="mc_embed_signup_scroll" class="mx-auto text-center">
        <label for="mce-EMAIL"><h3>Subscribe to Newsletter</h3></label><br>
        <input type="email" value="" name="EMAIL" class="email form-control" id="mce-EMAIL"
               placeholder="email address" required>
          <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
          <div style="position: absolute; left: -5000px;" aria-hidden="true">
            <input type="text" name="b_42f84de0fd657c4fb5f0dd5cc_b8a0926921"
                   tabindex="-1" value="">
          </div>
          <div class="clear my-3">
            <input type="submit" value="Subscribe" name="subscribe"
                   id="mc-embedded-subscribe" class="menu-button mx-auto">
          </div>
          </div>
      </form>
        <div class="mt-5" style="justify-content: end">
          <router-link class="text-white" :to="{'name': 'PrivacyPolicy'}">Privacy Policy</router-link>
          <br><br>
          <router-link class="text-white" :to="{'name': 'GeneralTermsAndConditions'}">General Term & Conditions</router-link>
        </div>
      </div>

    </div>

  </div>
  <div style="background-color: black" class="mx-auto w-100">
    <p class="text-white p-4 text-center">Customer Service: charles@mysupersuccess.com</p>
  </div>



</div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
a {
  text-decoration-line: none !important;
}

.footer {
    /*height: calc(0vh + 8rem);*/
    min-height: 610px !important;
    width: 100%;
    /*position: sticky;*/
    bottom: 0;
    padding-top: 2rem;
    color: white !important;
    background-color: black;
  }

left-space {
  margin-left: 3.4rem !important;
}
</style>