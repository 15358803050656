<template>
   <navbar-home></navbar-home>
  <div style="background-color: black" class="vh-100">

  <h1 class="py-5 text-white">Subscription</h1>
    <div>
      <stripe-card
        ref="elementRef"
        :pk="pulishableKey"
        @token="tokenCreated"
      />
    </div>
  </div>
</template>

<script>
import StripeCard from './StripeCard';
import NavbarHome from "@/components/navbar/NavbarHome";

export default {
  components: {
    StripeCard,
    NavbarHome,
  },
  data () {
    this.pulishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    return {
      loading: false,
      token: null,
      lineItems: [
        {
          price: 'some-price-id', // The id of the recurring price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: 'your-success-url',
      cancelURL: 'your-cancel-url',
      pulishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
    };
  },
  methods: {
    submit () {
      // this will trigger the process
      this.$refs.elementRef.submit();
    },
    tokenCreated (token) {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
      console.log(token);
      // handle the token
      // send it to your server
    },
  },
};
</script>