<template>


  <div class="menubar m-0 pb-4" v-if="$store.state.board && isLoggedIn && user">
    <div class="w-100" style="justify-content: space-between;
       display: flex; align-items: center !important;">
      <router-link :to="{'name': 'Home'}"
                   style="align-items: start;
                   margin-left: 8px; margin-top: 8px;">
        <img alt="Vue logo"
             class="animated fadeIn"
             style="opacity: 0.55; padding-top: 0;"
             src="../assets/2021_12_supersuccesslogo.png"
             width="69">
      </router-link>
      <BoardNavigation class="pb-3 mx-4" />

      <ToggleButton v-model="toggle" style="" class="mx-4" />
      <MenuTimer v-if="this.$store.state.isMenuTimer" />

      <router-link :to="{name: 'Timeline', params: $route.params}"
                style="color: black; text-decoration: none;"
                             class="p-3 px-4"
      >
        <button class="menu-button" style="font-weight: 600 !important;"
                v-if="$route.params.id">
            Timeline
        </button>
      </router-link>

      <router-link :to="{'name': 'Setting'}"
                   class="pt-2"
                   style="color: black; padding-right: 3rem; padding-left: 3rem;">
        <svg  width="1.8em" height="1.8em" viewBox="0 0 16 16" class="bi bi-gear-fill"
              fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 0 0-5.86 2.929 2.929 0 0 0 0 5.858z"/>
      </svg>
    </router-link>

    <router-link :to="{'name': 'Logout'}" class="" style="color: black">
        <svg width="2.1em" height="2.1em" viewBox="0 0 16 16" class="bi bi-door-open" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M1 15.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM11.5 2H11V1h.5A1.5 1.5 0 0 1 13 2.5V15h-1V2.5a.5.5 0 0 0-.5-.5z"/>
          <path fill-rule="evenodd" d="M10.828.122A.5.5 0 0 1 11 .5V15h-1V1.077l-6 .857V15H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117z"/>
          <path d="M8 9c0 .552.224 1 .5 1s.5-.448.5-1-.224-1-.5-1-.5.448-.5 1z"/>
        </svg>
    </router-link>

    </div>
    <add-board :show="showBoard || $store.state.boardCount == 0" :setShow="setShowBoard" />

    <MessageBox :show="isTimerMessage" :setShow="setIsTimerMessage" comment="Work Timer is running. Try again later." type="alert" draggable="false" />
  </div>
</template>

<script>
import AddBoardPanel from './AddBoardPanel';
import ToggleButton from './ToggleButton';
import BoardNavigation from "./BoardNavigation";
import MenuTimer from './MenuTimer';
import MessageBox from './MessageBox';
import { mapGetters } from 'vuex'

export default {
  name: 'MenuBar',
  data: () => ({
    showBoard: false,
    isToggle: false,
    isTimerMessage: false,
  }),
  components: {
    "add-board": AddBoardPanel,
    ToggleButton,
    BoardNavigation,
    MenuTimer,
    MessageBox,
  },
   mounted() {
      this.$store.dispatch('getBoard', this.$route.params.id)
    },
  computed: {
    ...mapGetters(['isLoggedIn', 'isPremiumUser', 'user']),
     shouldShowBoard() {
      if (!this.user) {
        return false;
      }
      if(this.$store.state.boardCount == 0) {
        return true
      }
      return this.showAddBoard
    },
    toggle: {
      get() {
        return this.isToggle;
      },
      set(value) {
        this.$store.dispatch('resetToggle', value);
        this.isToggle = value;
      }
    }
  },
  created() {
      if(this.user) {
         this.$store.dispatch('getBoardStatus')
      }
    },
  methods: {
    setShowBoard(val) {
      this.showBoard = val;
    },
    setBoard(val) {
      if(!this.$store.state.isWorkCount) {
        const killId = setInterval(() => { for(let i = killId; i > 0; i--) clearInterval(i); }, 0);
        document.title = 'Kanban Board';
        this.$store.dispatch('changeBoard', val);
        this.$router.push(`/board/${val}`);
        this.$store.dispatch('resetMenuTimer', 0);
      } else this.setIsTimerMessage(true);
    },
    setIsTimerMessage(val) {
      this.isTimerMessage = val;
    },
  },
};
</script>