<template>
  <MenuBar />
  <div v-if="user"
       style="position: relative; top: 8%; margin-right: auto; margin-left: auto;"
       class="font-weight-bold table-width"
  >

    <h2 class="headline pt-5 pb-2 font-weight-bold">App Setting</h2>

      <table class="mx-auto table-width">
        <tr>
          <td colspan="2"><br>How much do you have to work to
              achieve your long-term goals?
          </td>
        </tr>
        <tr class="">
          <td class="text-left" style="min-width:160px;">
            <span class="font-weight-bold">Worktime</span>
          </td>
          <td class="text-right">
            <hustle-time></hustle-time>
          </td>
        </tr>
        <tr class="">
          <td class="text-left my-4" colspan="2" style="min-width:233px;">
            <table>
              <tr>
                <td><report-options></report-options></td>
                <td><span class="font-weight-bold mt-4">Report: Yes, I want time tracking.</span></td>
              </tr>
            </table>


          </td>
        </tr>
      </table>

      <h2 class="headline pt-5 pb-2 font-weight-bold">Account Setting</h2>
      <table
        class="mx-auto table-width"
      >
        <tr class="">
          <td class="text-left" style="min-width:160px;">Username:</td>
          <td class="text-right" style="min-width:160px;">{{user.name}}</td>
        </tr>
        <tr class="">
          <td class="text-left  pb-3 pt-3">Email:</td>
          <td class="text-right">{{user.email}}</td>
        </tr>
        <tr class="">
          <td class="text-left">Premium Until:</td>
          <td class="text-right" v-if="isPremiumUser">
            {{formatDate(user.setting.premium_until,'DD-MMMM-YYYY')}}
          </td>

          <div style="min-width: 233px; overflow: visible">
            <router-link
                v-if="user && user.setting.stripe_subscription_status === 'cancelled' "
              class="menu-button"
              to="/stripe"
          >
            renew Subscription
          </router-link>
          </div>
          <div style="min-width: 233px;">
            <router-link v-if="user && !isPremiumUser"
                class="menu-button"
                to="/stripe"
            >
              Subscription
            </router-link>
          </div>

          <button @click="cancel()"
                  v-if="user && isPremiumUser || user && isPremiumUser && !user.setting.stripe_subscription_status === 'cancelled'"
              class="btn btn-xs w-100 text-black-50"
          >
            <small>cancellation</small>
          </button>
        </tr>
        <tr class="">
          <td class="text-left">Subscription Status:</td>
          <td class="text-right" v-if="isPremiumUser">
            {{user.setting.stripe_subscription_status}}
          </td>
        </tr>
      </table>

    <table class="w-100 table-width mx-auto text-center">
      <tr class="mx-auto text-center">
          <td>
            <p class=" text-center">Change Password:</p>
            <input class="form-control mx-auto my-2"
                   style="border-radius: 6px; max-width: 210px;"
                   v-model="password"/>
            <input class="form-control mx-auto my-2"
                   style="border-radius: 6px; max-width: 210px;"
                   v-model="password2"/>
           <div class="mx-auto" style="width: 360px;">
              <p class="alert-success bg-green-500 w-100 px-4 rounded"
                    v-if="message">{{message}}</p>
               <p class="alert-danger bg-red-500 w-100 px-4 rounded"
                    v-if="error">{{error}}</p>
           </div>
            <input type="submit" value="save" @click="saveUser()"
                   class="menu-button " style="color: black"
                   :disabled="password.length < 8 || password !== password2"
                   >
          </td>
        </tr>
    </table>



  </div>
</template>

<script>
import {mapGetters} from "vuex";
import api from "../api";
import MenuBar from "../components/MenuBar";
import HustleTime from "@/components/UI/HustleTime";
import ReportOptions from "@/components/UI/ReportOptions";

export default {
  name: 'Setting',
  components: {
    MenuBar,
    HustleTime,
    ReportOptions,
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'isPremiumUser', 'user', 'hustleTime']),
    stripeElements () {
      return this.stripe.elements();
    },
  },
  data() {
    return {
      password: '',
      password2: '',
      error: false,
      message: false,
      subscriptionCancelled: false,
      // stripe_subscription_id : this.$store.state.user.setting.stripe_subscription_id,
      // stripe_subscription_id : 'this.$store.state.user.setting.stripe_subscription_id'
    }
  },
  methods: {
    async saveUser(){
      this.error= false
      this.message= false

      const data = {

        email: this.user.email,
        name: this.user.name,
        password: this.password,
        password2: this.password2,
        // ...this.user,
      }
      console.log(data)
      // await api.updateMe(data)
      try {
        const response = await api.updateMe(data)
          .then((response) => {
            // debugger // eslint-disable-line
            if (response.status === 200) {
              this.message = 'Successful password changed.';
              this.password= '';
              this.password2= '';
              setTimeout(() => {
                this.message = false;
              }, 3400);

              // throw new Text('Successful deletion.');
            } else {
              throw new Error('Could not change password.');
            }
          })
        console.log('Response:::', response);
      }  catch(error) {
          console.log(error);
          this.error = 'Could not delete.';
          this.loading = false;
          setTimeout(() => {
                this.error = false;
          }, 3400);
          return;
      }
    },
    async cancel() {
      // TODO: error-msg
      const data = this.$store.state.user.setting.stripe_subscription_id
      // const data = this.stripe.stripe_subscription_status.id
      console.log(data)
      await api.cancelSubscription(data);
      this.user.setting.stripe_subscription_status = 'cancelled';
    }
  }
}
</script>

<style>
.table-width {
  padding: 2rem;
  margin: 2rem;
  background-color: whitesmoke;
  /*margin-top: 2.1rem;*/
  border-radius: 13px;
  max-width: 600px;

}


</style>