import { createApp } from 'vue'
// import { createStore} from "vuex"
import ElementPlus from 'element-plus'
import App from './App.vue'
import store from './store'
import router from './router'
import './assets/style.css'
import './assets/bootstrap.css'
import axios from 'axios'
import VueHighcharts from 'vue-highcharts';
import Highcharts from 'highcharts';
import loadGantt from 'highcharts/modules/gantt.js';
import loadDrag from 'highcharts/modules/draggable-points.js';
loadGantt(Highcharts);
loadDrag(Highcharts);
import * as HC_exporting from 'highcharts/modules/exporting';
HC_exporting(Highcharts);
import DateFormat from './plugins/date';
import { StripePlugin } from '@vue-stripe/vue-stripe';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";


const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
};

router.beforeEach((to, from, next) => {
    console.log(to)
    if(to.matched.some(record => record.meta.requiresLogin)){
        if(!store.getters.isLoggedIn){
            next({ name: 'Home' })
        }else{
            next()
        }
    }else{
        next()
    }
})

axios.interceptors.response.use(response => {
   return response;
}, error => {
  if (error.response.status === 401) {
   document.location.href='#/'
  }
  return error;
});

// import Vue from 'vue';
// Vue.config.errorHandler = (err, vm, info) => {
//   // err: error trace
//   // vm: component in which error occured
//   // info: Vue specific error information such as lifecycle hooks, events etc.
//
//   // TODO: Perform any custom logic or log to server
//
// };

const app = createApp(App)
app.use(ElementPlus)
app.use(router)
app.use(store)
app.use(DateFormat)
app.use(VueHighcharts, {Highcharts})
app.use(StripePlugin, options);
app.mount('#app')

