<template>
  <div class="row">
    <div class="col-xl-4 col-lg-5 col-md-6 col-8 text-center mx-auto"
    style="max-width: 540px; min-width: 360px;">
      <h3 class="text-white">Choose you Subscription</h3>

        <select
               id="currentProductId"
               name="currentProductId"
               v-model="currentProductId"
                class="row text-center"
                style="overflow: auto; background-color: black; border: none !important;"
                size="2" required>

          <option class="subscription-input mx-auto text-center bg-white px-3 mx-3"
                  type="radio"
                  :class="productSelectedMonthly ? 'productSelected' : '' "
                  :checked="true"
                  @click="aboMonthly"
                  name="abo"
                  id="abo-monthly"
                  :value="monthly">
            Monthly 5.99 USD
          </option>

          <option class="subscription-input mx-auto text-center bg-white px-3 mx-3"
                  type="radio"
                  :class="productSelectedYearly ? 'productSelected' : '' "
                  name="abo"
                  @click="aboYearly"
                  id="abo-yearly"
                  :value="yearly">
            Yearly 39.90 USD
          </option>

        </select>

      <br>

      <label class="text-white">Card Number</label>
      <div id="card-number" class="subscription-input" style="background-color:white;"></div>
      <label class="text-white">Card Expiry</label>
      <div id="card-expiry" class="subscription-input" style="background-color:white;"></div>
      <label class="text-white">Card CVC</label>
      <div id="card-cvc" class="subscription-input" style="background-color:white;"></div>
      <div id="card-error" class="text-white"></div>

      <div v-if="error"
         class="alert-danger bg-red-500 text-red-900 rounded-xl py-4">
        <p class="w-100 px-4 ">{{error}}</p>
      </div>
      <div v-if="message"
         class="alert-danger bg-green-500 text-green-900 rounded-xl py-4">
        <p class="w-100 px-4 ">{{message}}</p>
      </div>

      <button class="menu-button btn-warning mt-5" id="custom-button"
              @click="createToken">
        Subscribe <div v-if="loading" class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span></div>
      </button>

    </div>
  </div>
</template>

<script>
import api from '@/api'
import {mapGetters} from "vuex";
// import {  Form, } from 'vee-validate';
// import { extend } from 'vee-validate';
// import * as rules from 'vee-validate/dist/rules';
// Object.keys(rules).forEach(rule => {
//   extend(rule, rules[rule]);
// });
export default {
  components: {
      // Form,
      // Field,

      // // ErrorMessage: VeeValidate.ErrorMessage,
  },
  data () {
    return {
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      yearly: process.env.VUE_APP_YEARLY,
      monthly: process.env.VUE_APP_MONTHLY,
      loading: false,
      error : false,
      message: false,
      productSelectedYearly: false,
      productSelectedMonthly: false,
      product: process.env.VUE_APP_MONTHLY,

    };
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'user']),
    stripe() {
      return window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
    },
    stripeElements () {
      return this.stripe.elements();
    },
    currentProductId: {
            get: function() {
              // debugger // eslint-disable-line
                return this.product
            },
            // set: function() {
            //     // Communicate the change to parent component so that selectedValue can be updated
            //     this.$emit("change", this.label)
            // }
        }
  },
  mounted () {
    // Style Object documentation here: https://stripe.com/docs/js/appendix/style
    const style = {
      base: {
        iconColor: "#c4f0ff",
        color: 'black',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
              ":-webkit-autofill": {
                color: "#fce883"
              },
        fontWeight: 600,
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee"
      },
    };
    this.cardNumber = this.stripeElements.create('cardNumber', { style });
    this.cardNumber.mount('#card-number');
    this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
    this.cardExpiry.mount('#card-expiry');
    this.cardCvc = this.stripeElements.create('cardCvc', { style });
    this.cardCvc.mount('#card-cvc');
  },
  beforeUnmount () {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },
  methods: {
    aboMonthly () {
      return this.product === process.env.VUE_APP_YEARLY
    },
    aboYearly () {
      return this.product === process.env.VUE_APP_MONTHLY
    },
    async createToken () {
      this.loading = true;

      try {
        const { token, error } = await this.stripe.createToken(this.cardNumber);
        const customerResponse = await api.createCustomer({'token': token.id})
        // .then((i) => {
        //     this.loading = true;
        //     this.error = false;
        //     console.log(i)
        //       if(i.status === 200) {
        //         console.log('successful customer created')
        //       } else {
        //         debugger //eslint-disable-line
        //         this.loading = false;
        //         this.error = 'Some field is not correct'
        //         setTimeout(() => {
        //             this.error = false;
        //         }, 3400);
        //         // return this.error = 'Some field is not correct';
        //       }
        //   })
        const customerId = customerResponse.data.customer.id
        console.log(error)
          const subscriptionResponse = await api.createSubscription({
            customerId: customerId,
            priceId: this.currentProductId
          })
          .then((i) => {
            this.loading = true;
            this.error = false;
            console.log(i)
              if(i.status === 200) {
                console.log('successful subscription created')
                // this.message = 'successful subscription created'
                // setTimeout(() => {
                //   this.message = false;
                // }, 2100)
                // throw(i.response.data)
              }
          })
          console.log('subscriptionResponse::', subscriptionResponse)
        } catch (error) {
          // debugger //eslint-disable-line
        console.log('error::',error)
          this.loading = false;
          this.error = 'Some field is not correct'
          setTimeout(() => {
              this.error = false;
          }, 3400);
          return;
        }

      await this.$store.dispatch('fetchMe')
      this.$router.push('/');
      this.loading = false;
    },
  }
};
</script>

<style scoped>
#custom-button {
}

.subscription-input {
  margin: .55rem;
  padding: .55rem;
  border: 1px solid grey;
  border-radius: 13px;
}

#card-error {
  color: red;
}

select {
  display:table-row;
  border: none !important;
}
option {
    display: table-cell;
}
.productSelected {
  font-size: 1.2rem;
  font-weight: 600;
  background-color: orange;
}
</style>