<template>
  <div>

    <router-view />
  </div>
</template>

<script>


export default {
  components: {

  },
  name: 'App',
  mounted () {
    document.title = 'Board';
    //this.$store.dispatch('init');
    this.$store.dispatch('getBoard')
    this.$store.dispatch('fetchMe')
  },
}

</script>

