<template>
  <div id="memberWindowId"
       v-if="memberWindow"
       class="mx-auto">

      <p class="alert-success bg-green-500 w-100 px-4 rounded"
            v-if="message">Successful Super Success User added.</p>
      <p class="alert-danger bg-red-500 w-100 px-4 rounded"
            v-if="error">{{error}}</p>

    <form @submit.prevent="submitNewTeamMember" v-if="boardMaster">
      <div class="row">

        <div class="bg-green-500 text-green-900 w-100 rounded alert-success"
             id="teamMemberAlert" style="visibility: hidden !important;">
              Super Success Board-Member added successfully!
        </div>

<!--        <div v-if="error"-->
<!--           class="alert-danger bg-red-500 w-100 px-4 rounded">-->
<!--              {{errorMsg}}-->
<!--        </div>-->



        <label class="mx-auto w-100 mb-2">Enter the email from an existing Super Success User:</label>
        <div class="col-12">
          <input id="new-team-member"
             type="text"
             style="width: 300px;"
             class="form-control mx-auto"
             name="team-member"
             placeholder="Charly@Goodmann.com"
             v-model="teamMembers.member"
            :key="member">
        </div>
        <div class="col-12 mx-auto">
          <button type="submit"
              class="menu-button my-3"
              style="bottom: 3px; right: 3px;"
          >
            Add new Super Success User to Board
          </button>
        </div>
      </div>
    </form>
    <div v-else class="text-center mx-auto">
      <p class="text-black-50 col-10 text-center">The Board-owner can only add new Super Success User.</p>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "MemberWindow",
  components: {

  },
  data() {
    return {
      teamMembers: this.$store.state.teamMembers,
      currentBoard: Number(this.$route.params.id),
      // email: this.user,
      // user: this.$store.state.user.id,
      memberWindow: true,
      errorMsg: '',
      error: false,
      loading: false,
      message: false,
      isMessage: false,
      showAddTeamMember: false,
    }
  },
  computed: {
      ...mapGetters(['isLoggedIn', 'isPremiumUser', 'user', 'boardMaster']),
  },
  methods: {
    async submitNewTeamMember() {
      this.loading = true;
      this.error = false;
      console.log('this should be the requested.',this.teamMembers.member)
      try {
        const response = await this.$store.dispatch('addTeamMember', {
          email: this.teamMembers.member,
          board: this.currentBoard,
        })
        .then((response) => {
              if (response === true) {
                this.message = true;
                setTimeout(() => {
                  this.message = false;
                }, 3400);
                this.teamMembers.user = '';
                throw new Text('Successful Super Success User added.');
              } else {
                throw new Error('Could not add Super Success User.');
              }
            })
           this.loading = false;

          console.log('Response:::', response);
          // this.teamMembers.user = ''

          //     'Only the Board-Master can add new Board-Members which are Super Success User.' +
          //     'Board-Members can only delete there own board-membership. ' +
          //     'Or some other errors happend during the sending of email'

      } catch(error) {
          console.log(error);
          this.error = error.message;
          this.loading = false;
          setTimeout(() => {
                this.error = false;
                // window.location.reload()
          }, 1200);
          return;
        }
        this.teamMembers.user = ''
    },
    closeWindow() {
      this.memberWindow = !this.memberWindow;
    },
  },


  mounted() {
    this.$store.dispatch('getTeamMember', this.$route.params.id)
    // this.$store.dispatch('getTeamMember', {'id': this.$route.params.id, 'expands': ['member']});
  },
}
</script>

<style scoped>
.alert{
    width: 400px;
    margin-right: auto;
    margin-left: auto;
}

#member-list {
  max-width: 500px;
}
.memberWindowStyle {
  display: none;
  position: fixed;
  height: 100vh;
  margin: auto;
}
</style>