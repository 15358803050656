<template>
  <div class="mx-auto">

    <button
        id="memberListId"
        @click="closeWindow"
        class="btn float-right"
        style="position: static; "
    >x</button>




      <h3>Board Members:</h3>
      <oops v-if="hasError"></oops>
      <div id="deleteTeamMemberAlert" v-if="hasError"
           class="alert-danger bg-red-500 w-100 px-4 rounded">
              {{errorMsg}}
        </div>
    <p class="alert-success bg-green-500 w-100 px-4 rounded"
       v-if="message">Successful deletion.</p>
    <p class="alert-danger bg-red-500 w-100 px-4 rounded"
       v-if="error">{{error}}</p>


    <div :key="key" style="text-align: left;" class="pl-3"
             v-for="key in $store.state.teamMembers"
         :v-model="teamMembers"
    >
      <div class="row">
            <div class="col-10">
<!--          accountInUSD     if board = board   -->
              <small>User: {{key.invited_by.email}} invited Member:{{key.member.email}}</small>
<!--{{  returnBoardsTeamMembers  }}-->
<!--              {{ key}}-->
            </div>
            <div  class="col-1 float-right">
               <i class="fa fa-trash remove-icon float-right"
                  @click="deleteChat(key.id)"
                 />
            </div>
            <br>
      </div>
    </div>

<message-box></message-box>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import MessageBox from "@/components/MessageBox";
import Oops from "@/components/UI/Oops";


export default {
  name: "MemberList",
  components: {
    MessageBox,

    Oops,
  },
  props: ['memberWindow'],
  data() {
    return {
      errorMsg: '',
      error: false,
      loading: false,
      hasError: false,
      message: false,
      // boardMaster: false,

    }
  },
  methods: {
    async deleteChat(id) {
      this.loading = true;
      this.hasError = false;
      this.message = false;

      try {
         const response = await this.$store.dispatch('deleteTeamMember', id)
            .then((response) => {
              if (response === true) {
                this.message = true;
                setTimeout(() => {
                  this.message = false;
                }, 3400);
                throw new Text('Successful deletion.');
              } else {
                throw new Error('Could not delete.');
              }
            })
           this.loading = false;
            console.log('Response:::', response);
            // console.log('response::', response.status);
      } catch(error) {
          console.log(error);
          this.error = error.message;
          this.loading = false;
          setTimeout(() => {
                this.error = false;
          }, 3400);
          return;
        }
    },
    closeWindow() {
      this.$emit('member-window', this.memberWindow);
    },
  },
  mounted() {
    this.$store.dispatch('getCurrentBoard', this.$route.params.id);
    this.$store.dispatch('getTeamMember', this.$route.params.id)
    // this.$store.dispatch('getTeamMember', {'id': this.$route.params.id, 'expands': ['member']});
  },
  watch: {
    '$route.params.id'() {
      // this.$store.dispatch('getCurrentBoard', this.$route.params.id);
      this.$store.dispatch('getTeamMember', this.$route.params.id)
    },
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'isPremiumUser', 'user', 'boardMaster']),
    teamMember: {
      get() {
        const teamMember = this.$store.state.teamMember;
        const currentBoard = Number(this.$route.params.id);
        return teamMember.filter((val) => (val.board === currentBoard));
      },
      set(value) {
        this.$store.dispatch('getTeamMember', value);
      },
      returnBoardsTeamMembers() {
        console.log('hi')
      },
    }
  }
}
</script>

<style scoped>
#member-list {
  max-width: 500px;
}
.hidden {
  display: none !important;
}
</style>