<template>
  <navbar-home></navbar-home>
    <div class="front-page flex h-screen"
         style="background-color: black; color: white; min-height: 100vh;">


		<section class="second">
            <div class="p-6 form-section">
                <section class="form-title">
                    <h1 class="form-title--pre text-gray-600 pt-5">Renew Password</h1>
                    <p class="text-white my-3 text-center">We send you an email, activate the link please to re-set your password.</p>
                </section>

                <section class="form-login mt-6c">
                    <form v-on:submit.prevent="login" class="login-form"> 
                        <p v-if="incorrectAuth" class="bg-red-500 text-red-900 rounded-xl py-2 mb-6 alert-danger"
                           style="width: 400px">
                          <i class="fas fa-exclamation-triangle mr-2"></i>Invalid password</p>
                      <div v-if="msg" class="alert alert-info">{{msg}}</div>

                        <input class="form-control p-3 mx-auto text-center" style="width: 270px;" v-model="email" />

                        <div class="form-bottom flex items-center justify-between">
                            <button @click="renewPassword" type="submit"
                                    class="menu-button btn-warning mt-3" id="loginBtn">
                                <span class="ml-2" v-if="loading">
                                  <i class="fas fa-circle-notch load-icon"></i>  renewing password
                                </span>
                              Get Reset token</button>
                        </div>
                    </form>
                </section>
            </div>
		</section>
	</div>
</template>

<script>
import NavbarHome from "@/components/navbar/NavbarHome";
    export default {
        name: "RenewPassword",
        components: {
          NavbarHome,
        },
        data() {
            return {
              email: '',
              msg: false,
              loading: false,
            }
        },
        methods: {
            async renewPassword(){
                this.loading = true
                await this.$store.dispatch('userRenewPassword', {
                    email: this.email
                })
                this.loading = false;
                this.msg = 'please check your email for a renew link';
                localStorage.clear();

            }
        }
    }
</script>

<style scoped>
    .form-login{
        flex: 2;
        padding: 1rem 2rem;
        margin-right: auto;
        margin-left: auto;
    }

    .form-component{
        width: 400px;
        height: 50px;
        position: relative;
        margin-bottom: 1.5rem;
    }

    .label-placeholder{
        pointer-events: none;
        position: absolute;
        top: 12px;
        left: 20px;
        transition: 0.2s ease all;
    }

    .input-field{
        width: 400px;
        height: 50px;
        background: #Ffffff;
        border: 0;
        border-radius: 17px;
        padding: 0em 1rem;
        outline: none;
    }

    input:focus ~ .label-placeholder,
    input:not(:focus):valid ~ .label-placeholder{
        top: -18px;
        font-size: 16px;
    }

    .form-bottom{
        width: 400px;
        margin-right: auto;
        margin-left: auto;
    }
    
    .load-icon{
        margin-right: .5rem;
        animation: rotateLoader 1s infinite;
    }
</style>