<template>
    <table>
      <tr>
        <td>
          <button class="modal-step-button" @click="onHustleTime(0)">-</button>
        </td>
        <td>
          <input type="number" v-model.number="user.setting.work_duration" >
        </td>
        <td>
          <button class="modal-step-button" @click="onHustleTime(1)">+</button>
        </td>
      </tr>
    </table>
</template>

<script>
import api from "@/api";
import {mapGetters} from "vuex";

export default {
  name: "BreakTime",
  computed: {
    ...mapGetters(['isLoggedIn', 'isPremiumUser', 'user',]),
    workCount: {
      get() {
        return this.user ? this.user.setting.work_duration : 10
      },
      set(val) {
        this.user.setting.work_duration = val
      }
    }
  },
  methods: {
    async saveUser() {
      const data = {
        user: this.user.setting.id,
        work_duration: this.workCount,
      }
      await api.updateHustleTime(data)
    },
    onHustleTime(op) {
      op === 0 ?
          this.workCount = this.workCount > 0 ? this.workCount - 1 : this.workCount :
          this.workCount++;
      this.$store.dispatch('changeBreakCount', this.workCount);
      this.saveUser()
    },
  }
}
</script>

<style scoped>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number]{
    -moz-appearance: textfield;
}
table {
  min-width: 210px;
  max-width: 333px;
}
</style>