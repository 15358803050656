<template>
  <div class="card-block" :style="this.$store.state.priority[item.color - 1].color" :title="item.description ? item.description : ''">
    <div class="card-top">
      <i class="fa fa-trash task-icon" @click="setIsMessage(true)" title="Remove" />
      <i class="fa fa-navicon task-icon" @click="setShowTask(true)" title="Select" />
      <i class="fa fa-clock-o task-icon" @click="onTimer" title="Timer" />
<!--      <i class="fa fa-clock-o task-icon" @click="onSendEmail" title="SendTodo" />-->
<!--      <router-link :to="'/tasks/task/' + id">Send Todo with Email</router-link>-->
      <h5 class="card-title">
<!--        <span class="card-id">{{item.id}}</span>-->
        {{item.title}}
      </h5>
    </div>
    <div v-if="this.$store.state.isToggle" class="card-detail">
      <div class="card-description font-weight-bold">
        {{item.description ? item.description : ''}}
      </div>

      <div v-for="(element, index) in activeMilestones" :key="index"
           class="card-milestone">
        <li :taskMilestones="item.milestones">{{element.title}}</li>
      </div>


      <div  v-for="(element, index) in completedMilestones" :key="index"
            class="card-milestone"
            style="padding-left: 0rem !important;"
      :taskMilestones="item.milestones">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-check-lg mb-1" viewBox="0 0 16 16">
            <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"/>
          </svg>
          {{element.title}}
      </div>

      <div class="card-time">
        <div>{{item.start ? 'Start: ' + item.start : ''}}</div>

        <div
            @click="checkDeadline"
            :v-model="item.end"
            :class="[ugentcyClass, ugentcyWarningClass]">
          {{item.end ? 'End: ' + item.end : ''}}
        </div>
      </div>
      <div class="card-time">
        <div>Estimated: {{item.estimate_time ? formatDuration(item.estimate_time) : ''}}</div>
        <div>Worktime: {{item.worktime ? formatDuration(item.worktime) : '00:00'}}</div>
      </div>
    </div>
  </div>
  <add-task
      :show="showTask"
      :setShow="setShowTask"
      :id="item.id"
      :boardStatusId="item.status"
  />
  <MessageBox :show="isMessage" :setShow="setIsMessage" comment="Do you delete this task?" type="yesno" draggable="false" :run="deleteItem" />
  <MessageBox :show="isTimerMessage" :setShow="setIsTimerMessage" comment="Work Timer is running. Try again later." type="alert" draggable="false" />
<!--  <MessageBox :show="isSetEmailMessage" :setShow="setEmailMessage" comment="Send Todo with email to:" type="email" draggable="false" :run="sendEmail" />-->
</template>

<script>
import MessageBox from './MessageBox';
import AddTaskPanel from './AddTaskPanel';
import moment from "moment";

export default {
  name: 'TaskLaneItem',
  props: ['item'],
  components: {
    "add-task": AddTaskPanel,
    MessageBox
  },
  data() {
    return {
      isMessage: false,
      isTimerMessage: false,
      showTask: false,
      isFat: false,
      isWarning: false,
      isSetEmailMessage: false,
      setEmailMessage: false,
    };
  },
  beforeMount() {
  this.$nextTick(function () {
      const threeDaysBefore = moment().add(3, "days").format("YYYY-MM-DD");
      const today = new Date().toISOString().slice(0, 10);
      if (this.item.end <= today) {
        return this.isFat = true;
      } else if (threeDaysBefore >= this.item.end) {
        return this.isWarning = true
      } else {
        this.isWarning = false;
        return this.isFat = false;
      }
    })
  },
  beforeUpdate() {
    this.$nextTick(function () {
      const threeDaysBefore = moment().add(3, "days").format("YYYY-MM-DD");
      // console.log('threeDaysBefore', threeDaysBefore)
      const today = new Date().toISOString().slice(0, 10);
      // console.log(today)
      // console.log('Endtime', this.item.end)
      if (this.item.end <= today) {
        return this.isFat = true;
      } else if (threeDaysBefore >= this.item.end) {
        return this.isWarning = true
      } else {
        this.isWarning = false;
        return this.isFat = false;
      }
    })
  },

  computed: {
    activeMilestones() {
      return this.item.milestones.filter(i => !i.complete)
    },
    completedMilestones() {
      return this.item.milestones.filter(i => i.complete)
    },
    ugentcyClass() {
      return {superUrgent: this.isFat};
    },
    ugentcyWarningClass() {
      return {urgent: this.isWarning};
    },
  },
  methods: {
    async deleteItem() {
      await this.$store.dispatch('deleteTask', this.item.id);
      this.$store.dispatch('getBoardStatus', this.$route.params.id)
    },
    setIsMessage(val) { 
      this.isMessage = val; 
    },
    setIsTimerMessage(val) { 
      this.isTimerMessage = val; 
    },
    setShowTask(val) {
      this.showTask = val;
    },
    onTimer() {
      if(!this.$store.state.isWorkCount) {
        const killId = setInterval(() => { for(let i = killId; i > 0; i--) clearInterval(i); }, 0);
        this.$store.dispatch('resetMenuTimer', {id: this.item.id, title: this.item.title});
        this.$store.commit('setCurrentTask', this.item)
      } else this.setIsTimerMessage(true);
    },
    // checkDeadline() {
    //
    //   const threeDaysBefore = moment().add(3, "days").format("YYYY-MM-DD");
    //   console.log('threeDaysBefore', threeDaysBefore)
    //   console.log('Endtime', this.item.end)
    //   if (threeDaysBefore >= this.item.end) {
    //     this.isWarning = true
    //   }
    // }
  },
};
</script>

<style>
.superUrgent {
  font-weight: 900;
  color: white;
  text-shadow: 2px 2px 3px darkorange;
  /*padding: 0.5rem;*/
  /*background-color: #1b1e21;*/
  /*border-radius: 0.25rem;*/
}
.urgent {
  font-weight: 700;
  color: darkorange;
  text-shadow: 2px 2px 3px #1b1e21;
}
.active {
  border-color: red;
  background-color: salmon;
}
</style>