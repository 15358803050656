<template>
  <div class="chat-window-paint mx-auto">
    <div class="" style="width: 98%;">
<!--      id="chatWindowId"      -->
      <button
              @click="closeWindow"
              class="btn text-right float-right"
              style="position: static; "
          >x</button>
    </div>
<br>
<!--    // TODO: write Test for add TeamChat-->
          <p class="alert-danger bg-red-500 w-100 px-4 rounded"
            v-if="error">{{error}}</p>

<!--      id="v-for-teamChat"-->
      <div class="w-100">
          <chat-content :returnAnchorTrue="false"  :id="anchor" :key="chat"></chat-content>
      </div>

    <form @submit.prevent="submitChatData">
      <div class="row">
        <div class="col-10 mx-auto">
           <input id="new-team-chat"
             type="text"
             name="team-chat"

             class="form-control ml-3 pl-3 mb-3"
             placeholder="Super Success Chat"
             v-model="teamChat.chat"
             :key="chat">
        </div>
        <div class="col-2 mx-auto">
          <a href="#anchor">
            <button type="submit"
                    class="btn-outline-primary btn w-75"
            >
             Post
            </button>
          </a>
        </div>
      </div>
    </form>
  </div>


</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import ChatContent from "@/components/Chat/ChatContent";

export default {
  name: "ChatWindow",
  props: ['returnAnchorTrue'],
  components: {
    ChatContent
  },

  data() {
    return {
      teamChat: this.$store.state.teamChat,
      author: this.$store.state.user.name,
      currentBoard: Number(this.$route.params.id),
      error: false,
      loading: false,
      message: false,
    }
  },

  mounted() {
    this.$store.dispatch('getTeamChat', this.$route.params.id);
    // console.log('this.chat', this.$store.state.teamChat);
  },

  methods: {
        async submitChatData() {
          this.loading = true;
          this.error = false;
          try {
            const response = await this.$store.dispatch('addTeamChat', {
            board: this.currentBoard,
            author: this.user.name,
            chat: this.teamChat.chat,
            author_email: this.user.email,
            })
            .then((response) => {
                  // debugger //eslint-disable-line
                  if (response === true) {
                    this.message = true;
                    setTimeout(() => {
                      this.message = false;
                    }, 3400);
                    this.teamChat.chat = ''
                    this.$store.dispatch('getTeamChat', this.$route.params.id);
                    throw new Text('Successful Team-Chat added.');
                  } else {
                    throw new Error('Can not delete this Team-Chat.');
                  }
                })
               this.loading = false;

            // this.teamChatCount()
              console.log('Response:::', response);
          } catch(error) {
              console.log(error);
              this.error = error.message;
              this.loading = false;
              setTimeout(() => {
                    this.error = false;
              }, 3400);
              return;
            }
            this.teamChat.chat = ''

        },
    closeWindow() {
      this.$emit('chat-window', this.chatWindow);
    },
    moment: function () {
      return moment();
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'isPremiumUser', 'user']),
  },
}




</script>

<style>
.chatWindowStyle {
  display: none;
}
/*.anchor {*/
/*    !* allow the final child to be selected as an anchor node *!*/
/*    overflow-anchor: revert;*/
/*    !* anchor nodes are required to have non-zero area *!*/
/*    height: 1px;*/
/*}*/
.anchor {
    /* allow the final child to be selected as an anchor node */
    overflow-anchor: revert !important;
    /* anchor nodes are required to have non-zero area */
    height: 1px;
}
</style>