<template>

  <div>
<!--     v-if="teamMembers"   -->
    <div class="pl-3" v-if="myRequests.length > 0">
      <h3>TeamBoard Requests</h3>
      <p :key="key" v-for="key in myRequests"
           :v-model="teamMembers" class="text-black">


          User-email:{{ key.invited_by.email }} want  {{ key.member.email }}  to add too Board
          <span class="font-weight-bolder" style="color: red">{{ key.board.board_title }}</span>
          <br>


<!--        Only the member can join / decline / block the user     -->
          <span>
          <button @click="joinTeamBoard(key.id)"
                  class="btn btn-outline-dark m-2">
            join
          </button>
          <button @click="declineAccess(key.id,)"
                  class="btn btn-outline-dark m-2">
            decline
          </button>
          <button @click="saveBlockedUser(key.invited_by.id)"
                  class="btn btn-outline-dark m-2">
            block the user
          </button>
        </span>
      </p>

    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import api from "@/api";

export default {
  name: "BoardAccess",
  data() {
    return {
        teamMembers: this.$store.state.teamMembers,
        myRequests: []
    }
  },
  methods: {
    joinTeamBoard(id) {
      console.log('teammember.id,', id)
      const data = {
        'id': id,
        'user_agreement': true,
        'request_seen': true,
      }
      this.$store.dispatch('updateTeamMember', data);
      setTimeout(() => {
                window.location.reload()
          }, 1200);
    },
    declineAccess(id) {
      const data = {
        'id': id,
        'user_agreement': false,
        'request_seen': true,
      }
      this.$store.dispatch('updateTeamMember', data);
      setTimeout(() => {
                window.location.reload()
          }, 1000);
    },
    async saveBlockedUser(id){
      this.error= false
      this.success= false
      console.log('blocked_user: ', id)
      // console.log('user: ', user)
      const data = {
        blocked_user: id,
        user: this.$store.state.user.id
      }
      console.log(data)
      try {
        const response = await api.createBlockedUser(data)
          .then((response) => {
            // debugger // eslint-disable-line
            if (response.status === 200) {
              this.success = 'Successful Blocked User';
              setTimeout(() => {
                this.success = false;
              }, 3400);

              // throw new Text('Successful deletion.');
            } else {
              throw new Error('Could not change password.');
            }
          })
        console.log('Response:::', response);
      }  catch(error) {
          console.log(error);
          this.error = 'Could not delete.';
          this.loading = false;
          setTimeout(() => {
                this.error = false;
          }, 3400);
          return;
      }
      setTimeout(() => {
                window.location.reload()
          }, 1200);
    },

  },
  computed: {
  ...mapGetters(['user', 'boardMaster']),
  },
  async mounted() {
    //this.$store.dispatch('getTeamMember', this.$route.params.id);
    const response = await api.getMyTeamMember(['invited_by', 'board', 'member']);
    this.myRequests = response.data.results
    // this.$store.dispatch('getTeamMember', {'id': this.$route.params.id, 'expands': ['board']});

  },
}
</script>

<style scoped>

</style>