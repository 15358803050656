<template>
  <transition name="modal" v-if="show" >
    <div
         class="modal-mask">
<!--      v-click-away="onClickAway" -->
      <div class="modal-wrapper">
        <div class="modal-container-wide">
          <div class="modal-header">
            <slot name="header">
              <div style="font-family: 'Arial Rounded MT Bold';
              color: black; font-size: 1.61rem; border-bottom: none; padding: 0; margin: 0;
              text-align: center; width: 100% !important;">
                {{this.id ? 'Edit Todo' : 'Add Todo'}}
              </div>
            </slot>
          </div>
          <div class="modal-body">
            <slot name="body">
              <input class="modal-input" type="text" v-model="title" placeholder="Title *">
              <textarea class="modal-input" v-model="description" placeholder="Description" />
              <div class="modal-input-group">
                <p>Start: </p>
                <input class="modal-input" type="date" v-model="startDate" :max="endDate">
              </div>
              <div class="modal-input-group">
                <p>End: </p>
                <input class="modal-input" type="date" v-model="endDate" :min="startDate">
              </div>
              <div class="modal-input-group">
                <p>Estimated Time(h): </p>
                <input class="modal-input" type="number" v-model="estimated" step="1" min="1" >
              </div>
              <div class="modal-input-group">
                <p>Priority: </p>
                <select class="modal-select" v-model="priority" >
                  <option v-for="(value, index) in this.$store.state.priority" :key="index" :value="index + 1"
                          :style="value.color" >
                    {{value.name}}
                  </option>
                </select>
              </div>
<!--              <div class="modal-input-group">-->
<!--                <p>Status:  </p>-->
<!--                <select class="modal-select" v-model="status">-->
<!--                  <option v-for="value in getBoardStatus" :key="value.id" :value="value.id">-->
<!--                    {{value.title}}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->
              <div class="milestones-panel">
                <p>Milestones:</p>
                <draggable v-model="milestones" :group="{ name: 'milestone' }" handle=".drag-icon">
                  <div v-for="(element, index) in milestones" :key="index" class="modal-input-group">
                    <i class="fa fa-ellipsis-v drag-icon" />
                    <input type="checkbox" v-model="element.complete" >
                    <input class="modal-input" v-model="element.title" >
                    <i 
                      class="fa fa-trash remove-icon"
                      @click="() => onDelete(element)"
                    />
                  </div>
                </draggable>
                <div class="modal-input-group">
                  <i class="fa fa-ellipsis-v drag-icon" />
                  <input type="checkbox" disabled>
                  <input v-model="newMileStone" placeholder="Add milestone" class="modal-input" @change="(e) => addNewMilestone(e)">
                  <i class="fa fa-plus" />
                </div>
              </div>
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
<!--              <button class="menu-button menu-button mx-auto text-center"-->
<!--                      @click="close">-->
<!--                Close-->
<!--              </button>-->
              <button class="menu-button menu-button mx-auto text-center"
                      @click="save">
                {{buttonTitle}}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>

  <MessageBox :show="isMessage"
              :setShow="setIsMessage"
              comment="You should input title field!"
              type="alert"
  />

</template>

<script>
import MessageBox from './MessageBox';
import { VueDraggableNext } from 'vue-draggable-next';
// import {mixin as VueClickAway} from "vue3-click-away";
// import VueClickAway from "vue3-click-away";
export default {
  name: 'AddTaskPanel',
  // // mixins: [VueClickAway],
  // directives: {
  //   ClickAway: VueClickAway,
  // },
  props: ['show', 'setShow', 'id', 'statusId', 'taskTitle', 'boardStatusId', 'taskMilestones'],
  components: {
    MessageBox,
    draggable: VueDraggableNext,
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      title: this.taskTitle ? this.taskTitle : '',
      description: '',
      isMessage: false,
      startDate: new Date().toISOString().slice(0, 10),
      endDate: new Date(Date.now() + ( 3600 * 1000 * 24)).toISOString().slice(0, 10),
      estimated: 1,
      priority: 1,
      milestones: [],
      newMileStone: '',
      buttonTitle: this.id ? 'Save' : 'Save',
    };
  },
  watch: {
    show() {
      this.taskTitle ? this.title = this.taskTitle : '';
      // this.milestones ? this.milestones = this.taskMilestones : '';
    }
  },
  computed: {
    // getBoardStatus() {
    //   return this.$store.state.boardStatus.filter(val => val.board === this.$store.state.currentBoard);
    // },
  },
  methods: {
    initFields() {
      this.title = '';
      this.description = '';
      this.startDate = new Date().toISOString().slice(0, 10);
      this.endDate = new Date(Date.now() + ( 3600 * 1000 * 24)).toISOString().slice(0, 10);
      this.estimated = 1;
      this.priority = 1;
      this.setShow(false);
      this.milestones = [];
      // debugger // eslint-disable-line
      this.getData();
    },
    close() {
      this.initFields();
      const unsavedMilestones = this.$store.state.tasks.filter(val => val.description === 'unsaved');
      unsavedMilestones.map(val => this.$store.dispatch('deleteTask', val.id));
    },
    save() {
      if (this.title) {
        const data = {
          title: this.title,
          description: this.description,
          start: this.startDate,
          end: this.endDate,
          estimate_time: this.estimated * 3600,
          color: this.priority,
          milestones: this.milestones,
          status: this.statusId,
        }
        if(this.id) {
          data['id'] = this.id
          this.$store.dispatch('editTask', data);
        } else {
          this.$store.dispatch('addTask', data);
        }
        setTimeout(() => {
          this.initFields();
        }, 1500);
      } else this.setIsMessage(true);
    },
    saveEfficient() {
      if (this.title) {
        const data = {
          // title: this.title,
          description: this.description,
          start: this.startDate,
          end: this.endDate,
          estimate_time: this.estimated * 3600,
          color: this.priority,
          milestones: this.milestones,
          status: this.statusId,
        }
        if(this.id) {
          data['id'] = this.id
          this.$store.dispatch('editEfficientlyTask', data);
        } else {
          this.$store.dispatch('addEfficientlyTask', data);
        }
        setTimeout(() => {
          this.saveEfficient();
        }, 1300);
      } else this.setIsMessage(true);
    },
    setIsMessage(val) { 
      this.isMessage = val; 
    },
    getData() {
      if(this.id) {
        const board = this.$store.state.boardStatus.find(i => i.id === this.boardStatusId);
        const taskData = board.tasks.find(val => val.id === this.id);
        if (!taskData) {
          return
        }
        this.title = taskData.title;
        this.description = taskData.description;
        this.startDate = taskData.start;
        this.endDate = taskData.end;
        this.estimated = this.formatDuration(taskData.estimate_time)
        this.priority = taskData.color;
        this.milestones = taskData.milestones
        // this.milestonesCompleted = taskData.milestones.complete
        // this.status = taskData.status;
      }
    },
    addNewMilestone(e) {
      const title = e.target.value;
      ///if(title.length > 0) this.$store.dispatch('addMilestone', {title, estimate_time: 3600, description: 'unsaved'});
      //e.target.value = '';
      this.newMileStone = ''
      this.milestones.push({title})
      // this.saveEfficient()
    },
    editMilestone(e, id) {
      const title = e.target.value;
      if(title.length === 0) this.$store.dispatch('deleteTask', id);
      // debugger //eslint-disable-line
      // this.saveEfficient()
    },
    onDelete(element) {
      //if(element.checked) this.$store.dispatch('deleteTask', element.id);
      const index = this.milestones.findIndex(i => i == element)
      if (index > -1) {
        this.milestones.splice(index, 1)
      }
      // debugger //eslint-disable-line
      // this.saveEfficient()
    },
  },
};
</script>
