<template>
  <NavbarHome />
<div class="mx-auto col-xl-6 col-lg-6 col-md-10 col-sm-11 col-xs-11 py-5">
  <div class="mx-auto">
    <img class="home-img w-100" style="max-width: 600px;"
         alt="logo" src="../assets/blogtodo.png">
  </div>
	<h1 class="pt-5">Boards</h1>
    <h3 class="text-left">The Board can be topic specific for example:</h3>
    <ol>
      <li class="li">
        <span class="span">
          Administrative
        </span>
      </li>
      <li class="li">
        <span class="span">
          Work
        </span>
      </li>
      <li class="li">
        <span class="span">
          Hobby
        </span>
      </li>
      <li class="li">
        <span class="span">
          Research
        </span>
      </li>
      <li class="li">
        <span class="span">
          Webpage - Blog
        </span>
      </li>
    </ol>

    <p>
		Visualize your next actions with Todo-cards quickly, simple and straight
		forward. Set high goals, target long-term skills for future success.
	</p>
    <!-- Section -->
<!--  <div class="row py-5 w-100 row-max mx-auto">-->
<!--    <div class="col-10 col-md-8 col-xl-6 col-lg-6 mx-auto">-->
        <h1 class="text-center pb-5" >TODOFLOW - STATUS</h1>
        <div class="">
          <img class="home-img" style="width: 100%;" alt="logo"
               src="../assets/todowithstatus.png">
<!--        </div>-->
  <!--                    <div class="w-100">-->
  <!--                      <img class="home-img" alt="logo" src="../assets/todowithstatus.png">-->
  <!--                    </div>-->
        <p class="col-12 mx-auto">
            A Todo starts with an Idea in the "STATUS = TODO". As soon you start
          working, the "STATUS = IN PROGRESS". Your option is to use an additional "Status = WAIT FOR"
          for emails, other peoples job or in general reminder. After all Jobs are finished the Todo is
          moving to "STATUS = DONE".
        </p>
        <br>
        <ol>
          <li class="li">
            <span class="span">
              TODOS is the space where to <b>prepare</b> your work.
            </span>
          </li>
              <li class="li">
            <span class="span">
              In Progress for jobs you <b>currently focus</b> on.
            </span>
          </li>
              <li class="li">
            <span class="span">
              Done show the <b>completed</b> TODOS.
            </span>
          </li>
        </ol>


<!--</div>-->


            </div>
    <h1 class="">What can I do with Todos?</h1>
    <ol>
		<li class="li">
			<span class="span">
				define the target,
			</span>
		</li>
		<li class="li">
			<span class="span">
				organize administrative stuff,
			</span>
		</li>
		<li class="li">
			<span class="span">
				break big goals into small milestones,
			</span>
		</li>
        <li class="li">
			<span class="span">
				set a deadline,
			</span>
		</li>
        <li class="li">
			<span class="span">
				measure your time,
			</span>
		</li>
        <li class="li">
			<span class="span">
				define your priority level,
			</span>
		</li>
        <li class="li">
			<span class="span">
				collect your PDF Report.
			</span>
		</li>
    </ol>

    <div class="w-100">
      <img class="home-img" alt="logo" src="../assets/long-term.png">
    </div>

    <h1 class="pt-5">Order your Mind</h1>
    <p>First of all, overview, order & organize your todos. </p>


    <div class="w-100">
      <img class="home-img" alt="logo" src="../assets/todoboard.png">
    </div>


    <br>

	<ol>
		<li class="li">
			<span class="span">
				TODOS is the space where to <b>prepare</b> your work.
			</span>
		</li>
        <li class="li">
			<span class="span">
				In Progress for jobs you <b>currently focus</b> on.
			</span>
		</li>
        <li class="li">
			<span class="span">
				Done show the <b>completed</b> TODOS
			</span>
		</li>
		<li class="li">
			<span class="span">
				Plan todos for up to 30 Milestones .
			</span>
		</li>
	</ol>



</div>
<!-- Section -->
                    <div class="row py-5 text-center todo-description hidden">
                        <div class=" col-12 col-lg-8 col-xl-8 mx-auto">
                            <h1 class="py-5">TODOS are Simple and let you Focus.</h1>
<!--                          <p>fullboard.png</p>-->
                        </div>
                    </div>

</template>

<script>
import NavbarHome from "@/components/navbar/NavbarHome";
export default {
  name: "BlogTodoBoards",
  components: {
    NavbarHome,
  },
}
</script>

<style scoped>

</style>