import { createRouter, createWebHistory } from "vue-router";
import Board from "./views/Board";
import Home from "./views/Home";
import Paradise from "@/views/Paradise";
import Login from './views/Login';
import Logout from './views/Logout';
import Register from "./views/Register";
import Timeline from "./views/Timeline";
import Stripe from "./views/Stripe";
import Checkout from "./views/Checkout";
import Setting from "./views/Setting";
import RenewPassword from "./views/RenewPassword";
import ResetPassword from "./views/ResetPassword";
import BlogTodoBoards from "@/views/BlogTodoBoards";
import BlogTimerReport from "@/views/BlogTimerReport";
import BlogTimeline from "@/views/BlogTimeline";
import PrivacyPolicy from "@/views/PrivacyPolicy";
import GeneralTermsAndConditions from "@/views/GeneralTermsAndConditions";
// TODO: autoscroll to top after each pagerefresh or change
// import Vue from 'vue'
// import Router from 'vue-router'
// const router = new Router({
//   scrollBehavior (to, from, savedPosition) {
//     // purposely left blank
//   },
//   routes: [
//     {
//       path: "/board/:id",
//       meta: {
//           requiresLogin: true
//       },
//       name: 'BoardDetail',
//       component: Board,
//       props: true,
//     }
//   ]
// });
//
// router.afterEach((to, from) => {
//   window.scrollTo(0, 0);
// });

export default createRouter({

  routes: [
      /*
      FIXME:  add board id as a parameter to Board. When they switch boards then update the id.
      that way you can email someone a link to the board
       */
    {
      path: "/board/:id",
      meta: {
          requiresLogin: true
      },
      name: 'BoardDetail',
      component: Board,
      props: true,
    },
    {
      path: "/",
      name: "Home",
      component: Home
    },
    {
      path: "/paradise/",
      name: "Paradise",
      component: Paradise
    },
    {
      path: "/BlogTodoBoards/",
      name: "BlogTodoBoards",
      component: BlogTodoBoards
    },
    {
      path: "/BlogTimerReport/",
      name: "BlogTimerReport",
      component: BlogTimerReport
    },
    {
      path: "/BlogTimeline/",
      name: "BlogTimeline",
      component: BlogTimeline
    },
    {
      path: "/board/:id/timeline",
      name: "Timeline",
      meta: {
          requiresLogin: true
      },
      component: Timeline
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout
    },
    {
      path: '/stripe',
      name: 'Stripe',
      component: Stripe
    },
    {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
    },
    {
    path: '/generaltermsandconditions',
    name: 'GeneralTermsAndConditions',
    component: GeneralTermsAndConditions
    },
    {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout
    },
    {
    path: '/board/:id/setting',
    name: 'Setting',
    component: Setting,
    props: true,
    },
    {
      path: '/renewpassword/',
      name: 'RenewPassword',
      component: RenewPassword,

    },
    {
    path: '/renewpassword/:token',
    name: 'ResetPassword',
    component: ResetPassword,

  },
  ],
  history: createWebHistory(),
});


