<template>
  <navbar-home></navbar-home>
<!--  <reset-new-password-form></reset-new-password-form>-->
    <div style="background-color: black; color: white; height: 100vh;">

      <h1 class="py-5">Set new password</h1>

      <div v-if="error"
       class="alert-danger bg-red-500 text-black rounded-xl m-5 p-3">
      <h3 class="w-100 px-4 text-center font-weight-bolder">Link not active, please request new rest password link.</h3>
    </div>
    <div v-if="success"
       class="alert-success bg-green text-center font-weight-bolder rounded m-5 p-3">
      <h3 class="w-100 px-4 text-center font-weight-bolder">Successful password-reset, feel free to login at anytime.</h3>
    </div>

    <div class="form-component">
        <input type="password" name="password" v-model="password1" style="width: 270px;"
               class="input-field form-control mx-auto" autocomplete="off" required>
        <span class="label-placeholder">Password</span>
    </div>

    <div class="form-component">
        <input type="password" name="password2" v-model="password2" style="width: 270px;"
               class="input-field form-control mx-auto" autocomplete="off" required>
        <span class="label-placeholder">repeat your Password please</span>
    </div>

    <button :disabled="password1.length < 8 || password1 !== password2"
            @click="setPassword"
    class="mx-auto menu-button btn-warning mt-3">
        <div v-if="loading" class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
      Set password</button>
  </div>

</template>

<script>
import api from '../api.js'
import NavbarHome from "@/components/navbar/NavbarHome";

export default {
  name: "ResetPassword",
  components: {
          NavbarHome,
        },
  data() {
    return {
      password1: '',
      password2: '',
      error: false,
      loading: false,
      success: false,
    }
  },
  methods: {
    async setPassword() {
      this.loading = true;
      this.error = false
      this.success = false
      const response = await api.resetPassword({'token': this.$route.params.token, 'password': this.password1})

        .then((i) => {
          // console.log('i.data',i.data)
          // console.log('response', i.data.status)
          // console.log('response', response.data.status)

          if (i.data.status === "OK") {
            this.loading = false
            this.success = true
            throw(i.data.status)
          }
          if (i.data.status === 401) {
            this.loading = false
            this.success = false
            throw(response.data.status)
          }
        })
        .catch(e => {
          // debugger // eslint-disable-line
          // console.log('error-catch',e.message)
          // console.log('error-catch',e.data)
          if (e === "OK") {
            //TODO: Auto-Login !?
            setTimeout(() => {
                this.$router.push({ name: 'Login' });
            }, 3400)
            return this.error = false
          } else {
            console.log('Error-Response1',e)
            this.error = 'Error: please reset your password and try again.';
            }
            setTimeout(() => {
                this.$router.push({ name: 'RenewPassword' });
            }, 2100);
          return this.error = true

        })

    }
  }
}
</script>

<style scoped>

</style>