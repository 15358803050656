<template>
  <NavbarHome />
		<section class="one-page" style="background-color: black">
            <div class="p-6 form-section">
                <section class="form-title">
                    <h1 class="form-title--pre text-white pt-5">Login</h1>
                    <h3 class="form-title--heading text-white  my-4">Welcome to Super Success</h3>
                </section>

                <section class="form-login pt-5">
                    <form v-on:submit.prevent="login" class="login-form"> 
                        <p v-if="incorrectAuth"
                           class="mx-auto bg-red-500 text-red-900 rounded-xl p-3 mb-6 alert-danger"
                           style="width: 400px">
                          Invalid email or password</p>
                        <div class="form-component">
                            <input type="email" name="email" v-model="email" class="input-field"
                                   autocomplete="off" required>
                            <span class="label-placeholder">Email</span>
                        </div>

                        <div class="form-component">
                            <input type="password" name="password" v-model="password" class="input-field"
                                   autocomplete="off" required>
                            <span class="label-placeholder">Password</span>
                        </div>

                        <div class="form-bottom">
                            <button type="submit"
                                    class="menu-button mt-5" id="loginBtn">
                               <div v-if="loading" class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span></div><span v-else>Login</span>
                            </button>
                        </div>
                      <div class="row mx-auto pt-5 text-white " style="max-width: 400px;">
                          <div class="goto-signup col-12" style="font-size:18px !important;">
                            Don't have an account?
                          </div>
                          <div class="goto-signup col-12" style="">
                              <br>
                                <button class="menu-button text-left" style="background-color: transparent!important;">
                                  <router-link to="/register" exact class="text-black-50"
                                  style="font-size:16px !important;
                                  color: black !important;
                                  text-decoration: none !important;"
                                  >Register
                                  </router-link>
                                </button>
                                <button class="menu-button text-left" style="
                                background-color: transparent!important;">
                                  <router-link to="/renewpassword/" exact class="text-black-50"
                                  style="font-size:16px !important;
                                  color: black !important;
                                  text-decoration: none !important;"
                                  >Forgot Password?
                                  </router-link>
                                </button>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
		</section>
</template>

<script>
import NavbarHome from "@/components/navbar/NavbarHome";

    export default {
        name: "Login",
        components: {
            NavbarHome,
        },
        data() {
            return{
                email: '',
                password: '',
                incorrectAuth: false,
                errorMsg: '',
                error: false,
                loading: false,
            }
        },
        methods: {
          async login(){
                this.loading = true;
                this.incorrectAuth = false;

                try {
                  const response = await this.$store.dispatch('userLogin', {
                    email: this.email,
                    password: this.password
                  })
                  this.loading = false;
                  console.log(response)
                  if (!response) {
                    this.errorMsg = 'invalid login'
                    this.incorrectAuth = true;
                    return;
                  }
                } catch(e) {
                  console.log(e)
                  this.loading = false;
                  this.incorrectAuth = true;
                  return;
                }
                await this.$store.dispatch('fetchMe')
                this.$router.push({ name: 'Home' });
            },
        }
    }
</script>

<style scoped>
.form-login{
        flex: 2;
        padding: 1rem 2rem;
    }

    .form-component{
        width: 400px;
        height: 50px;
        position: relative;
        margin-bottom: 1.5rem;
        margin-right: auto;
        margin-left: auto;
    }

    .label-placeholder{
        pointer-events: none;
        position: absolute;
        top: 12px;
        left: 20px;
        transition: 0.2s ease all;
    }

    .input-field{
        width: 400px;
        height: 50px;
        background: #Ffffff;
        border: 0;
        border-radius: 17px;
        padding: 0em 1rem;
        outline: none;
    }

    input:focus ~ .label-placeholder,
    input:not(:focus):valid ~ .label-placeholder{
        top: -18px;
        font-size: 16px;
    }

    .form-bottom{
        width: 400px;
        margin-right: auto;
        margin-left: auto;
    }
</style>