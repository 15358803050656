<template>
<NavbarHome />

<!--   Pitch    -->
    <div class="row " style="background-color: black; color: #f1f1f1;">
        <div  v-if="user && !isPremiumUser && !isLoggedIn" style="bottom: 0; background-image: linear-gradient(lightgoldenrodyellow,goldenrod,gold); color: black;
            width: 100%; margin: 0; padding: 0; position: absolute; top:120px; height: 100px;">
          <h3>ONLY NOW, 2 weeks free & 40% OFF until 21.03.2021</h3>
        </div>
<!--        <div style="bottom: 0; background-image: linear-gradient(lightgoldenrodyellow,goldenrod,gold); color: black;-->
<!--            width: 100%; margin: 0; padding: 0; position: absolute; top:120px; height: 100px;">-->
<!--          <h3 v-if="user && !isPremiumUser">ONLY NOW, 2 weeks free & 50% OFF (5,99$) until 31.12.2021</h3>-->
<!--        </div>-->
      <div v-if="!user" class="col-10 col-xl-6 col-lg-10 mx-auto ">
        <h3>Todo-App</h3>
        <h1 class="pt-5">Super Success</h1>
        <h3 class="pitchline">Get more Done: Achieve Daily Milestones:</h3>

        <iframe width="560" height="315" src="https://www.youtube.com/embed/fLfYPLKBqos"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>
        </iframe>

        <table class="w-100">
          <tr class="">
            <td class="">
              <div class="  h-100" style="justify-content: center; vertical-align: middle">
                <div class="mx-auto  h-100"
                     style="max-width: 377px; justify-content: center;
                     vertical-align: middle;  ">
                  <h3>We offer 2 weeks free trial:</h3>
                  <ol class="text-left h-100 my-5" type="1"
                      style="vertical-align: middle !important;
                      font-size: 3rem;">
                    <li style="vertical-align: middle !important;">
                      <router-link :to="{'name': 'BlogTodoBoards'}" class="whitetitle">
                        Todo-Boards
                      </router-link>
                    </li>
                    <li style="vertical-align: middle !important;">
                      <router-link :to="{'name': 'BlogTodoBoards'}" class="whitetitle">
                        Team-Boards
                      </router-link>
                    </li>
                    <li style="vertical-align: middle !important;">
                      <router-link :to="{'name': 'BlogTimeline'}" class="whitetitle">
                        Timelines
                      </router-link>
                    </li>
                    <li style="vertical-align: middle !important;" >
                      <router-link :to="{'name': 'BlogTimerReport'}" class="whitetitle">
                        Time Tracking
                      </router-link>
                    </li>
                    <li style="vertical-align: middle !important;">
                      <router-link :to="{'name': 'BlogTimerReport'}" class="whitetitle">
                        PDF-Reporting System
                      </router-link>
                    </li>
                  </ol>
                </div>
              </div>

            </td>
          </tr>
      </table>
      </div>
      <h3 v-if="!user">Stay Motivated, Achieve Milestones, Realise your Dreams</h3>





    <div class="col-8 col-xl-8 col-lg-9 mx-auto pb-5 order-0" >
        <div  v-if="user && isPremiumUser"
              style="position: relative;
              top: 2.1rem; right: 2.1rem;
         color: white; font-weight: 700;">
          <img id="animated-example"
              class="pt-xl-4 pt-3 animated fadeIn"
               style="min-width: 300px; max-width: 590px;"
               alt="logo"
               src="../assets/logo2.png"
               >
          <h3 class="mb-3"
              style="color: white">Choose your Board</h3>
          <ul v-if="$store.state.boardCount >= 0">
            <li :key="board.id" v-for="board in $store.state.board"
            style="list-style: none; color: white;  text-decoration: none !important;">
              <router-link style=""
                  :to="{'name': 'BoardDetail', params: {id: board.id}}">
                <h3 style="max-width:600px; color: black; font-weight: 800; background-color: white !important;
                border-radius: 13px; border: black solid 3px; z-index: 100;
                padding: 8px; text-decoration: none !important;"
                class="btn-light pl-4 pl-sm-3 mx-auto"
                >{{board.board_title}}</h3>
              </router-link>
            </li>
          </ul>
          <div v-if="$store.state.boardCount === 0">
            <button-add-board></button-add-board>
          </div>
        </div>
      </div>
      <div v-if="user && !isPremiumUser" class="vh-100 w-100">



        <div class="mx-auto text-center w-100" style="top: 13vh; position: absolute;">
          <h1 class="pt-5">Welcome to Super Success</h1>
<!--          <h3 class="py-5">Click the button below to Subscribe.Get Your Award NOW.</h3>-->
          <h3>Super Success offers a powerful tool to realize
            the life you wanna live. </h3>
          <div class="card pricing-card mx-auto">
            <div class="card-body pt-2">
<!--                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" fill="currentColor" class="bi bi-box" viewBox="0 0 16 16">-->
<!--                  <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5 8 5.961 14.154 3.5 8.186 1.113zM15 4.239l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"/>-->
<!--                </svg>-->
              <h3 class="font-weight-bolder">Abo</h3>
              <ul class="pt-1">
                <li class="py-2 list">Todo-Boards</li>
                <li class="py-2 list">Team-Boards</li>
                <li class="py-2 list">Timelines</li>
                <li class="py-2 list">Time-Tracking</li>
                <li class="py-2 list">PDF-Reporting System</li>

<!--                <li class="py-2 list">Bonus: Premium Content</li>-->
<!--                <li class="py-2 list">Bonus: Structure of Success PDF-Material</li>-->
<!--                <li class="py-2 list">Bonus: Access to Network</li>-->
<!--                <li class="py-2 list">Bonus: Super Success Podcast Invitation</li>-->

              </ul>
            </div>
          </div>
            <router-link
                class="menu-button mt-5 mx-auto"
                style=""
                to="/stripe"
            >
            Subscribe for 5,99$/month
          </router-link>
          <br><br><br>
          <small class="text-center" style="width: 80%; max-width: 377px !important;  ">* Fell free to cancel within the first 2 weeks without obligation. After your test-period you can
            cancel to the last day of the month.</small>

        </div>


      </div>
<!-- background-image: linear-gradient(315deg, #fff3dc 0%, lightgoldenrodyellow 55%, #fee7ba 89%); -->
  <div class="row w-100 space"
       style="
       background-image: linear-gradient(270deg, #fff3dc 13%, #fee7ba 21%, #fbdb98 55%, #f0c451 89%);

            color: black; margin: 0;">
        <div class="col-11 col-xl-10 col-lg-11 mx-auto">
      <h1 class="mb-5">Advantages</h1>
      <div class="row row-cols-auto mx-auto">
        <div class="col pitch-card mx-auto ">
<!--          <div style="background-color: white;" class="mx-4">-->
              <img src="../assets/TODO_SAMPLE/2022-01-17_TODOBSP.png" class="pitch-img">
              <h3 class="">1. Todos':</h3>
              <ol>
                <li>Organize & Plan your daily life.</li>
                <li>Accomplished Milestones.</li>
                <li>Create Rules for Yourself.</li>
                <li>Make Positive Future Plans.</li>
                <li>Set Deadlines.</li>
              </ol>
<!--          </div>-->
        </div>

        <div class="col pitch-card mx-auto ">
          <img src="../assets/TODO_SAMPLE/AddTeamMember.png" style="object-fit: scale-down;" class="pitch-img">
          <h3 class="">2. Add Team-Members:</h3>
            <ol>

              <li>Build Synergy.</li>
              <li>Team Work.</li>
              <li>Accomplish Together more.</li>
            </ol>
          <small class="text-left">Team Boards are made to cooperate with others and working on the same goals.</small>
        </div>

        <div class="col pitch-card mx-auto ">
          <img src="../assets/timeline_sample.png" class="pitch-img">
          <h3 class="">3. Timelines:</h3>
            <ol>
              <li>Overview Your Project.</li>
              <li>Control your Time.</li>
              <li>Develop your Future.</li>
            </ol>
        </div>

        <div class="col pitch-card mx-auto ">
          <img src="../assets/newTimer.png" class="pitch-img">
          <h3 class="">4. Time Tracking:</h3>
            <ol>
              <li>Track your Work.</li>
              <li>Review & Analyse your Time-Use.</li>
              <li>See, where to save time.</li>
              <li>Pomodoro Timer.</li>
            </ol>
        </div>

        <div class="col pitch-card mx-auto ">
          <img src="../assets/report_footer.png" class="pitch-img">
          <h3 class="">5. PDF Report:</h3>
            <ol>
              <li>Weekly & Monthly.</li>
              <li>Success-Log.</li>
              <li>Track Record.</li>
<!--              <li>Increase Self Confident</li>-->


            </ol>
          <small class="text-left">You will get from every task you or a Team-mate has worked on the print inside of the report.
                This is useful specially to see: "What has the Team-mates accomplished?"</small>
        </div>

      </div>
        </div>
  </div>



<!--    Todo-Boards   -->
      <div class="row w-100 space" style="background-color: white; min-height: 900px;
      color: black; margin: 0;">
        <div class="col-11 col-xl-10 col-lg-11 mx-auto">
          <h1 class="mb-5 text-center">Todo-Boards</h1>
          <img style="" class="home-img" src="../assets/BusinessSample.png">

          <p class="p py-5 mx-auto text-left" style="max-width:600px;">
            Organize & Plan your daily life.
            Create your <b>Boards topic-wise</b>,
            define different <b>Columns as</b> current <b>Status</b> for your Todos.
            Create your Todo-Flow and celebrate your accomplished <b>Milestones</b>.

          </p>

          <p class="text-center pt-2">
            <router-link :to="{'name': 'BlogTodoBoards'}">
              <button class="menu-button">Read More</button>
            </router-link>
          </p>
        </div>
    </div>


<!--    Timer & Report   -->
<!--      <div class="row w-100 space" style="background-color: orange; min-height: 900px;-->
<!--      color: black; margin: 0;">-->
<!--        <div class="mx-auto col-8">-->
<!--          <h1 class="mb-5  text-center">Timer</h1>-->
<!--          <img style="max-height: 540px; border-radius: 13px;" class="" src="../assets/newTimer.png">-->
<!--          <h2 class="p pt-4 pb-4">Oh, you like to work with a timer, no problem.</h2>-->
<!--&lt;!&ndash;          <p class="p">Add Offline-work to your Timelog.</p>&ndash;&gt;-->
<!--          <p class="text-center pt-2">-->
<!--              <router-link :to="{'name': 'BlogTimerReport'}">-->
<!--                <button class="menu-button"   -->
<!--                        style="">Read More about the Report-->
<!--                </button>-->
<!--              </router-link>-->
<!--          </p>-->
<!--        </div>-->

<!--      </div>-->


<!--    Timeline   -->
<!--      <div class="row w-100 space" style="background-color: yellow; min-height: 900px;-->
<!--      color: black; margin: 0;">-->
<!--        <div class="mx-auto col-xl-8 col-lg-9 col-md-10 col-11 ">-->
<!--          <h1 class="mb-5 text-center" style="color:black !important;">Timeline</h1>-->

<!--            <img class="home-img" src="../assets/timeline_sample.png">-->

<!--          <h2 class="p pt-4 pb-3">The Overview your Projects.</h2>-->
<!--            <p class="text-center pt-2">-->
<!--              <router-link :to="{'name': 'BlogTimeline'}">-->
<!--                  <button class="menu-button text-black">Read More</button>-->
<!--              </router-link>-->
<!--            </p>-->
<!--        </div>-->
<!--      </div>-->


<!--    TODO: Add exapmle Libary     -->
<!--   <div class="w-100" style="background-color: white; color: black; min-height: 500px; ">-->
<!--          <div class="w-100 text-center">-->
<!--            <h3 class="pitchline px-4 "-->
<!--              style="">-->
<!--              Plan your Future, Start NOW!-->
<!--            </h3>-->
<!--            <div class="w-100">-->
<!--              <router-link :to="{'name': 'Register'}">-->
<!--                <button class="menu-button text-center mx-auto">-->
<!--                  Register here-->
<!--                </button>-->
<!--              </router-link>-->
<!--            </div>-->

<!--        </div>-->

<!--      </div>-->
</div>
<Footer />
</template>

<script>
import NavbarHome from "@/components/navbar/NavbarHome";
import {mapGetters} from "vuex";
import ButtonAddBoard from "@/components/UI/ButtonAddBoard";
import Footer from "@/components/navbar/Footer";

  export default {
    name: 'Home',
    components: {
      NavbarHome,
      ButtonAddBoard,
      Footer
    },
    computed: {
      ...mapGetters(['isLoggedIn', 'isPremiumUser', 'user']),
      user() {
        return this.$store.state.user
      },
    },
    created() {
      if(this.user) {
         this.$store.dispatch('getBoard')
      }
    }
  }
</script>
<style type="text/css">
        #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif;  width:377px;}
        /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
           We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
      </style>
<style scoped>

  .pitchline {
    position: relative;
    /*top: 40%;*/
    padding-top: 2.1rem;
    font-weight: 600 !important;
    font-size: 2.1rem !important;
    text-align: center !important;
  }
  .space {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

a {
  text-decoration-line: none !important;
}

li {
  list-style: disc;
  font-size: 21px;
  font-weight: 377;
  text-align: left;
}

.whitetitle {
  color: white !Important;
  text-decoration-line: none;
}

.pricing-card {
  width: 360px;
  color: black;
  border: none;
  border-radius: 13px;
  margin-top: 5rem;
  margin-bottom: 6rem;
  text-align: center;
  transition: all 0.6s;
  background-color: white;
}



.pricing-card:hover {
    transform: translateY(-13px);
  }

.pitch-img {
  margin-top: 1.5rem !important;
  object-fit: cover;
  width: 300px;
  height: 300px;
  border-radius: 13px !important;
  background-color: white;
}


.pitch-card {
  min-width: 360px;
  max-width: 377px !important;
  color: black;
  /*background-image: linear-gradient(360deg, #fff3dc 0%, #fee7ba 21%, #fbdb98 34%, #f0c451 55%);*/
  /*background-image: linear-gradient(315deg, #fff3dc 0%, lightgoldenrodyellow 55%, #fee7ba 89%);*/
  background-color: transparent;
  border-radius: 13px;
  margin-right: auto !important;
  margin-left: auto !important;
  margin-top: 1.61rem;
  margin-bottom: 1.61rem;
  text-align: center;
  transition: all 0.6s;
}

</style>