<template>
  <div id="scroller" class="w-100">
    <div class="w-100 scrollBox">
      <div @click="getMoreTeamChat()"
          class="menu-button btn-outline-primary mx-auto w-25">load more</div>

      <div class="w-100 pt-3 scrollBoxInside"
            style=""
            :key="key"
            v-for="key in $store.state.teamChat">

        <div class="row">
          <div class="pl-5 col-11" >
            <b>{{key.author}}:</b> {{key.chat}}
          </div>
          <div class="col-1 mx-auto">
            <i class="fa fa-trash remove-icon"
                @click="deleteChat(key.id)"
               />
          </div>
          <br>

          <small class="pl-5 w-100 text-black-50">
            <span class="float-left" style="font-size: 16px">
              {{formatDate(key.timestamp,'YYYY--MMMM-DD, HH:mm')}}
            </span>
          </small>

        </div>
      </div>
      <p class="alert-success bg-green-500 w-100 px-4 rounded"
            v-if="message">{{message}}</p>
      <p class="alert-danger bg-red-500 w-100 px-4 rounded"
            v-if="error">{{error}}</p>
      <div id="anchor"
           class="anchor"
           :class="{ anchor: this.activeAnchor }">

      </div>
    </div>
  </div>

</template>

<script>

import {mapGetters} from "vuex";




export default {
  name: "ChatContent",
  props: ['returnAnchorTrue'],
  data() {
    return {
      teamChat: this.$store.state.teamChat,
      currentBoard: Number(this.$route.params.id),
      activeAnchor: true,
      error: false,
      loading: false,
      message: false,
      page: 1,
      links: null,
      loadingChat: false,
    }
  },
  methods: {
    async deleteChat(id) {
      this.loading = true;
      this.error = false;
      this.message = false;

      try {
         const response = await this.$store.dispatch('deleteTeamChat', id)
            .then((response) => {
              if (response === true) {
                this.message = 'Successful deletion.';
                setTimeout(() => {
                  this.message = false;
                }, 3400);
                // throw new Text('Successful deletion.');
              } else {
                throw new Error('Could not delete.');
              }
            })
           this.loading = false;

            console.log('Response:::', response);
            // console.log('response::', response.status);
      } catch(error) {
          console.log(error);
          this.error = 'Could not delete.';
          this.loading = false;
          setTimeout(() => {
                this.error = false;
          }, 3400);
          return;
        }
    },
    async getMoreTeamChat() {
      this.error = false;
      this.page = this.page + 1
      this.currentBoard = Number(this.$route.params.id)
      const idAndPage = "?board=" + this.currentBoard + "&" + "page=" + this.page
      // print(idAndPage)
        try {
            const response = await this.$store.dispatch('getMoreTeamChat', idAndPage )
          .then((response) => {
                  // debugger //eslint-disable-line
                  if (response === true) {
                    this.message = 'Successful Teamchat loaded.';
                    setTimeout(() => {
                      this.message = false;
                    }, 1300);
                  } else {
                    throw new Error('No more chats existing.');
                  }
                })
              console.log('Response:::', response);
          } catch(error) {
          console.log('ERROR:::', error)
          this.error = 'No more chats existing.';
          setTimeout(() => {
                this.error = false;
          }, 3400);
          return
        }
    },
  },
  mounted() {
    this.$store.dispatch('getTeamChat', this.$route.params.id);
  },
  updated() {
    let scrollBox = document.querySelector('.scrollBox');
    scrollBox.scrollTop = scrollBox.scrollHeight - scrollBox.clientHeight;
    if (this.returnAnchorTrue === false) {
      return { anchor: this.activeAnchor };
    }
  },
  watch: {
    '$route.params.id'() {
      this.$store.dispatch('getBoard', this.$route.params.id);
      this.$store.dispatch('getTeamChat', this.$route.params.id)
    },
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'isPremiumUser', 'user', 'boardMaster']),
  }
}

</script>

<style>

.scrollBox {
  max-height: 32vh;
  overflow: scroll;
  overflow-x: hidden;
  /*scroll-padding-bottom: revert;*/
}

.scrollBoxInside {
  font-size: 21px;
  list-style: none;
  text-align: left;
}

#scroller * {
    /* don't allow the children of the scrollable element to be selected
    as an anchor node */
    overflow-anchor: none !important;
}

#anchor {
    /* allow the final child to be selected as an anchor node */
    overflow-anchor: revert !important;
    /* anchor nodes are required to have non-zero area */
    height: 1px;
}

.anchor {
    /* allow the final child to be selected as an anchor node */
    overflow-anchor: revert !important;
    /* anchor nodes are required to have non-zero area */
    height: 1px;
}
</style>