<template>
    <table>
      <tr>
        <td>
          <button class="modal-step-button" @click="onHustleTime(0)">-</button>
        </td>
        <td>
          <input type="number" v-model.number="user.setting.hustle_time"
                 style="max-width: 89px;">
        </td>
        <td>
          <button class="modal-step-button" @click="onHustleTime(1)">+</button>
        </td>
      </tr>
    </table>
<!--  <h1>{{user.setting.id}}</h1>-->
</template>

<script>
import api from "@/api";
import {mapGetters} from "vuex";

export default {
  name: "HustleTime",
  data() {
    return {
      // id: this.user.setting.id
      }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'isPremiumUser', 'user', 'hustleTime']),
    hustleTime: {
      get() {
        return this.user ? this.user.setting.hustle_time : 30
      },
      set(val) {
        this.user.setting.hustle_time = val
      }
    }
  },
  methods: {
    async saveUser() {
      // console.log('this.user', this.user)
      // console.log('...this.user', ...this.user)
      console.log('this.user.setting.hustle_time', this.user.setting.hustle_time)
      console.log('this.user.setting.id', this.user.setting.id)
      const data = {
        user: this.user.setting.id,
        hustle_time: this.hustleTime,
        // user: this.user,
        // user: this.user.setting,
        // name: this.user.name,

        // user: this.user.id,
      }
      console.log('data', data)
      await api.updateHustleTime(data)
    },
    onHustleTime(op) {
      op === 0 ?
          this.hustleTime = this.hustleTime > 0 ? this.hustleTime - 1 : this.hustleTime :
          this.hustleTime++;
      this.$store.dispatch('changeHustleTimeCount', this.hustleTime);
      this.saveUser()
    },
  }
}
</script>

<style scoped>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number]{
    -moz-appearance: textfield;
}
table {
  min-width: 210px;
  max-width: 333px;
}
</style>