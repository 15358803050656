<template>
  <navbar-home></navbar-home>
  <div class="row">
     <div class="mx-auto col-8">
       <h1>Privacy Policy</h1>





<div class=WordSection1>

<p class=MsoNormal><b><span lang=DE style=''></span></b></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=berschrift2 style='margin-left:.4in;text-indent:-.4in'>1.<span
style=' "Times New Roman"'> </span>Name
and address of the person responsible</p>

<p class=MsoNormal><span lang=DE></span></p>

<p class=MsoNormal><span lang=DE style=''>The person
responsible within the meaning of the Federal Act on Data Protection of the
Swiss Confederation (DSG) and other data protection regulations is:</span></p>

<p class=MsoListParagraph style='margin-left:.25in'><span lang=DE
style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>Software Henker</span></p>

<p class=MsoNormal><span lang=DE style=''>Fellenbergstr. 63</span></p>

<p class=MsoNormal><span lang=DE style=''>9000 St.Gallen</span></p>

<p class=MsoNormal><span lang=DE style=''>Schweiz</span></p>

<p class=MsoNormal><span lang=DE style=''>Tel.: +41 76 7250 700</span></p>

<p class=MsoNormal><span lang=DE style=''>E-Mail:
charles@software-henker.ch</span></p>

<p class=MsoNormal><span lang=DE style=''>Website:
software-henker.ch</span></p>

<h1 style='margin-left:.3in;text-indent:-.3in'><b><span lang=DE
style=';font-family:"Arial",sans-serif;color:black'>2.<span
style=' "Times New Roman"'> </span></span></b><b><span
lang=DE style=';font-family:"Arial",sans-serif;color:black'>Name
and address of the data protection officer</span></b></h1>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>The data protection
officer of the person responsible is:</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>Software Henker</span></p>

<p class=MsoNormal><span lang=DE style=''>Fellenbergstr. 63</span></p>

<p class=MsoNormal><span lang=DE style=''>9000 St.Gallen</span></p>

<p class=MsoNormal><span lang=DE style=''>Schweiz</span></p>

<p class=MsoNormal><span lang=DE style=''>Tel.: +41 76 7250 700</span></p>

<p class=MsoNormal><span lang=DE style=''>E-Mail:
charles@software-henker.ch</span></p>

<p class=MsoNormal><span lang=DE style=''>Website:
software-henker.ch</span></p>

<h1 style='margin-left:.3in;text-indent:-.3in'><b><span lang=DE
style=';font-family:"Arial",sans-serif;color:black'>3.<span
style=' "Times New Roman"'> </span></span></b><b><span
lang=DE style=';font-family:"Arial",sans-serif;color:black'>General
information on data processing</span></b></h1>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:.4in;text-indent:-.4in'><span lang=DE style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif'>3.1.<span
style=' "Times New Roman"'> </span></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'>Scope
of the processing of personal data</span></h2>

<p class=MsoNoSpacing><span lang=DE style=''>In principle, we
only process personal data of our users insofar as this is necessary to provide
a functional website and our content and services. The name &quot;we&quot;
describes the person responsible for data protection above. The term “user”
refers to those people who use services via www.mysupersucces.com. The
processing of personal data of our users takes place regularly only with the
consent of the user. An exception applies in those cases in which prior consent
cannot be obtained for practical reasons and the processing of the data is
permitted by law. </span></p>

<p class=MsoNoSpacing><span lang=DE></span></p>

<p class=berschrift2 style='margin-left:38.4pt;text-indent:-38.4pt'><span
style=''>3.2.<span style=' "Times New Roman"'>
</span></span><span style=''>Legal basis for the processing of
personal data</span></p>

<p class=MsoNoSpacing><span lang=DE style=''></span></p>

<p class=MsoNoSpacing><span lang=DE style=''>Insofar as we
obtain the consent of the data subject for the processing of personal data,
Article 4 Paragraph 1 DSG serves as the legal basis.</span></p>

<p class=MsoNoSpacing><span lang=DE style=''>When processing
personal data that is required to fulfill a contract to which the data subject
is a party, Article 4 Paragraph 3 DSG serves as the legal basis. This also
applies to processing operations that are necessary to carry out
pre-contractual measures.</span></p>

<p class=MsoNoSpacing><span lang=DE style=''>Insofar as the
processing of personal data is necessary to fulfill a legal obligation to which
our company is subject, Article 4 Paragraph 1 DSG serves as the legal basis.</span></p>

<p class=MsoNoSpacing><span lang=DE style=''>In the event that
vital interests of the data subject or another natural person require the processing
of personal data, Article 4 Paragraph 2 DSG serves as the legal basis.</span></p>

<p class=MsoNoSpacing><span lang=DE style=''>If processing is
necessary to safeguard a legitimate interest of our company or a third party
and if the interests, fundamental rights and freedoms of the data subject do
not outweigh the first-mentioned interest, Article 4 (3) DSG serves as the
legal basis for processing.</span></p>

<p class=MsoNoSpacing><span lang=DE style=''></span></p>

<h2 style='margin-left:.4in;text-indent:-.4in'><span lang=DE style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif'>3.3.<span
style=' "Times New Roman"'> </span></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'>Data
destruction and storage duration</span></h2>

<p class=MsoNoSpacing><span lang=DE style=''>The personal data
of the data subject will be deleted or</span></p>

<p class=MsoNoSpacing><span lang=DE style=''>blocked as soon as
the purpose of storage no longer applies. A storage can about it</span></p>

<p class=MsoNoSpacing><span lang=DE style=''>in addition, if
this has been provided for by the national legislator in laws or other
regulations to which the person responsible is subject. The data will also be
blocked or destroyed if a storage period prescribed by the standards mentioned
expires, unless there is a need for further storage of the data for the
conclusion or fulfillment of a contract.</span></p>

<p class=MsoNoSpacing><span lang=DE style=''></span></p>

<h1 style='margin-left:.3in;text-indent:-.3in'><b><span lang=DE
style=';font-family:"Arial",sans-serif;color:black'>4.<span
style=' "Times New Roman"'></span></span></b><b><span
lang=DE style=';font-family:"Arial",sans-serif;color:black'>Provision
of the website</span></b></h1>

<p class=MsoNormal><b><span lang=DE style=''></span></b></p>

<h2 style='margin-left:.4in;text-indent:-.4in'><span lang=DE style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif'>4.1.<span
style=' "Times New Roman"'> </span></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'>Description
and scope of data processing</span></h2>

<p class=MsoNoSpacing><span lang=DE style=''>Every time our
website is called up, our system automatically collects data and information
from the computer system of the calling computer.</span></p>

<p class=MsoNoSpacing><span lang=DE style=''></span></p>

<p class=MsoNoSpacing><span lang=DE style=''>The following data
is collected:</span></p>

<p class=MsoNoSpacing><span lang=DE style=''></span></p>

<p class=MsoNoSpacing><span lang=DE style=''>Date
and time of access</span></p>

<p class=MsoNoSpacing><span lang=DE style=''></span></p>

<h2 style='margin-left:38.4pt;text-indent:-38.4pt'><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>4.2.<span
style=' "Times New Roman"'>
</span></span><span lang=DE style=';line-height:115%;
font-family:"Arial",sans-serif'>Legal basis for data processing</span></h2>

<p class=MsoNoSpacing><span lang=DE style=''></span></p>

<p class=MsoNoSpacing><span lang=DE style=''>The legal basis
for the temporary storage of the data is Article 4 Paragraph 1 DSG. </span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:38.4pt;text-indent:-38.4pt'><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>4.3.<span
style=' "Times New Roman"'>
</span></span><span lang=DE style=';line-height:115%;
font-family:"Arial",sans-serif'>Purpose of data processing</span></h2>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>The temporary storage
of the date and time of the last access by the system is necessary to enable
the website to be delivered to the user's computer.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>Our legitimate
interest in data processing in accordance with Article 4 Paragraph 3 DSG lies
in this purpose.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:.4in;text-indent:-.4in'><span lang=DE style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif'>4.4.<span
style=' "Times New Roman"'> </span></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'>Duration
of storage</span></h2>

<p class=MsoNormal><span lang=DE style=''>The data will be
deleted as soon as they are no longer required to achieve the purpose for which
they were collected. In the case of the collection of data for the provision of
the website, this is the case when the respective session has ended.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:38.4pt;text-indent:-38.4pt'><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>4.5.<span
style=' "Times New Roman"'>
</span></span><span lang=DE style=';line-height:115%;
font-family:"Arial",sans-serif'>Opposition and removal option</span></h2>

<p class=MsoNormal><span lang=DE style=''>The collection of
data for the provision of the website is absolutely necessary for the operation
of the website. There is consequently no possibility of objection on the part
of the user.</span></p>

<h1 style='margin-left:.3in;text-indent:-.3in'><b><span lang=DE
style=';font-family:"Arial",sans-serif;color:black'>5.<span
style=' "Times New Roman"'> </span></span></b><b><span
lang=DE style=';font-family:"Arial",sans-serif;color:black'>Use
of Cookies</span></b></h1>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:38.4pt;text-indent:-38.4pt'><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>5.1.<span
style=' "Times New Roman"'>
</span></span><span lang=DE style=';line-height:115%;
font-family:"Arial",sans-serif'>Description and scope of data processing</span></h2>

<p class=MsoNormal><span lang=DE style=''>Our website uses
authentication cookies. A cookie is a text file that is stored in the internet
browser or by the internet browser on the user's computer system. When a user
calls up a website, a cookie can be stored on the user's operating system. This
cookie contains a characteristic string of characters that enables the browser
to be clearly identified when the website is called up again.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>We use cookies to
make our website more user-friendly. Some elements of our website require that
the calling browser can also be identified after changing pages.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>The following data is
stored and transmitted in the cookies:</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''> Authentication
Token &amp; User Email</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>We also use cookies
on our website that enable an analysis of the surfing behavior of the users. In
this way, the following data can be transmitted:</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>Frequency
of page views</span></p>

<p class=MsoNormal><span lang=DE style=''> Use of website
functions</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>The user data
collected in this way is pseudonymised by technical precautions. It is
therefore no longer possible to assign the data to the calling user. The data
is not stored together with other personal data of the user.</span></p>

<p class=MsoNormal><span lang=DE style=''>In this context,
there is also an indication of how the storage of cookies can be prevented in
the browser settings.</span></p>

<p class=MsoNormal><span lang=DE></span></p>

<h2 style='margin-left:38.4pt;text-indent:-38.4pt'><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>5.2.<span
style=' "Times New Roman"'>
</span></span><span lang=DE style=';line-height:115%;
font-family:"Arial",sans-serif'>Legal basis for data processing</span></h2>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>The legal basis for
the processing of personal data using cookies is Article 4 Paragraph 3 DSG.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:38.4pt;text-indent:-38.4pt'><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>5.3.<span
style=' "Times New Roman"'>
</span></span><span lang=DE style=';line-height:115%;
font-family:"Arial",sans-serif'>Purpose of data processing</span></h2>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>The purpose of using
technically necessary cookies is to simplify the use of websites for users.
Some functions of our website cannot be offered without the use of cookies. For
this it is necessary that the browser is recognized even after changing pages.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>We need cookies for
the following applications:</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''> Authentication
Token &amp; User Email</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>The user is
identified by a token that can be saved in the browser.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>Our legitimate
interest in the processing of personal data in accordance with Article 4 Paragraph
3 DSG lies in these purposes.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:38.4pt;text-indent:-38.4pt'><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>5.4.<span
style=' "Times New Roman"'>
</span></span><span lang=DE style=';line-height:115%;
font-family:"Arial",sans-serif'>Duration of storage, possibility of objection
and removal</span></h2>

<p class=MsoNormal style='margin-left:.5in'><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>Cookies are stored on
the user's computer and transmitted to our site from there. As a user, you
therefore have full control over the use of cookies. You can deactivate or
restrict the transmission of cookies by changing the settings in your Internet
browser. Cookies that have already been saved can be deleted at any time. This
can also be done automatically. If cookies are deactivated for our website, it
is possible that not all functions of the website can be used to their full
extent.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h1 style='margin-left:.3in;text-indent:-.3in'><b><span lang=DE
style=';font-family:"Arial",sans-serif;color:black'>6.<span
style=' "Times New Roman"'> </span></span></b><b><span
lang=DE style=';font-family:"Arial",sans-serif;color:black'>Newsletter</span></b></h1>

<p class=MsoNormal><span lang=DE></span></p>

<h2 style='margin-left:38.4pt;text-indent:-38.4pt'><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>6.1.<span
style=' "Times New Roman"'>
</span></span><span lang=DE style=';line-height:115%;
font-family:"Arial",sans-serif'>Description and scope of data processing</span></h2>

<p class=MsoNormal style='margin-left:.5in'><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>You can subscribe to
a free newsletter on our website. When registering for the newsletter, the data
from the input mask is transmitted to us. This affects the user's email
address.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>In addition, the
following data is collected when you register:</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>Date and time of last
login</span></p>

<p class=MsoNormal><span lang=DE style=''>This affects the
user's email address</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>For the processing of
the data, your consent is obtained during the registration process and
reference is made to this data protection declaration.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>In connection with
the data processing for the dispatch of newsletters, the data is not passed on
to third parties. The data will only be used to send the newsletter.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:38.4pt;text-indent:-38.4pt'><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>6.2.<span
style=' "Times New Roman"'>
</span></span><span lang=DE style=';line-height:115%;
font-family:"Arial",sans-serif'>Legal basis for data processing</span></h2>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>The legal basis for
the processing of the data after the user has registered for the newsletter is
Article 4 (5) DSG, provided that the user has given his / her consent.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:38.4pt;text-indent:-38.4pt'><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>6.3.<span
style=' "Times New Roman"'>
</span></span><span lang=DE style=';line-height:115%;
font-family:"Arial",sans-serif'>Purpose of data processing</span></h2>

<p class=MsoNormal><span lang=DE style=''>The collection of the
user's email address is used to deliver the newsletter.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>The collection of
other personal data as part of the registration process serves to prevent
misuse of the services or the email address used.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:38.4pt;text-indent:-38.4pt'><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>6.4.<span
style=' "Times New Roman"'>
</span></span><span lang=DE style=';line-height:115%;
font-family:"Arial",sans-serif'>Duration of storage</span></h2>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>The data will be deleted
as soon as they are no longer required to achieve the purpose for which they
were collected. The user's email address is therefore stored as long as the
subscription to the newsletter is active.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>The other personal
data collected during the registration process are usually deleted after a
period of seven days.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:.4in;text-indent:-.4in'><span lang=DE style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif'>6.5.<span
style=' "Times New Roman"'> </span></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'>Opposition
and removal option</span></h2>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>The user concerned
can cancel the subscription to the newsletter at any time. There is a
corresponding link in every newsletter for this purpose.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>This also enables you
to revoke your consent to the storage of the personal data collected during the
registration process.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h1 style='margin-left:.3in;text-indent:-.3in'><b><span lang=DE
style=';font-family:"Arial",sans-serif;color:black'>7.<span
style=' "Times New Roman"'> </span></span></b><b><span
lang=DE style=';font-family:"Arial",sans-serif;color:black'>Register</span></b></h1>

<p class=MsoNormal><span lang=DE></span></p>

<h2 style='margin-left:38.4pt;text-indent:-38.4pt'><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>7.1.<span
style=' "Times New Roman"'>
</span></span><span lang=DE style=';line-height:115%;
font-family:"Arial",sans-serif'>Description and scope of data processing</span></h2>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>On our website, we
offer users the opportunity to register by providing personal data. The data is
entered in an input mask and transmitted to us and stored. A transfer of data
to third parties does not take place. The following data is collected during
the registration process:</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''> Username</span></p>

<p class=MsoNormal><span lang=DE style=''> E-mail
address</span></p>

<p class=MsoNormal><span lang=DE style=''> password</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>At the time of registration,
the following data is also stored:</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>Date and time of
registration &amp; last login</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>As part of the
registration process, the user's consent to the processing of this data is
obtained.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:38.4pt;text-indent:-38.4pt'><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>7.2.<span
style=' "Times New Roman"'>
</span></span><span lang=DE style=';line-height:115%;
font-family:"Arial",sans-serif'>Legal basis for data processing</span></h2>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>The legal basis for
the processing of the data is Article 4 (5) of the DSG if the user has given
his / her consent.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>If the registration
serves to fulfill a contract to which the user is a party or to carry out
pre-contractual measures, Article 4 Paragraph 3 DSG is an additional legal
basis for the processing of the data.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:38.4pt;text-indent:-38.4pt'><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>7.3.<span
style=' "Times New Roman"'>
</span></span><span lang=DE style=';line-height:115%;
font-family:"Arial",sans-serif'>Purpose of data processing</span></h2>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>Registration of the
user is required to fulfill a contract with the user or to carry out
pre-contractual measures.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>The data is collected
in order to be able to fulfill and offer the services of the contract
accordingly. Without the data, it is not possible to conclude a contract and to
use the agreed subject matter of the contract. The user has access to
&quot;Todoboards&quot;, &quot;Timers&quot; &amp; &quot;Timelines&quot; as well
as weekly / monthly e-mail reports including a report file in PDF format. The
user is identified by a token that can be saved in the browser.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:.4in;text-indent:-.4in'><span lang=DE style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif'>7.4.<span
style=' "Times New Roman"'> </span></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'>Duration
of storage</span></h2>

<p class=MsoNormal style='margin-left:.5in'><span lang=DE></span></p>

<p class=MsoNormal><span lang=DE style=''>The data will be
deleted as soon as they are no longer required to achieve the purpose for which
they were collected.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>The registration is
used to conclude a contract with the user:</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>This is the case
during the registration process to fulfill a contract or to carry out
pre-contractual measures when the data is no longer required for the execution
of the contract. Even after the contract has been concluded, it may be
necessary to save personal data of the contractual partner in order to meet
contractual or legal obligations.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>The user has access
to &quot;To-Do-Boards&quot;, &quot;Timers&quot; &amp; &quot;Timelines&quot; as
well as a weekly and monthly summary in the form of an email including a PDF
file as a report. The user is saved in the browser via a token and can thus be
identified.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:.4in;text-indent:-.4in'><span lang=DE style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif'>7.5.<span
style=' "Times New Roman"'> </span></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'>Opposition
and removal option</span><span lang=DE style=';line-height:
115%;font-family:"Arial",sans-serif'></span></h2>

<p class=MsoNormal><span lang=DE style=''>As a user, you can
cancel your registration at any time. You can have the data stored about you
changed at any time.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>Depending on the
contract, the termination can be made at the end of the contract cycle.</span></p>

<p class=MsoNormal><span lang=DE style=''>The revocation can be
made free of charge in the first two months after the initial conclusion of the
contract. The user can cancel at www.mysupersuccess.com/setting/ with the
button &quot;Cancellation&quot;.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>If the data is
required to fulfill a contract or to carry out pre-contractual measures,
premature destruction of the data is only possible if there are no contractual
or legal obligations to prevent destruction.</span></p>

<h1 style='margin-left:.3in;text-indent:-.3in'><b><span lang=DE
style=';font-family:"Arial",sans-serif;color:black'>8.<span
style=' "Times New Roman"'> </span></span></b><b><span
lang=DE style=';font-family:"Arial",sans-serif;color:black'>Payment
services and payment methods</span></b></h1>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:38.4pt;text-indent:-38.4pt'><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>8.1.<span
style=' "Times New Roman"'>
</span></span><span lang=DE style=';line-height:115%;
font-family:"Arial",sans-serif'>Description and scope of data processing</span></h2>

<p class=MsoNormal><span lang=DE style=''>Our website offers
the option of entering into a contractual relationship for the use of software.
This takes place as part of the registration process. In this context, your
data for the payment process will be transmitted via the payment service
provider Stripe, Inc. The data is entered in an input mask and forwarded. Which
data is collected as part of the payment process can be found in Stripe Inc.'s
data protection regulations.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>As part of the
registration process, the user's consent to the processing of this data is
obtained.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:38.4pt;text-indent:-38.4pt'><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>8.2.<span
style=' "Times New Roman"'>
</span></span><span lang=DE style=';line-height:115%;
font-family:"Arial",sans-serif'>Legal basis for data processing</span></h2>

<p class=MsoNormal><span lang=DE style=''>The legal basis for
the processing of the data is DSG 4 with the consent of the user.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:.4in;text-indent:-.4in'><span lang=DE style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif'>8.3.<span
style=' "Times New Roman"'> </span></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'>Purpose
of data processing</span></h2>

<p class=MsoNormal><span lang=DE style=''>The payment data are
required in order to be able to legally conclude the contract for the use of
the software.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>Without the data, it
is not possible to conclude a contract and to use the agreed subject matter of
the contract.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:.4in;text-indent:-.4in'><span lang=DE style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif'>8.4.<span
style=' "Times New Roman"'> </span></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'>Duration
of storage</span></h2>

<p class=MsoNormal><span lang=DE style=''>The data will be
deleted as soon as they are no longer required to achieve the purpose for which
they were collected.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>The payment serves to
conclude a contract and leads to the immediate availability of the software.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:.4in;text-indent:-.4in'><span lang=DE style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif'>8.5.<span
style=' "Times New Roman"'> </span></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'>Opposition
and removal option</span></h2>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>As a user, you have
the option of canceling the payment process at any time.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>If the data is
required to fulfill a contract or to carry out pre-contractual measures,
premature destruction of the data is only possible if there are no contractual
or legal obligations to prevent destruction.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h1 style='margin-left:.3in;text-indent:-.3in'><b><span lang=DE
style=';font-family:"Arial",sans-serif;color:black'>9.<span
style=' "Times New Roman"'> </span></span></b><b><span
lang=DE style=';font-family:"Arial",sans-serif;color:black'>E-Mail-Contact</span></b></h1>

<p class=MsoNormal><span lang=DE></span></p>

<h2 style='margin-left:24.0pt;text-indent:-24.0pt'><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>9.1.<span
style=' "Times New Roman"'> </span></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'>Description
and scope of data processing</span><span lang=DE style=';
line-height:115%;font-family:"Arial",sans-serif'> </span></h2>

<p class=MsoNormal><span lang=DE style=''>You can contact us
via the email address provided. In this case, the user's personal data
transmitted with the email will be saved.</span></p>

<p class=MsoNormal><span lang=DE style=''>In this context, the
data will not be passed on to third parties. The data will only be used to
process the conversation.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:.4in;text-indent:-.4in'><span lang=DE style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif'>9.2.<span
style=' "Times New Roman"'> </span></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'>Legal
basis for data processing</span></h2>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>The legal basis for
the processing of the data is Article 4 (5) of the DSG if the user has given
his / her consent.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>The legal basis for
the processing of the data transmitted in the course of sending by email is
Article 4 Paragraph 3 DSG. If the aim of the email contact is to conclude a
contract, the additional legal basis for the processing is Article 4 Paragraph
4 DSG.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:.4in;text-indent:-.4in'><span lang=DE style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif'>9.3.<span
style=' "Times New Roman"'> </span></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'>Purpose
of data processing</span></h2>

<p class=MsoNormal><span lang=DE style=''>The processing of the
personal data from the input mask serves us only to process the contact. If you
contact us by e-mail, there is also the necessary legitimate interest in
processing the data.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:38.4pt;text-indent:-38.4pt'><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>9.4.<span
style=' "Times New Roman"'>
</span></span><span lang=DE style=';line-height:115%;
font-family:"Arial",sans-serif'>Duration of storage</span></h2>

<p class=MsoNormal><span lang=DE style=''>The data will be
deleted as soon as they are no longer required to achieve the purpose for which
they were collected. For the personal data sent by email, this is the case when
the respective conversation with the user has ended. The conversation is ended
when it can be inferred from the circumstances that the matter in question has
been finally clarified.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:.4in;text-indent:-.4in'><span lang=DE style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif'>9.5.<span
style=' "Times New Roman"'> </span></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'>Possibility of objection and removal
  </span></h2>

<p class=MsoNormal><span lang=DE style=''>The user has the possibility to revoke his consent to the processing of
  personal data at any time.
  If the user contacts us by e-mail, he can object to the storage of his personal data at any time. In such a case,
  the conversation cannot be continued.</span></p>

<h1 style='margin-left:.3in;text-indent:-.3in'><b><span lang=DE
style=';font-family:"Arial",sans-serif;color:black'>10.<span
style=' "Times New Roman"'> </span></span></b><b><span
lang=DE style=';font-family:"Arial",sans-serif;color:black'>Websiteanalytics
with Google Analytics</span></b></h1>

<p class=MsoNormal><span lang=DE></span></p>

<h2 style='margin-left:.4in;text-indent:-.4in'><span lang=DE style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif'>10.1.<span
style=' "Times New Roman"'> </span></span><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'>Scope
of the processing of personal data</span></h2>

<p class=MsoNormal><span lang=DE style=''>We use Google
Analytics on our website to analyze the surfing behavior of our users: Inside.
Please refer to the data protection provisions of Google Analytics at www.policies.google.com/privacy?hl=de
to find out which data is determined, collected, processed or saved.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:38.4pt;text-indent:-38.4pt'><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>10.2.<span
style=' "Times New Roman"'> </span></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'>Legal
basis for the processing of personal data</span></h2>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>The legal basis for
processing users' personal data is Article 4 Paragraph 3 DSG.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:.4in;text-indent:-.4in'><span lang=DE style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif'>10.3.<span
style=' "Times New Roman"'> </span></span><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>Purpose
of data processing</span></h2>

<p class=MsoNormal><span lang=DE style=''>The processing of the
personal data of the users enables us to analyze the surfing behavior of our
users. By evaluating the data obtained, we are able to compile information
about the use of the individual components of our website. This helps us to
continuously improve our website and its user-friendliness. Our legitimate
interest in the processing of the data in accordance with Article 4 Paragraph 3
DSG also lies in these purposes. By anonymizing the IP address, the interests
of users in protecting their personal data are adequately taken into account.</span></p>

<p class=MsoNormal><span lang=DE></span></p>

<h2 style='margin-left:38.4pt;text-indent:-38.4pt'><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>10.4.<span
style=' "Times New Roman"'> </span></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'>Duration
of storage</span></h2>

<p class=MsoNormal><span lang=DE style=''>The data will be
deleted as soon as they are no longer required for our recording purposes.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:.4in;text-indent:-.4in'><span lang=DE style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif'>10.5.<span
style=' "Times New Roman"'> </span></span><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'>Opposition
and removal option</span></h2>

<p class=MsoNormal><span lang=DE style=''>Cookies are stored on
the user's computer and transmitted to our site from there. As a user, you
therefore have full control over the use of cookies. You can deactivate or
restrict the transmission of cookies by changing the settings in your Internet
browser. Cookies that have already been saved can be deleted at any time. This
can also be done automatically. If cookies are deactivated for our website, it
is possible that not all functions of the website can be used to their full
extent.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>You can find more
information on the privacy settings of the Matomo software under the following
link: https://matomo.org/docs/privacy/.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h1 style='margin-left:.3in;text-indent:-.3in'><b><span lang=DE
style=';font-family:"Arial",sans-serif;color:black'>11.<span
style=' "Times New Roman"'> </span></span></b><b><span
lang=DE style=';font-family:"Arial",sans-serif;color:black'>Rights
of the data subject</span></b></h1>

<p class=MsoNormal><b><span lang=DE style=''></span></b></p>

<h2 style='margin-left:.4in;text-indent:-.4in'><span lang=DE style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif'>11.1.<span
style=' "Times New Roman"'> </span></span><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>right
of providing information</span></h2>

<p class=MsoNormal><span lang=DE style=''>You can request
confirmation from the person responsible as to whether we are processing
personal data relating to you.</span></p>

<p class=MsoNormal><span lang=DE style=''>If this is the case,
you can request the following information from the person responsible:</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''> the
purposes for which the personal data are processed</span></p>

<p class=MsoNormal><span lang=DE style=''> the
planned duration of the storage of your personal data</span></p>

<p class=MsoNormal><span lang=DE style=''> the
right to lodge a complaint with a supervisory authority;</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:.4in;text-indent:-.4in'><span lang=DE style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif'>11.2.<span
style=' "Times New Roman"'> </span></span><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>Right
to rectification</span></h2>

<p class=MsoNormal><span lang=DE style=''>You have a right to
correction and / or completion vis-à-vis the person responsible if the
processed personal data concerning you is incorrect or incomplete. The person
responsible must make the correction immediately.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:.4in;text-indent:-.4in'><span lang=DE style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif'>11.3.<span
style=' "Times New Roman"'> </span></span><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>Right
to annihilation</span></h2>

<p class=MsoListParagraph style='margin-left:0in'><span lang=DE
style=''>You can request the person responsible to delete the
personal data concerning you immediately, and the person responsible is obliged
to delete this data immediately if one of the following reasons applies:</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoListParagraph style='margin-left:7.9pt;text-indent:-7.9pt'><span
lang=DE style=''>•<span style=' "Times New Roman"'>
</span></span><span lang=DE style=''>The personal data
concerning you are no longer necessary for the purposes for which they were
collected or otherwise processed. </span></p>

<p class=MsoListParagraph style='margin-left:7.9pt;text-indent:-7.9pt'><span
lang=DE style=''>•<span style=' "Times New Roman"'>
</span></span><span lang=DE style=''>You revoke your consent on
which the processing was based in accordance with Article 4 Paragraph 5 DSG,
and there is no other legal basis for the processing.</span></p>

<p class=MsoListParagraph style='margin-left:7.9pt;text-indent:-7.9pt'><span
lang=DE style=''>•<span style=' "Times New Roman"'>
</span></span><span lang=DE style=''>You request the blocking
and destruction of the processing in accordance with Article 15 (1) DSG</span></p>

<p class=MsoListParagraph style='margin-left:7.9pt;text-indent:-7.9pt'><span
lang=DE style=''>•<span style=' "Times New Roman"'>
</span></span><span lang=DE style=''>The personal data
concerning you have been processed unlawfully.</span></p>

<p class=MsoListParagraph style='margin-left:7.9pt;text-indent:-7.9pt'><span
lang=DE style=''>•<span style=' "Times New Roman"'>
</span></span><span lang=DE style=''>The destruction of your
personal data is necessary to fulfill a legal obligation under Union law or the
law of the member states to which the person responsible is subject.</span></p>

<p class=MsoListParagraph style='margin-left:0in'><span lang=DE
style=''></span></p>

<p class=MsoListParagraph style='margin-left:0in'><span lang=DE
style=''></span></p>

<p class=MsoListParagraph style='margin-left:0in'><span lang=DE
style=''>The right to destruction does not exist if the
processing is necessary:</span></p>

<p class=MsoListParagraph style='margin-left:0in'><span lang=DE
style=''></span></p>

<p class=MsoListParagraph style='margin-left:0in'><span lang=DE
style=''> to assert, exercise or defend legal
claims</span></p>

<p class=MsoListParagraph><span lang=DE style=''></span></p>

<h2 style='margin-left:.4in;text-indent:-.4in'><span lang=DE style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif'>11.4.<span
style=' "Times New Roman"'> </span></span><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>Right
to be informed</span></h2>

<p class=MsoNormal><span lang=DE style=''>If you have asserted
the right to correction, destruction or restriction of processing against the
person responsible, the person responsible is obliged to notify all recipients
to whom the personal data concerning you have been disclosed of this correction
or destruction of the data or restriction of processing, unless this turns out
to be impossible or involves a disproportionate effort.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>You have the right
vis-à-vis the person responsible to be informed about these recipients.</span></p>

<h2><span lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'></span></h2>

<h2 style='margin-left:38.4pt;text-indent:-38.4pt'><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>11.5.<span
style=' "Times New Roman"'> </span></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'>Legal
claims</span></h2>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>You have the right,
for reasons that arise from your particular situation, to request blocking and
destruction at any time against the processing of your personal data, which is
based on Article 4 of the DSG.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2 style='margin-left:.4in;text-indent:-.4in'><span lang=DE style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif'>11.6.<span
style=' "Times New Roman"'> </span></span><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'>Right
to revoke the declaration of consent under data protection law</span></h2>

<p class=MsoNormal style='margin-left:.5in'><span lang=DE></span></p>

<p class=MsoNormal><span lang=DE style=''>You have the right to
revoke your declaration of consent under data protection law at any time.
Revoking your consent does not affect the legality of the processing carried
out on the basis of your consent up to the point of revocation.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<h2><span lang=DE
style=';line-height:115%;font-family:"Arial",sans-serif'>11.7.<span
style=' "Times New Roman"'> </span></span><span
lang=DE style=';line-height:115%;font-family:"Arial",sans-serif'>Right
to sue</span></h2>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE style=''>Without prejudice to
any other administrative or judicial remedy, you have the right to take legal
action in accordance with Article 15 DSG if you are of the opinion that the
processing of your personal data violates the DGS.</span></p>

<p class=MsoNormal><span lang=DE style=''></span></p>

<p class=MsoNormal><span lang=DE>Date</span><span lang=DE style=''>: 10/2021</span></p>

</div>



   </div>
  </div>
</template>

<script>
import NavbarHome from "@/components/navbar/NavbarHome";

export default {
  name: "PrivacyPolicy",
  components: {
    NavbarHome
  },
}
</script>

<style scoped>


</style>