<template>
  <div class="header w-100"
       style="justify-content: start; background-color: black; color: white !important;
       display: flex; align-items: center;" v-if="!user">
    <router-link :to="{'name': 'Home'}" style="order: initial">
      <img alt="Vue logo" src="./2021_12_supersuccesslogo.png"
           width="69" class="animated fadeIn text-left"
            style="margin: 13px; padding: 0; left: 0 !important; ">
    </router-link>
    <div class="header-item-right  w-100">

<!--      <div class="header-item-right float-right dropdown mx-xl-4 mx-lg-3"-->
<!--              style="align-items: start !important;"-->
<!--        >-->
<!--          <button-->
<!--            class="w-100 menu-button dropdown-toggle  float-right"-->
<!--            type="button"-->
<!--            id="dropdownMenuButton2"-->
<!--            data-bs-toggle="dropdown"-->
<!--            aria-expanded="false"-->
<!--            v-click-away="onClickAway"-->
<!--          >-->
<!--          Join Events-->
<!--          </button>-->
<!--          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">-->
<!--            <li>-->
<!--              <a class="dropdown-item" href="#">-->
<!--                <router-link :to="{'name': 'paradise'}"-->
<!--                             style="text-decoration-line: none;">-->
<!--                  paradise-->
<!--                </router-link>-->
<!--              </a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      <div class="header-item-right float-right dropdown mx-xl-4 mx-lg-3"-->
<!--              style="align-items: start !important;"-->
<!--        >-->
<!--          <button-->
<!--            class="w-100 menu-button dropdown-toggle  float-right"-->
<!--            type="button"-->
<!--            id="dropdownMenuButton1"-->
<!--            data-bs-toggle="dropdown"-->
<!--            aria-expanded="false"-->
<!--            v-click-away="onClickAway"-->
<!--          >-->
<!--          About Super Success-->
<!--          </button>-->
<!--          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">-->
<!--            <li>-->
<!--              <a class="dropdown-item" href="#">-->
<!--                <router-link :to="{'name': 'BlogTodoBoards'}" style="text-decoration-line: none;">Todo Boards</router-link>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a class="dropdown-item" href="#">-->
<!--                <router-link :to="{'name': 'BlogTimerReport'}" style="text-decoration-line: none;">Timer & Report</router-link>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a class="dropdown-item" href="#">-->
<!--                <router-link :to="{'name': 'BlogTimeline'}" style="text-decoration-line: none;">Timeline</router-link>-->
<!--              </a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
          <router-link :to="{'name': 'Login'}"
            class="menu-button  float-right"
            style="background-color: black; font-weight: 500;
                 margin-left: 2.1rem;; margin-right: 2.1rem;
                 padding-left: 2.1rem; padding-right:2.1rem;"
          >
            Login
          </router-link>
          <router-link :to="{'name': 'Register'}"
            class="menu-button  float-right"
            style="background-color: black; font-weight: 500;
                 margin-left: 2.1rem;; margin-right: 2.1rem;
                 padding-left: 2.1rem; padding-right:2.1rem;"
          >
            Register
          </router-link>
<!--          <router-link :to="{'name': 'Paradise'}"-->
<!--                       class="menu-button  float-right"-->
<!--                       style="background-color: black; font-weight: 500;-->
<!--                 margin-left: 2.1rem;; margin-right: 2.1rem;-->
<!--                 padding-left: 2.1rem; padding-right:2.1rem;">-->
<!--            Paradise City-->
<!--          </router-link>-->
          <router-link v-if="user" :to="{'name': 'Logout'}"
            class="menu-button  float-right"
            style="background-color: black; font-weight: 500;
                 margin-left: 2.1rem;; margin-right: 2.1rem;
                 padding-left: 2.1rem; padding-right:2.1rem;"
          >
            Logout
          </router-link>
        </div>
</div>
</template>

<script>
import VueClickAway from "vue3-click-away";

export default {
  name: "NavbarHome",
  directives: {
    ClickAway: VueClickAway,
  },
  methods: {
    onClickAway(event) {
      console.log(event);
    },
  },
  computed: {
      user() {
        return this.$store.state.user
      },
    },
};
</script>

<style scoped>
.header {
    /*height: calc(0vh + 5rem);*/
    width: 100%;
    /*position: fixed;*/
    top: 0;
    background-color: white;
    /*padding-top: 2rem;*/
  }
.header-item-right {
  justify-content: end !important;
}
</style>