import api from "../api";
import router from "../router"

export default {
  async init({dispatch}) {
    dispatch('getBoard');
    dispatch('getBoardStatus');
    dispatch('getTask');
    dispatch('getTasklog');
    dispatch('getTeamChat');
    dispatch('getTeamMember');
  },
//  ALL getItems
  async getBoard({commit}) {
    const {data} = await api.getBoard();
    commit('getBoardMutation', data);
  },
  // async getCurrentBoard({commit}) {
  //   const { data } = await api.getCurrentBoard();
  //   commit('getCurrentBoardMutation', data);
  // },
  async getTeamChat({commit}, id) {
    const {data} = await api.getTeamChat(id);
    commit('getTeamChatMutation', data);
  },
  async getMoreTeamChat({commit}, idAndPage) {
    // debugger //eslint-disable-line
    // console.log(data);
    // console.log(data.links);
    // console.log(data.links.next);
    // console.log(idAndPage);
    // console.log(page);
    const {data} = await api.getMoreTeamChat(idAndPage);
     try {
        let response = await api.getMoreTeamChat(idAndPage);
        // console.log('Actions-response', response);
        // console.log('Actions-response', response.status);
        if (response.status === 200) {
          commit('getMoreTeamChatMutation', data);
          return true;
        }
    } catch (error) {
      console.log('error::', error);
      return false;
    }
  },
  async getBoardAccess({commit}, id) {
    const {data} = await api.getBoardAccess(id);
    commit('getBoardAccessMutation', data);
  },
  async getTeamMember({commit}, id) {
    const {data} = await api.getTeamMember(id, ['invited_by', 'member', 'board']);
    commit('getTeamMemberMutation', data);
  },
  async getBoardOrder({commit}, id) {
    // debugger // eslint-disable-line
    try {
      let response = await api.getBoardOrder(id);
      // console.log('Actions-response', response);
      // console.log('Actions-response', response.status);
      if (response.status === 200) {
        commit('getBoardOrderMutation', response.data.results);
        console.log('data', response.data.results);
        return true;
      }
    } catch (error) {
        console.log('error::', error);
      return false;
    }
  },
  async getBoardStatus({commit}, id) {
    const {data} = await api.getBoardStatus(id);
    commit('getBoardStatusMutation', data);
  },
  async getTask({commit}) {
    const {data} = await api.getTask();
    commit('getTaskMutation', data);
  },
  async getTasklog({commit}) {
    const {data} = await api.getTasklog();
    commit('getTasklogMutation', data);
  },
  changeOrder({commit}, newStatus) {
    commit('changeOrderMutation', newStatus);
    newStatus.map(async (val, index) => {
      await api.editBoardStatus(val.id, {order: index + 1, board: val.board, title: val.title});
    });
  },
  async saveBoardOrder({commit}, newOrder) {
    commit('changeBoardMutation', newOrder);
    await api.updateBoardOrder(newOrder.id,  newOrder.order, newOrder.boardOrderId,  newOrder.user);
  },

  // ALL addItems
  async addBoard({commit}, newBoard) {
    const {data} = await api.addBoard(newBoard);
    commit('addBoardMutation', data);
    router.push({name: 'BoardDetail', params: {id: data.id}});
  },

  async addBoardStatus({commit}, newBoardStatus) {
    const {data} = await api.addBoardStatus(newBoardStatus);
    commit('addBoardStatusMutation', data);
  },
  async addTeamChat({commit}, newTeamChat) {
    // const { data } = await api.addTeamChat(newTeamChat);
    // commit('addTeamChatMutation', data);
    // debugger //eslint-disable-line
    try {
      let response = await api.addTeamChat(newTeamChat);
      console.log('Actions-response', response);
      console.log('Actions-response', response.status);
      if (response.status === 201) {
        commit('addTeamChatMutation', newTeamChat);
        return true;
      }
    } catch (error) {
      console.log('error::', error);
      return false;
    }

    // console.log('response:.data', response.data);
    //commit('addTeamMemberMutation', data);
    // return true;

  },
  async addTeamMember({commit}, newTeamMember) {
    // const { data } = await api.addTeamMember(newTeamMember);
    // let response;
    // debugger //eslint-disable-line
    try {
      let response = await api.addTeamMember(newTeamMember);
      // TODO: check if the user is blocked Backend && if blocked tell user
      console.log('Actions-response', response);
      console.log('Actions-response', response.status);
      if (response.status === 201) {
          // commit('addTeamMember', newTeamMember);
          commit('addTeamMemberMutation', newTeamMember);
          return true;
        }
    }
    catch(error) {
      console.log('error::',error);
      return false;
    }
    // debugger //eslint-disable-line
    // console.log('response:.data', response.data);
    //commit('addTeamMemberMutation', data);
    // return true;
  },
  async updateTeamMember({commit}, data) {
    console.log(data)
    // debugger //eslint-disable-line
    try {

      let response = await api.updateTeamMember(data.id,
          data.user_agreement, data.request_seen);

      console.log('Actions-response', response);
      console.log('Actions-response', response.status);
      if (response.status === 201) {
          // commit('addTeamMember', newTeamMember);
          commit('updateTeamMemberMutation', data);
          return true;
        }
    }
    catch(error) {
      console.log('error::',error);
      return false;
    }
    // debugger //eslint-disable-line
    // console.log('response:.data', response.data);
    //commit('addTeamMemberMutation', data);
    // return true;
  },

  async addTask({commit, dispatch, state}, newTask) {
    const { data } = await api.addTask(newTask);
    const boardStatus =  state.boardStatus.find(i => i.id === newTask.status);

    dispatch('getBoardStatus', boardStatus.board);
    commit('addTaskMutation', data);
    const milestones = this.state.tasks.filter(val => val.description === 'unsaved');
    milestones.map(async val => {
      api.editTask(val.id, { title: val.title, parent: data.id, description: '' }).then(res => {
        commit('editTaskMutation', res.data);
      })
    });
  },
  async addTasklog({commit}, tasklog) {
    const { data } = await api.addTasklog(tasklog);
    commit('addTasklogMutation', data);
  },
  async editTask({commit, dispatch, state}, newData) {
    const { data } = await api.editTask(newData.id, newData);
    commit('editTaskMutation', data);
    const boardStatus =  state.boardStatus.find(i => i.id === data.status);

    dispatch('getBoardStatus', boardStatus.board);
    const milestones = this.state.tasks.filter(val => val.description === 'unsaved');
    milestones.map(async val => {
      api.editTask(val.id, { title: val.title, parent: data.id , description: '' }).then(res => {
        commit('editTaskMutation', res.data);
      })
    });
  },
  async addMilestone({commit}, milestone) {
    const { data } = await api.addTask(milestone);
    commit('addTaskMutation', data);
  },
  async updateTasks({commit, dispatch, state}, {value, id}) {
    /* FIXME: build bulk backend function to take tasks and mass update */
    let movedIndex = -1;
    if(id > 0) value.map((val, index) => (val.status !== id ? movedIndex = index : ''));
    commit('updateTasksMutation', {value, id, movedIndex});
    movedIndex > -1 ? await api.editTask(value[movedIndex].id, { status: id}) : '';
    const board = state.boardStatus.find(i => i.id == id)

    value.map(async (val, index) => {
      await api.editTask(val.id, { order: index });
    });
    dispatch('getBoardStatus', board.board)
    
  },

  async editTasklog({commit}, newData) {
    const { data } = await api.editTasklog(newData.id, newData);
    commit('editTasklogMutation', data);
  },
  async updateBlockedUser({commit}, newData) {
    const { data } = await api.updateBlockedUser(newData.id, newData.user);
    commit('updateBlockedUserMutation', data);
  },
  async createBlockedUser(newData) {
    console.log('newData', newData)
    const { data } = await api.createBlockedUser(newData.id, newData.user);
    console.log('createBlockedUser', data)
  },
  // ALL deleteItems
  async deleteBoard({commit}, id) {
    commit('deleteBoardMutation', id);
    await api.deleteBoard(id);
    router.push({name: 'Home'});
  },
  async deleteTeamChat({commit}, id) {
    // commit('deleteTeamChatMutation', id);
    // await api.deleteTeamChat(id);
    try {
          let response = await api.deleteTeamChat(id)
    // console.log('Actions-response', response);
    // console.log('Actions-response', response.status);
    if (response.status === 204) {
        commit('deleteTeamChatMutation', id);
        return true
      }
    } catch(error) {
      console.log('BigError::',error);
      return false;
    }
  },
  async deleteTeamMember({commit}, id) {
    //debugger //eslint-disable-line

    try {
          let response = await api.deleteTeamMember(id)
    // console.log('Actions-response', response);
    // console.log('Actions-response', response.status);
    if (response.status === 204) {
        commit('deleteTeamMemberMutation', id);
        return true
      }
    } catch(error) {
      console.log('BigError::',error);
      return false;
    }
  },
  async deleteBoardStatus({commit}, id) {
    commit('deleteBoardStatusMutation', id);
    await api.deleteBoardStatus(id);
  },
  async deleteTask({commit}, id) {
    commit('deleteTaskMutation', id);
    return await api.deleteTask(id);
  },
  async deleteTasklog({commit}, id) {
    commit('deleteTasklogMutation', id);
    await api.deleteTasklog(id);
  },
  changeBoard({commit}, val) {
    commit('changeBoardMutation', val);
  },
  async updateBoard({commit}, board) {
    // debugger //eslint-disable-line
    await api.editBoard(board.id, board);
    commit('changeBoardMutation', board);
  },
  async updateStatus({commit}, boardStatus) {
    await  api.editBoardStatus(boardStatus.id, {title: boardStatus.title});
    commit('editBoardStatusTitle', boardStatus);
  },
  resetToggle({commit}, val) {
    commit('resetToggleMutation', val);
  },
  resetTimer({commit}) {
    commit('resetTimerMutation');
  },
  resetMenuTimer({commit}, payload) {
    commit('resetMenuTimerMutation', payload);
  },
  resetTimerTaskID({commit}, id) {
    commit('resetTimerTaskIDMutation', id);
  },
  changeWorkCount({commit}, val) {
    commit('changeWorkCountMutation', val);
  },
  async changeHustleTimeCount({commit}, val) {
    // const { data } = await api.updateHustleTime(data);
    // console.log('State:::', this.state)
    commit('changeHustleTimeCountMutation', val);
  },
  changeBreakCount({commit}, val) {
    commit('changeBreakCountMutation', val);
  },
  changeBreathCount({commit}, val) {
    commit('changeBreathCountMutation', val);
  },
  setWorkRun({commit}, val) {
    commit('setWorkRunMutation', val);
  },
  setBreakRun({commit}, val) {
    commit('setBreakRunMutation', val);
  },
  async userLogin({commit}, data) {
    let response;
    try {
      response = await api.login(data)
    } catch(e) {
      console.log(e)
      return false;
    }

    if (response.data.non_field_errors) {
      throw response.data.non_field_errors
    }
    localStorage.setItem('access_token', response.data.token)
    api.setAuthToken(response.data.token)
    commit('setUserLogin', true)
    return true;
  },
  async fetchMe({commit}) {
    const response = await api.fetchMe()
    commit('setUser', response.data)
  },
  async fetchBoardMaster({commit}) {
    const response = await api.fetchBoardMaster()
    commit('setBoardMaster', response.data)
  },
  async updatePassword(data) {
    try {
      // debugger // eslint-disable-line
      const response = await api.updateMe(data)
      console.log('Actions-response', response);
      console.log('Actions-response', response.status);
      if (response.status === 200) {
        return true
      }
    } catch (error) {
      console.log(error)
      return false;
    }
  },
  userLogout({commit}) {
    commit('setUser', false);
    commit('setUserLogin', false);
    api.setAuthToken(false)
    localStorage.clear();
  },
    async userRenewPassword({commit}, data) {
    api.setAuthToken(false)
    localStorage.clear();
    const response = await api.renewPassword(data)
    localStorage.setItem('access_token', response.data.token)
    api.setAuthToken(response.data.token)
    commit('setUserLogin', true)
  },
};