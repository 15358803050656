<template>
  <NavbarHome />
<div class="mx-auto col-xl-6 col-lg-6 col-md-10 col-sm-11 col-xs-11 py-5">

  <!-- Section Time -->
  <div class="row pb-5 row-max">
      <div class=" mx-auto col-12">
          <h1 class="text-center py-3" >Timer</h1>
        <div class="text-center mx-auto w-100 " style="vertical-align: middle !Important;">
            <img src="../assets/sandwatch.png" style="max-height: 300px; max-width: 480px;" class="card-img-top py-4 px-5" alt="...">
        </div>
        <h3 class="" >Time Tracking</h3>
  <div class="row">
    <div class="col-6">
<!--      <img  class="" alt="logo" src="../assets/2021_12_supersuccesslogo.png"-->
<!--            style="width: 80px !important; height: 80px !important;">-->
    </div>
    <div class="col-6">
      <img class="home-img" alt="promodoro_sample" src="../assets/promodoro_sample.png">
    </div>
  </div>
  <h3 class="text-left font-weight-bold">Functions:</h3>
	<ol>
		<li class="li">
			<span class="span">
				Timelog,
			</span>
		</li>
		<li class="li">
			<span class="span">
				keep you motivated,
			</span>
		</li>
		<li class="li">
			<span class="span">
				Promodoro method (Sprint & Rest),
			</span>
		</li>
		<li class="li">
			<span class="span">
				increase Focus with Concentration.
			</span>
		</li>
	</ol>
        <img class="pb-5 home-img boarder" style="max-height: 540px;"  src="../assets/timermenu_sample.png">
        <p>The Promodoro-timer is a tool that track your time & keep you focused. Promodoro’s are usually 25min work (focus) & 5 minutes break (fun).
          Run the Timer by pressing "Play", all your work times are tracked and visible in your “Timer-menu.“
          Press "Stop" to finish the work session.
          </p>

      </div>


          </div>


    <h1>Report</h1>
      <div class="mx-auto text-center">
        <img src="../assets/email-report.png"
             style="max-height: 516px; max-width:780px;">
    </div>
    <p>
        All is made to have a nice record to read as a <b>market letter</b>.
        Yes, market. The market of information that you focus on. This keep
        your focus on your goals and dreams. The structure of mysupersuccess.com
        and process, programs success in your subconscious mind. Every-time you read the
        report, you discover issues and can avoid them before the sabotage happens.
    </p>
    <p><b>What is better to write the own story of life!?</b></p>
    <h2>
        1. Advantage of weekly & monthly reports:
    </h2>

    <ol>
		<li class="li">
			<span class="span">
				display your results,
			</span>
		</li>
		<li class="li">
			<span class="span">
				print, read, make notes,
			</span>
		</li>
		<li class="li">
			<span class="span">
				detect wrong actions,
			</span>
		</li>
        <li class="li">
			<span class="span">
				reflect your outcome,
			</span>
		</li>
        <li class="li">
			<span class="span">
				overview your time-horizon,
			</span>
		</li>
        <li class="li">
			<span class="span">
				find the best solution.
			</span>
		</li>
    </ol>
    <p>
        Pass your monthly report to the next generation of your family,
        they will profit from the knowledge & ideas. With this in mind, your
        inner drive to take smart decisions will be higher.
    </p>

    <h2>2. What does a report include?</h2>
<!--    <h3>A. Your Habits</h3>-->
<!--    <img src="{% static 'img/report_sample_1.png' %}" style="max-width:999px;">-->
<!--    <p>Measures habit-rate, activities & categories you do print your future, ACT NOW! :)</p>-->
    <h3>A. Your Todos</h3>
<!--    <img src="{% static 'img/report_sample_2.png' %}" style="max-width:999px;">-->
    <p>All you boards are simple listed with all of your details, <b>Milestone included.</b></p>
    <h3>B. Your Timelog</h3>
<!--    <img src="{% static 'img/report_sample_3.png' %}" style="max-width:999px;">-->
    <p>An elegant overview of your time-spend.</p>
    <p>The Report give you feedback & the roadmap to success.</p>

</div>

</template>

<script>
import NavbarHome from "@/components/navbar/NavbarHome";
export default {
  name: "BlogTimerReport",
  components: {
    NavbarHome,
  },
}
</script>

<style scoped>

</style>