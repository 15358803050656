<template>
  <h3 v-if="hasError"
      class="alert-danger w-100 text-center">Something went wrong.</h3>

</template>

<script>
export default {
  name: "Oops",
  data() {
    return {
      hasError: false,
    }
  },
  errorCaptured(error, component, info) {
    console.log("An error occured: \n", error,component, info);
    this.hasError = true;
  }
}
</script>

<style scoped>

</style>