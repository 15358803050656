import axios from 'axios';


const BASE_URL = process.env.VUE_APP_BASE_URL;
const urls  = {
  createCustomer: `${BASE_URL}/create-customer/`,
  createSubscription: `${BASE_URL}/create-subscription/`,
  cancelSubscription: `${BASE_URL}/cancel-subscription/`,
  board: `${BASE_URL}/tasks/board/`,
  teamMembers: `${BASE_URL}/tasks/teammembers/`,
  blockedUser: `${BASE_URL}/accounts/blockeduser/`,
  boardOrder: `${BASE_URL}/tasks/boardorder/`,
  teamChat: `${BASE_URL}/tasks/teamchat/`,
  boardStatus: `${BASE_URL}/tasks/boardstatus/`,
  task: `${BASE_URL}/tasks/task/`,
  success: `${BASE_URL}/tasks/setting/`,
  tasklog: `${BASE_URL}/tasks/tasklog/`,
  login: `${BASE_URL}/token/`,
  fetchMe: `${BASE_URL}/me/`,
  register: `${BASE_URL}/accounts/create/`,
  paradiseNewsletter: `${BASE_URL}/accounts/paradisenewsletter/`,
  stripe: `${BASE_URL}/stripe/`,
  setting: `${BASE_URL}/setting/`,
  renewpassword: `${BASE_URL}/api/password_reset/`,
  resetpassword: `${BASE_URL}/api/password_reset/confirm/`,
};

const createCustomer = (data) => (axios.post(urls.createCustomer, data));
const createSubscription = (data) => (axios.post(urls.createSubscription, data));
const cancelSubscription = (data) => (axios.post(urls.cancelSubscription, data));

const getBoard = () => (axios.get(urls.board + "?expand=teammembers"));
const addBoard = (data) => (axios.post(urls.board, data));
const editBoard = (id, data) => (axios.put(urls.board + id + "/", data));
const deleteBoard = (id) => (axios.delete(urls.board + id  + "/"));
const getBoardOrder = (id) => (axios.get(urls.boardOrder + '?setting_id=' + id));
const updateBoardOrder = (id, order, boardOrder_id, user) => (axios.put(urls.boardOrder + id + "/update_order/", {'order' :order, 'boardOrderId': boardOrder_id, 'user': user }));

const getBlockedUser = () => (axios.get(urls.blockedUser));
const createBlockedUser = (data) => (axios.post(urls.blockedUser, data));
const updateBlockedUser = (id, order) => (axios.patch(urls.blockedUser + id + "/", order));

const getTeamMember = (id, expands) => (axios.get(urls.teamMembers + `?board_id=${id}&expand=${expands ? expands.join(','): ''}`));
const getMyTeamMember = (expands) => (axios.get(urls.teamMembers + `?me=1&expand=${expands ? expands.join(','): ''}`));
const addTeamMember = (data) => (axios.post(urls.teamMembers, data));
const updateTeamMember = (id, user_agreement, request_seen) => (axios.put(urls.teamMembers + id + "/", {
              'user_agreement': user_agreement, 'request_seen': request_seen}));
const deleteTeamMember = (id) => (axios.delete(urls.teamMembers + id + "/"));

const getMoreTeamChat = (idAndPage) => (axios.get(urls.teamChat+ idAndPage ));
const getTeamChat = (id) => (axios.get(urls.teamChat + '?board_id=' + id));
const addTeamChat = (data) => (axios.post(urls.teamChat, data));
const deleteTeamChat = (id) => (axios.delete(urls.teamChat + id + "/"));

const getBoardStatus = (id) => (axios.get(urls.boardStatus +'?board_id=' + id  ));
const addBoardStatus = (data) => (axios.post(urls.boardStatus, data));
const editBoardStatus = (id, data) => (axios.patch(urls.boardStatus + id + "/", data));
const deleteBoardStatus = (id) => (axios.delete(urls.boardStatus + id + "/"));

const getTask = () => (axios.get(urls.task));
const addTask = (data) => (axios.post(urls.task, data));
const editTask = (id, data) => (axios.patch(urls.task + id + "/", data));
const deleteTask = (id) => (axios.delete(urls.task + id + "/"));
const addTime = (id) => (axios.post(urls.task + id + "/start_timer/"));

const updateHustleTime = (data,id) => (axios.put(urls.success + id + "/", data));

const getTasklog = (params) => (axios.get(urls.tasklog, {params}));
const addTasklog = (data) => (axios.post(urls.tasklog, data));
const editTasklog = (id, data) => (axios.patch(urls.tasklog + id + "/", data));
const deleteTasklog = (id) => (axios.delete(urls.tasklog + id + "/"));
const login = (data) => (axios.post(urls.login, data));
const logout = (data) => (axios.post(urls.logout, data));
const fetchMe = (data) => (axios.get(urls.fetchMe, data));
const fetchBoardMaster = () => (axios.get(urls.board));
const updateMe = (data) => (axios.put(urls.fetchMe, data));
const register = (data) => (axios.post(urls.register, data));
const renewPassword = (data) => (axios.post(urls.renewpassword, data));
const resetPassword = (data) => (axios.post(urls.resetpassword, data));
const paradiseNewsletter = (data) => (axios.post(urls.paradiseNewsletter, data));

let interceptor = -1;
const setAuthToken = (token) => {

  if(interceptor > -1) {
    console.log('ejecting')
    axios.interceptors.request.eject(interceptor);
  }
  if (!token) {
    return false;
  }
  interceptor = axios.interceptors.request.use(function (config) {
    console.log('token', token, 'interceptor,', interceptor);
    if (token) {
      config.headers.Authorization = `Token ${token}`;
      return config;
    }
  });
}
export default { 
  getBoard, addBoard, editBoard, deleteBoard,
  getBoardStatus, addBoardStatus, editBoardStatus, deleteBoardStatus,
  getTask, addTask, editTask, deleteTask, fetchMe, fetchBoardMaster, setAuthToken,
  getTasklog, addTasklog, editTasklog, deleteTasklog, login, logout,
  register, renewPassword, resetPassword, updateMe, addTime,
  createSubscription, createCustomer, cancelSubscription, updateHustleTime,
  getTeamMember, addTeamMember, updateTeamMember, deleteTeamMember,
  createBlockedUser, getBlockedUser, updateBlockedUser,
  getTeamChat, getMoreTeamChat, addTeamChat, deleteTeamChat,
  paradiseNewsletter, updateBoardOrder, getBoardOrder, getMyTeamMember
};
