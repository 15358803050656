<template>
  <button class="menu-button m-4"
          style="color: black"
          @click="showAddBoard = {}">
    + Add new Board
  </button>

  <add-board :show="shouldShowBoard" :board="showAddBoard" :setShow="setShowBoard" />

</template>

<script>
import {mapGetters} from "vuex";
import AddBoardPanel from "@/components/AddBoardPanel";

export default {
  name: "ButtonAddBoard",
  components: {
    "add-board": AddBoardPanel,
  },
  data() {
    return {
      showAddBoard: false,
      currentBoard: false,
      boardTitle: 'Board Title',
    }
  },
  methods: {
    setShowBoard(val) {
      this.showAddBoard = val;
    },
  },
  computed: {
    ...mapGetters(['isPremiumUser', 'user']),
    user() {
      return this.$store.state.user
    },
    shouldShowBoard() {
      if (!this.user) {
        return false;
      }
      if(this.$store.state.boardCount === 0) {
        return true
      }
      return typeof this.showAddBoard === 'object'
    }
  },
}
</script>

<style scoped>

</style>