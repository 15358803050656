<template>
  <MenuBar />
<h1 class="pt-4 pb-3 font-weight-bold">Timeline</h1>
<!--v-if="isPremiumUser"-->
  <HighchartsGantt v-if="isPremiumUser"
      style=" margin-left: 13px; margin-right: 13px;"
    :options="chartOptions"
    :redrawOnUpdate="true"
    :oneToOneUpdate="false"
    :animateOnUpdate="true"
   />

</template>



<script>
import axios from 'axios'
import api from '@/api';
import MenuBar from "../components/MenuBar";
import {mapGetters} from "vuex";

export default {
  name: 'MyChart',
  components: {MenuBar},
  data() {
    return {
      output: null,
      chartOptions: {
      }

    }
  },
  methods: {
    async print() {
      const el = this.$refs.printMe;
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: 'dataURL'
      }
      this.output = await this.$html2canvas(el, options);
    }
  },
  mounted() {
    this.print()
  },
  computed:{
        ...mapGetters(['isLoggedIn', 'isPremiumUser', 'user']),
  },

  async created() {
    const url = `${process.env.VUE_APP_BASE_URL}/diagrams/task/?board_id=${this.$route.params.id}`
    const response = await axios.get(url)
    const data = response.data
    console.log(data)
    const categories = []
    const chartData = [{'name': 'test', data: []}];
        data.forEach((i) => {
            categories.push(i.title)
            chartData[0]['data'].push({
                name: i.title,
                start: new Date(i.start).getTime(),
                end: new Date(i.end).getTime(),
                pk: i.pk
                //y: index
            })
        })
    this.chartOptions = {
        navigator: {
        enabled: true,
        liveRedraw: true,
        series: {
          type: 'gantt',
          pointPlacement: 0.5,
          pointPadding: 0.25,
          },
        },
        scrollbar: {
          enabled: true
        },
        rangeSelector: {
          enabled: true,
          selected: 4
        },


        yAxis: [{
        title: '',
        type: 'treegrid',
        gridLineWidth: 2,
        labels: {

            align: 'left',
            levels: [{
                level: 1,
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                }
            }, {
                level: 2,
                style: {
                    fontSize: '21px'
                }
            }, {
                level: 3,
                style: {
                    fontSize: '30px'
                }
            }]
        }
    }],
        tooltip: {
            fontSize: 34,
            // backgroundColor: '#FCFFC5',
            // borderColor: 'black',
            // borderRadius: 10,
            // borderWidth: 3
        },
        plotOptions: {
            series: {
                stickyTracking: false,
                borderRadius: 5,
                // label: {
                //   maxFontSize:34,
                //   minFontSize:21,
                //   height: 34,
                // },
              // dataLabels: [{
              //     enabled: true,
              //     format: '<div style="width: 20px; height: 20px; overflow: hidden; border-radius: 50%; margin-left: -25px">' +
              //             '<img src="https://www.highcharts.com/images/employees2014/{point.assignee}.jpg" ' +
              //             'style="width: 30px; margin-left: -5px; margin-top: -2px"></div>',
              //     useHTML: true,
              //     align: 'left'
              //   }, {
              //     enabled: true,
              //     format: '<i class="fa fa-{point.fontSymbol}" style="font-size: 1.5em"></i>',
              //     useHTML: true,
              //     align: 'right'
              //   }],
                dragDrop: {
                draggableX: true,
                draggableY: true,
                dragMinY: 0,
                dragMaxY: 2,
                dragMinX: Date.UTC(2020, 10, 15),
                dragMaxX: Date.UTC(2025, 0, 10),
                liveRedraw: false,
                groupBy: 'groupId' // Group data points with the same groupId
            },
            point: {
                events: {
                    drop: function (e) {
                        // The default action here runs point.update on the
                        // new points. Return false to stop this. Here we stop
                        // the "Group A" points from being moved to the
                        // "Prototyping" row.
                        console.log(e.target.pk, e.newPoint.start, e.newPoint.end)
                        let data = {

                        }
                        if (e.newPoint.start){
                            data['start'] = new Date(e.newPoint.start).toISOString().substr(0, 10)
                        }
                        if (e.newPoint.end) {
                            data['end'] = new Date(e.newPoint.end).toISOString().substr(0, 10)
                        }
                        console.log(data)
                      api.editTask( e.target.pk, data)


                    }
                }
            }}},
          series: chartData
        }
    /*
    const data = ref([1, 2, 3])
    const chartData = computed(() =>{
      return {
        series: [{
          name: 'Test Series',
          data: data.value,
        }],
      }
    });
    const onRender = () => {
      console.log('Chart rendered');
    };

    const onUpdate = () => {
      console.log('Chart updated');
    };

    const onDestroy = () => {
      console.log('Chart destroyed');
    };

    return {
      chartData,
      onRender,
      onUpdate,
      onDestroy,
    };
    */
  }
}
</script>

<style>

highchartsgantt {
  width: 100%;
  min-height: 400px;
  background-color: #42b983;
  font-size: 16px !important;
  color: black !important;
  overflow: hidden !important;
}

.highcharts-point {
  /*height: 1.13rem !important;*/
  /*line-height: 342px !important;*/
  /*padding-bottom: 1.3rem !important;*/
  padding: 5px !important;
  /*border-radius: 5px !important;*/
  /*vertical-align: top !important;*/
}


.highcharts-treegrid-node-level-1 {
  /*font-size: 13px !important;*/
  color: black !important;
  /*overflow: hidden !important;*/
  fill: black !important;
}

.highcharts-xaxis-line {
  height: 1.5rem !important;
  /*overflow: hidden !important;*/
}

</style>