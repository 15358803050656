<template>
  <transition name="modal">
    <div class="modal-container-timer">
      <div class="modal-body-timer">
        <slot name="body">
          <div class="modal-input-group">
            <p class="p-top" style="font-size: 2.1rem;"> Todo: </p>
            <select class="pl-3 py-3 font-weight-bold" style="font-size: 2.1rem;"
                    v-model="task_id" @change="this.$store.dispatch('resetTimerTaskID', this.task_id)">
              <option class="font-weight-bold" style="font-size: 2.1rem;"
                  v-for="value in getTasks" :key="value.id" :value="value.id">
                {{value.title}}
              </option>
            </select>
          </div>

<!--          <form @submit.prevent="">-->


          <div class="seperate-line" />
            <div class="modal-input-group">
              <p>Breaktime/min</p>
              <break-time :type="type"></break-time>
            </div>


            <div class="seperate-line" />
            <div class="modal-input-group">
              <p>Worktime/min</p>
              <work-time :type="type"></work-time>
            </div>

<!--          </form>-->

          <div class="seperate-line" />
          <div class="modal-input-group">
            <button class="modal-step-button" @click="minusNowDate">&lsaquo;</button>
            <input v-model="nowDate" readonly>
            <button class="modal-step-button" @click="plusNowDate">&rsaquo;</button>
          </div>
          <div class="modal-input-group">
            <p class="p-center">Start: </p>
            <input type="time" v-model="startTime">
          </div>
          <div class="modal-input-group">
            <p class="p-center">End: </p>
            <input type="time" v-model="endTime">
          </div>
          <div class="modal-input-group">
            <button class=" modal-step-button mx-3 my-2 py-2 px-3"
                    @click="save">Add time</button>
          </div>
          <div class="seperate-line pb-3" />
          <h5>My Daily Record</h5>

          <table class="log-detail">
            <tr v-for="log in taskLog"
                :key="log.id"
                :value="log.id"
                :models="taskLog">
              <td>{{log.task_title}} by
                <span style="border-radius: 8px; background-color: gold; font-size: 13px; padding: 5px;">
                  {{ log.username }}
                </span>
              </td>
              <td>{{getTime(log.start_time, log.end_time)}}</td>
              <td>
                <i class="fa fa-trash task-icon"
                     @click="deleteLog(log.id)"
                     title="Remove" />
              </td>
            </tr>
            <tr v-if="this.taskLog.length">
              <td />
              <td>Total time : {{getTotalTime()}}</td>
              <td />
            </tr>
          </table>

<!--          <div class="seperate-line pb-3" />-->
<!--          <h5>Teams Daily Record</h5>-->
<!--          <table class="log-detail">-->
<!--            <tr v-for="log in taskLog"-->
<!--                :key="log.id"-->
<!--                :value="log.id"-->
<!--                :models="taskLog">-->
<!--              <td>{{log.task_title}} by-->
<!--                <span style="border-radius: 8px; background-color: gold; font-size: 13px; padding: 5px;">-->
<!--                  {{ log.username }}-->
<!--                </span>-->
<!--              </td>-->
<!--              <td>{{getTime(log.start_time, log.end_time)}}</td>-->
<!--              <td>-->
<!--                <i class="fa fa-trash task-icon"-->
<!--                     @click="deleteLog(log.id)"-->
<!--                     title="Remove" />-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr v-if="this.taskLog.length">-->
<!--              <td />-->
<!--              <td>Total time : {{getTotalTime()}}</td>-->
<!--              <td />-->
<!--            </tr>-->
<!--          </table>-->

        </slot>
      </div>
    </div>
  </transition>
</template>

<script>

import api from '@/api';
import moment from 'moment';
import BreakTime from "@/components/UI/BreakTime";
import WorkTime from "@/components/UI/WorkTime";

export default {
  name: 'Timer',
  props: ['type'],
  components: {
    WorkTime,
    BreakTime,
  },
  data() {
    return {
      task_id: this.$store.state.timerTaskID,
      startTime: null,
      endTime: null,
      taskLog: [],
      nowDate: new Date().toISOString().slice(0, 10),
      user: this.$store.state.user.id,
      username: this.$store.state.user.name,
    };
  },
  computed: {
    getTasks() {
      const statusArr = this.$store.state.boardStatus; //.filter(val => val.board === this.$store.state.currentBoard);
      let taskArr = []
      statusArr.forEach(i => {
        taskArr = taskArr.concat(i.tasks)
      })
      //const taskArr = this.$store.state.tasks.filter(val => statusArr.some(value => value.id === val.status));
      taskArr.sort((a, b) => (a.id > b.id) ? 1 : (a.id < b.id) ? -1 : 0);
      return taskArr;
    },

      // ...mapGetters(['user']),


  },
  methods: {
    getTime(from, to) {
      const strFrom = new Date(from.slice(0, 19));
      const strTo = new Date(to.slice(0, 19));
      const duration = this.getDuration((strTo - strFrom) / 1000);
      return strFrom.toTimeString().slice(0,5) + ' - ' + strTo.toTimeString().slice(0,5) + '\t\t' + duration;
    },
    getDuration(val) {
      let h = Math.trunc(val / 3600);
      let m = Math.trunc((val - h * 3600) / 60);
      m = m === 0 && h === 0 ? 1 : m;
      m = m < 10 ? '0' + m : m;
      h = h < 10 ? '0' + h : h;
      return h + ':' + m + 'h';
    },
    getTotalTime() {
      const duration = this.taskLog.reduce((total, val) => {
        const strFrom = new Date(val.start_time.slice(0, 19));
        const strTo = new Date(val.end_time.slice(0, 19));
        total += (strTo - strFrom);
        return total;
      }, 0);
      return this.getDuration(duration / 1000);
    },
    async minusNowDate() {
      const newDate = new Date(this.nowDate);
      newDate.setDate(newDate.getDate() - 1);
      this.nowDate = newDate.toISOString().slice(0, 10)
    },
    async plusNowDate() {
      const newDate = new Date(this.nowDate);
      newDate.setDate(newDate.getDate() + 1);
      this.nowDate = newDate.toISOString().slice(0, 10)
    },
    async deleteLog(id) {
      await this.$store.dispatch('deleteTasklog', id);
      this.getTaskLog(this.nowDate)
    },
    close() {
      this.$store.dispatch('resetTimer');
    },
    async save() {
      if(this.startTime !== null && this.endTime !== null) {
        if(this.startTime > this.endTime)
          alert("Start time can't be bigger than end time!");
        else{
          const start_time = this.nowDate + 'T' + this.startTime;
          const end_time = this.nowDate + 'T' + this.endTime;
          await this.$store.dispatch('addTasklog', {
            start_time,
            end_time,
            task: this.task_id,
            user: this.user,
            username: this.username,
          });
          this.getTaskLog(this.nowDate)
          this.$store.dispatch('getBoardStatus', this.$route.params.id)
        }
      }
      else
        alert('input time');
    },
    async getTaskLog(date) {
      const response = await api.getTasklog({
        // TODO: add Board_id
        // id: 'board_id',
        start_time__gte:moment(date).format(),
        end_time__lte:moment(date).add(1, 'day').format(),
        // ordering: 'user__-start_time'
      })
      this.taskLog = response.data.results
    }
  },
  async mounted() {
    this.getTaskLog(this.nowDate)
    // this.$store.dispatch('getTasklog', this.$route.params.id)
  },
  watch: {
    nowDate() {
      this.getTaskLog(this.nowDate)
    },
  }
};
</script>
