<template>
<NavbarHome />

<!--   paradise Pitch    -->
<div class="row mx-auto background-img"
     style="min-height: 720px; min-width: 300px ; width: 100%; background-color: black !important;
       background-size: cover !important; z-index: 100 !important;">

  <div class="p-5 p-sm-1 mx-auto text-black">
    <h1 class="mt-4">Discover the <span class="" style="color: crimson;">“Super Success Paradise”</span></h1>
<!-- <= lg title -->
        <div class="example special-list d-lg-none" style="width: 80% !important;">
          <h3 style="font-weight: 600;">a Place where You can Live a Peaceful Exciting Life</h3>
          <h5 style="font-weight: 600;">Where the Community meet-up as team.</h5>
          <ol class="mt-lg-3 mt-md-2" style="">
            <li>Session: 01.05.2023 - 28.02.2024 </li>
            <li>First Person 333 USD/d Bungalow + 2nd Person 200 USD/d</li>
          </ol>
        </div>


  </div>

      <div class="row mx-auto p-0 m-0 example-list "
           style="width: 100%;
           background: transparent !important;">

        <div class="example special-list d-none d-lg-block" style="width: 61% !important;">
          <h3>a Place where You can Live a Peaceful Exciting Life</h3>
          <h5>Where the Community meet-up as team.</h5>
          <ol class="mt-5">
            <li class="">Session: 01.05.2023 - 28.02.2024 </li>
            <li class="">First Person 333 USD/d Bungalow + 2nd Person 200 USD/d</li>
          </ol>
        </div>
<!--      <div class="">-->
        <div class="float-right d-none d-lg-block" style="width:39% !important; min-width: 377px; ">
          <h3 class="font-weight-bold special-list text-left">What We Offer</h3>
          <ol style="padding-bottom: 30px;" class="special-list ">
            <li>34 Privat Bungalows</li>
            <li>High Speed Internet 24/7</li>
<!--            <li>Independent Energy Sources</li>-->
            <li>Peaceful Place in the Caribbean Jungle</li>
<!--            <li>not far from the Beach</li>-->
            <li>Breakfast & Dinner</li>
<!--            <li>Dinner with Sunset View</li>-->
<!--            <li>Exclusive Tours from Charly Goodman</li>-->
            <li>Daily Adventures (THE BEST EVER)</li>
            <li>Super Success Cafe</li>
            <li>Super Success Office</li>
            <li class="">Super Success Workplace</li>
            <li class="">Super Success Chill-Area with Hammocks</li>
<!--            <li>Psytrance Events</li>-->
<!--            <li>Retreats</li>-->
            <li class="">Sauna Tent & Pool</li>
<!--            <li>Spa & Relaxation</li>-->
          </ol>
        </div>
<!--      </div>-->
    </div>
</div>
  <div style="background-color: black !important;">
      <div class="mx-auto text-center w-100 text-black font-weight-bold" style="min-width: 300px;">
        <p style="color: black !important;" class="alert-success bg-green-500 w-100 px-4 rounded"
                v-if="message">Successful added to Newsletter.</p>
          <p style="color: black !important;" class="alert-danger bg-red-500 w-100 px-4 rounded text-black font-weight-bold"
                v-if="error">Sorry something went wrong.</p>
        <form @submit.prevent="saveNewsletter" class="row-cols-auto example-inline"
              style="display: inline-flex; justify-content: center; align-items: center">

          <p class="text-white mx-auto pt-3 col text-center" style="max-width: 300px">Join Super Success Paradise</p>

          <input type="email"
                 name="email"
                 class="form-control mx-auto hide btn-warning"
                 placeholder="type your email ..."
                 width="210px" style="width: 210px; display: none !important;">

          <input v-model="paradiseNewsletterName"
                 type="text"
                 class="form-control mx-auto col"
                 placeholder="type your Username ..."
                 width="210px" style="width: 210px" required>

          <input v-model="paradiseNewsletter"
                 type="email"
                 class="form-control mx-auto col"
                 placeholder="type your email ..."
                 width="210px" style="width: 210px" required>

          <button type="submit" class="btn m-0 col"
                  style="width: 210px; background-image: linear-gradient(lightgoldenrodyellow,goldenrod,gold);">
            I am Interested
          </button>



        </form>
      </div>
  <small class="text-center text-white col-10 mx-auto example-inline" >
            * I agree that the contact details provided by me will only be used to contact you for a binding order
            regarding a stay at Super Success Paradise.
    Subsequently, or as soon as the data is no longer needed to contact me, my data will be deleted.
  </small>
<!-- d-xs-none d-sm-none d-md-none -->
        <div class="mx-auto example d-lg-none element">
          <div style="width:39% !important; min-width: 377px; color: white" class="mx-auto">
          <h3 class="font-weight-bold text-left">We Offer</h3>
            <ol style="padding-bottom: 30px;">
              <li>34 Privat Bungalows</li>
              <li>High Speed Internet 24/7</li>
<!--              <li>Independent Energy Sources</li>-->
              <li>Peaceful Place in the Caribbean Jungle</li>
<!--              <li>not far from the Beach</li>-->
              <li>Breakfast in the Garden</li>
              <li>Dinner with Sunset View</li>
              <li>Exclusive Tours from Charly Goodman</li>
              <li>Daily Adventures (THE BEST EVER)</li>
              <li>Super Success Cafe</li>
              <li>Super Success Workplace</li>
              <li>Super Success Chill-Area with Hammocks</li>
              <li>Psytrance Events</li>
              <li>Retreats</li>
              <li>Sauna Tent & Pool</li>
<!--              <li>Spa & Relaxation</li>-->
            </ol>
          </div>
        </div>

      <div class="">
        <div class="row mx-auto" style="max-width: 1200px; ">
          <div class="col-lg-6 col-xl-6 col-11 text-left" style="color: white !important;">
            <h3 class="">Hello I am your host Charly Goodman.</h3>
            <ol>
              <li class="text-white">I organise everything you need.</li>
              <li class="text-white">I create the best living experience you can dream of.</li>
              <li class="text-white">From daily tours up to 8days of jungle & mountain trekking.</li>
              <li class="text-white">Spa, Relaxation, Meditation, Yoga</li>
              <li class="text-white">Self-Finding & Self-Development classes with the community as class or privat.</li>
            </ol>
          </div>
          <div class="col-lg-6 col-xl-6 col-11 text-right ">
            <img src="../assets/paradise/sumatra.png" class="art-img">
          </div>
        </div>
      </div>

<!--  <div class="pitch-card mx-auto">-->
<!--    <img src="../assets/boat.jpg" class="pitch-img" style="">-->
<!--    <h3 class="">I am your host Charly Goodman and I do EVERYTHING to create the BEST LIVING EXPERIENCE for YOU.</h3>-->
<!--  </div>-->



  <h1 class="text-white mt-4">Our Activities</h1>
  <div class="row row-cols-auto mx-auto">



    <div class="col pitch-card ">
      <img src="../assets/paradise/yoga-stand.png" class="pitch-img">
      <h3 class="">Yoga</h3>
      <p>Our Beginner Ashtanga Yoga Class (60min) is focusing on your meridian to recover physically & mentally.
      This will give you a strong relaxation effect & will sharpen your mind.
      </p>
    </div>


    <div class="col pitch-card mx-auto ">
      <img src="../assets/paradise/meditation.png" class="pitch-img">
      <h3 class="">Meditation</h3>
      <p>20min Meditation, at our jungle spot closed to the waterfall is meditation time at 5am & 8pm.
      You can go there and meditate any time. This is our quiet place.
      </p>
    </div>
<!--    <div class="col pitch-card mx-auto ">-->
<!--      <img src="../assets/paradise/beach-2.png" class="pitch-img">-->
<!--      <h3 class="">Beach</h3>-->
<!--      <p>Imagine the Stars</p>-->
<!--    </div>-->
<!--    <div class="col pitch-card mx-auto ">-->
<!--      <img src="../assets/paradise/sunset.png" class="pitch-img">-->
<!--      <h3 class="">Sunsets</h3>-->
<!--      <p>Imagine the Stars</p>-->
<!--    </div>-->

    <div class="col pitch-card mx-auto ">
      <img src="../assets/paradise/Boat-Tour.png" class="pitch-img">
      <h3 class="">River Tours</h3>
      <p>Join an wild experience Charly Goodman show you how to survive in the jungle.
      If you want we can camp there and sleep in the hammock.
      </p>
    </div>




    <div class="col pitch-card mx-auto ">
      <img src="../assets/paradise/handstand.png" class="pitch-img">
      <h3 class="">Acrobatics & Fitness</h3>
      <p>Alex Goodman bring you in shape, here we train cardio, kick-boxing and crossfit together. </p>
    </div>
    <div class="col pitch-card mx-auto ">
      <img src="../assets/paradise/Psytrance.png" class="pitch-img">
      <h3 class="">Psytrance Forest</h3>
      <p>Once a month we organize our own festival on a secread place in the jungle and having the best time ever.</p>
    </div>
    <div class="col pitch-card mx-auto ">
      <img src="../assets/paradise/retreat.png" class="pitch-img">
      <h3 class="">Retreats</h3>
      <p>Once a month we go deep in the jungle detox 1-2 days and having 2-4 days to do retreats.
      </p>
    </div>
    <div class="col pitch-card mx-auto ">
      <img src="../assets/paradise/jungle-rainbow.png" class="pitch-img">
      <h3 class="">Nature Tour</h3>
      <p>We offer all kind of nature tours from 2h upto 21d, we make you sure you are satisfied & happy.</p>
    </div>
<!--    <div class="col pitch-card mx-auto ">-->
<!--      <img src="../assets/paradise/nighttrakking.png" class="pitch-img">-->
<!--      <h3 class="">Night Hikking</h3>-->
<!--      <p>Imagine the Stars</p>-->
<!--    </div>-->
    <div class="col pitch-card mx-auto ">
      <img src="../assets/paradise/mountain.png" class="pitch-img">
      <h3 class="">Mountain Tours</h3>
      <p>Last for about 3-4 day and is very nice to clear the mind in high altitude.</p>
    </div>


    <div class="col pitch-card mx-auto ">
      <img src="../assets/paradise/coffee.png" class="pitch-img">
      <h3 class="">Super Success Cafe</h3>
      <p>We have office facilities for seminars & teamwork, whiteboards, monitors and spare parts.
      Enjoy Coffee & tasty food, is a great place to network and get some work done.
      </p>
    </div>
<!--    <div class="col pitch-card mx-auto ">-->
<!--      <img src="../assets/paradise/whale.png" class="pitch-img">-->
<!--      <h3 class="">We take You on boat-, fishing-, snorkeling- & diving-tours.</h3>-->
<!--    </div>-->

    <div class="col pitch-card mx-auto ">
      <img src="../assets/paradise/boat.png" class="pitch-img">
      <h3 class="">Boat Tours</h3>
      <p>On the weekend we go fishing & sail around beautiful places you are welcome to join us.</p>
    </div>
    <div class="col pitch-card mx-auto ">
      <img src="../assets/paradise/beach_title.png" class="pitch-img">
      <h3 class="">Beach</h3>
      <p>We have incredible beaches & quiet places to chill-out.</p>
    </div>

<!--    <div class="col pitch-card mx-auto ">-->
<!--      <img src="../assets/paradise/mountain-2.png" class="pitch-img">-->
<!--      <h3 class="">Fun in the Mountains</h3>-->
<!--      <p>Imagine the Stars</p>-->
<!--    </div>-->
<!--    <div class="col pitch-card mx-auto ">-->
<!--      <img src="../assets/paradise/beachtours.png" class="pitch-img">-->
<!--      <h3 class="">Beach Tours</h3>-->
<!--    </div>-->
<!--    <div class="col pitch-card mx-auto ">-->
<!--      <img src="../assets/paradise/garden-tours.png" class="pitch-img">-->
<!--      <h3 class="">Garden Tours</h3>-->
<!--    </div>-->
<!--    <div class="col pitch-card mx-auto ">-->
<!--      <img src="../assets/paradise/yoga-upwardfacing.png" class="pitch-img">-->
<!--      <h3 class="">Yoga</h3>-->
<!--      <p>Imagine the Stars</p>-->
<!--    </div>-->
<!--    <div class="col pitch-card mx-auto ">-->
<!--      <img src="../assets/paradise/garden-3.png" class="pitch-img">-->
<!--      <h3 class="">Garden</h3>-->
<!--      <p>Imagine the Stars</p>-->
<!--    </div>-->
  </div>

  <!-- Art background-color: white;
  color: black; margin: 0;-->
  <div class="row mx-auto text-center" style="max-width: 1200px; ">
    <h1 class="text-white ">Our Home</h1>

    <div class="col-lg-6 col-xl-6 col-md-11 col-11">
      <img src="../assets/paradise/g-yoga-garden.png" class="art-img">
    </div>
    <div class="col-lg-6 col-xl-6 col-md-11 col-11 text-left " style="color: white !important;">
      <h3 class="">Spa, Sauna & Relaxation</h3>
      <p style="color: white !important;">From Monday to Friday, evening at 6pm.</p>
    </div>

    <div class="col-lg-6 col-xl-6 col-md-11 col-11 ">
      <img src="../assets/paradise/garden-2.png" class="art-img">
    </div>
    <div class="col-lg-6 col-xl-6 col-md-11 col-11 text-left " style="color: white !important;">
      <h3 class="text-left">Breakfast</h3>
      <p style="color: white !important;" class="text-left">From Monday to Sunday, every morning 6:00am to 9:30am.</p>
    </div>


    <div class="col-lg-6 col-xl-6 col-md-11 col-11">
      <img src="../assets/paradise/beach_mountain_view.png" class="art-img">
    </div>
    <div class="col-lg-6 col-xl-6 col-md-11 col-11 text-left " style="color: white !important;">
      <h3 class="">Dinner</h3>
      <p style="color: white !important;">From Monday to Sunday, every evening 5:00pm to 7:30pm.</p>
    </div>

    <div class="col-lg-6 col-xl-6 col-md-11 col-11  ">
      <img src="../assets/paradise/privat_sweet.png" class="art-img">
    </div>
    <div class="col-lg-6 col-xl-6 col-md-11 col-11 text-left " style="color: white !important;">
      <h3 class="text-left">Private Bungalow</h3>
      <p class="text-left" style="color: white !important;">Privat Garden, Privat Bathroom, Privat Office.</p>
    </div>


    <div class="col-lg-6 col-xl-6 col-md-11 col-11  ">
      <img src="../assets/paradise/room.png" class="art-img">
    </div>
    <div class="col-lg-6 col-xl-6 col-md-11 col-11 text-left " style="color: white !important;">
      <h3 class="">Spa, Sauna & Relaxation</h3>
      <p style="color: white !important;">From Monday to Saturday, morning & evening</p>
    </div>



    <div class="col-lg-6 col-xl-6 col-md-11 col-11 ">
      <img src="../assets/paradise/garden-3.png" class="art-img">
    </div>
    <div class="col-lg-6 col-xl-6 col-md-11 col-11 text-left" style="color: white !important;">
      <h3 class="">Bungalow for 2</h3>
      <p style="color: white !important;">We have air condition, hot water supply and electricity 24/7.</p>
    </div>

    <div class="col-lg-6 col-xl-6 col-md-11 col-11 ">
      <img src="../assets/paradise/bangalow.png" class="art-img">
    </div>
    <div class="col-lg-6 col-xl-6 col-md-11 col-11 text-left" style="color: white !important;">
      <h3 class="">Bungalow</h3>
      <p style="color: white !important;">Your bungalow will be every day cleaned.</p>
    </div>



  </div>


      <div class="mx-auto text-center w-100 text-black font-weight-bold" style="min-width: 300px;">
        <p style="color: black !important;" class="alert-success bg-green-500 w-100 px-4 rounded"
                v-if="message">Successful added to Newsletter.</p>
          <p style="color: black !important;" class="alert-danger bg-red-500 w-100 px-4 rounded text-black font-weight-bold"
                v-if="error">Sorry something went wrong.</p>
        <form @submit.prevent="saveNewsletter" class="row-cols-auto example-inline"
              style="display: inline-flex; justify-content: center; align-items: center">

          <p class="text-white mx-auto pt-3 col text-center" style="max-width: 300px">Join Super Success Paradise</p>

          <input type="email"
                 name="email"
                 class="form-control mx-auto hide btn-warning"
                 placeholder="type your email ..."
                 width="210px" style="width: 210px; display: none !important;">

          <input v-model="paradiseNewsletterName"
                 type="text"
                 class="form-control mx-auto col"
                 placeholder="type your Username ..."
                 width="210px" style="width: 210px" required>

          <input v-model="paradiseNewsletter"
                 type="email"
                 class="form-control mx-auto col"
                 placeholder="type your email ..."
                 width="210px" style="width: 210px" required>

          <button type="submit" class="btn m-0 col"
                  style="width: 210px; background-image: linear-gradient(lightgoldenrodyellow,goldenrod,gold);">
            I am Interested
          </button>



        </form>
      </div>
    <small class="text-center text-white col-10 mx-auto example-inline" >
            * I agree that the contact details provided by me will only be used to contact you for a binding order
            regarding a stay at Super Success Paradise.
    Subsequently, or as soon as the data is no longer needed to contact me, my data will be deleted.
  </small>
</div>




</template>

<script>
import NavbarHome from "@/components/navbar/NavbarHome";

import api from "@/api";

  export default {
    name: 'Home',
    components: {
      NavbarHome,
    },
    data() {
      return {
        paradiseNewsletterName: '',
        paradiseNewsletter: '',
        error: false,
        message: false,
      }
    },
    methods: {
      async saveNewsletter() {
        this.error= false
        this.message= false
        console.log('email', this.paradiseNewsletterName)
        const data = {
          email_paradise_newsletter : this.paradiseNewsletter,
          name : this.paradiseNewsletterName,
        }
      try {
        const response = await api.paradiseNewsletter(data)
          .then((response) => {
            // debugger // eslint-disable-line
            if (response.status === 201) {
              this.message = 'Successful added to Newsletter.';
              this.paradiseNewsletter= '';
              this.paradiseNewsletterName= '';
              setTimeout(() => {
                this.message = false;
              }, 3400);
            } else {
              this.error = true;
              throw new Error('Something went wrong. Maybe you are already signed up.');
            }
          })
        console.log('Response:::', response);
      }  catch(error) {
          console.log(error);
          this.error = true;
          setTimeout(() => {
                this.error = false;
          }, 3400);
          return;
      }
      }
    }

  }
</script>

<style scoped>
a {
  text-decoration-line: none !important;
}

@media screen and (max-width: 987px) {
  .example {
    width: 100% !important;
    background-color: transparent !important;
    z-index: 50;
    color: black !important;
    margin-left: auto !important;
    margin-right: auto !important;
    /*margin-top: -8.9rem;*/
  }
}

@media screen and (max-width: 987px) {
  .move-to-top {
    margin-top: -8.9rem;
  }
}

@media screen and (max-width: 987px) {
  .example-inline {
    display: inline !important;
    text-align: center !important;
    margin: auto !important;
    background-color: black !important;
  }
}

@media screen and (max-width: 987px) {
  .example-list {
    background-color: black !important;
    color: white !important;
  }
}

@media screen and (max-width: 610px) {
  .special-list {
    text-shadow: 0 0 8px black, 0 0 8px black !important;
    color: white !important;
  }
}

.special-list {
    text-shadow: 0 0 8px white, 0 0 8px white ;

  }

.background-img {
  background-image: url('http://s3.eu-central-1.amazonaws.com/mysupersuccess.com/img/PDF/beach-background.png');
  z-index: auto;
  background-size: cover !important;
  /*background-repeat: repeat-x !important;*/
  /*width: 61%;*/
}

/*.background-img {*/
/*  background-image: url('/src/assets/paradise/beach_title.png') !important;*/
/*  z-index: auto;*/
/*  background-size: cover;*/
/*}*/

.art-img {
  width: 100%;
  max-width: 600px;
  border-radius: 0px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.pitch-img {
  margin-top: 1.5rem !important;
  object-fit: cover;
  width: 300px;
  height: 300px;
  border-radius: 13px;
}

p {
  color:white !important;
}

.pitch-card {
  width: 377px;
  color: white;
  background-color: black;
  border: none;
  border-radius: 13px;
  margin-right: auto !important;
  margin-left: auto !important;
  margin-top: 1.61rem;
  margin-bottom: 1.61rem;
  text-align: center;
  transition: all 0.6s;
}

li {
  list-style: disc;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  line-height: 2.1rem;
}

.pitch-card:hover {
    transform: translateY(-13px);
  }


</style>