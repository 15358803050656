<template>
    <div class="log"></div>
</template>

<script>
    export default {
        created(){
            this.$store.dispatch('userLogout')
            this.$router.push({ name: 'Login' })
        }
    }
</script>