<template v-if="" style="background-color: white; " id="scroller">
<!--isPremiumUser-->
  <MenuBar style="background-color: white;"/>
  <div id="anchor"
           class="anchor" href="#anchor"
           >
<!--    :class="{ anchor: this.activeAnchor }" -->
  </div>
  <oops v-if="hasError" style="background-color: white;">{{error}}</oops>
  <div v-if="user && isPremiumUser"
       class="board"
       style="top:0 !important; background-color: white; min-height:1597px;">

    <draggable class="board-group" chosen-class="ghost"
               v-model="boardStatus" :group="{ name: 'board' }"
               handle=".card-header">
      <div class="col-md" v-for="key in boardStatus" :key="key.title">
        <task-lane :id="key.id" :title="key.title" :tasks="key.tasks"></task-lane>
      </div>
      <div class="px-3">
        <input v-model="newStatus" class="new-status-input" placeholder="+ Add Status" @change="onInput">
      </div>
    </draggable>

  </div>


      <button style="bottom:89px; right:21px; position: fixed; z-index: 100 !important;"
                class="menu-button"
                @click="memberWindow = !memberWindow"
          >
        Board Members
      </button>

      <a v-if="returnAnchorTrue" href="#" @click="returnAnchor">
        <button style="bottom:21px; right:21px; position: fixed; z-index: 100 !important;"
            class="menu-button"
            @click="chatWindow = !chatWindow"
            >Chat
          <small>
            {{teamChatCount}}
          </small>
        </button>
      </a>

      <a v-else href="#anchor" @click="returnAnchor">
        <button style="bottom:21px; right:21px; position: fixed; z-index: 100 !important;"
            class="menu-button"
            @click="chatWindow = !chatWindow"
            >Chat
          <small>
            {{teamChatCount}}
          </small>
        </button>

      </a>


    <div v-if="chatWindow" class="chat-window-position"
          style="width: 91%;
          overflow-anchor: none !important;">
      <chat-window
          :id="chatWindow"
          :is-chatWindow="true"
          :returnAnchorTrue="false"
          :chat-window="chatWindow"
          @chat-window="toggleChatWindow"
          @change="teamChatCount"
      >
      </chat-window>
    </div>


    <div class="mx-auto chat-window-position"
          style="width: 91%"
          v-if="memberWindow">

      <div v-if="memberWindow"
           class="chat-window-paint"
           style="width: 500px;">

        <member-list
            :id="memberListId"
            :key="memberWindow"
            :member-window="memberWindow"
            :is-member-window="true"
            @member-window="toggleWindow"></member-list>

        <member-window
            v-if="boardMaster"
            :id="memberWindowId"
            :key="memberWindow"
            :member-window="memberWindow"
            :is-member-window="true"
            @member-window="toggleWindow"
        ></member-window>

      </div>

    </div>


</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next';
import TaskLane from "../components/TaskLane";
import MenuBar from "../components/MenuBar";
import {mapGetters} from "vuex";
import ChatWindow from "@/components/Chat/ChatWindow";
import MemberWindow from "@/components/Chat/MemberWindow";
import MemberList from "@/components/Chat/MemberList";
import Oops from "@/components/UI/Oops";
export default {
  name: 'Board',
  components: {
    MemberWindow,
    MemberList,
    MenuBar,
    draggable: VueDraggableNext,
    "task-lane": TaskLane,
    ChatWindow,
    Oops,
  },

  data() {
    return {
      newStatus: '',
      chatWindow: false,
      memberWindow: false,
      hasError: false,
      returnAnchorTrue: false,
      teamMembers: this.$store.state.teamMembers,
      // teamChatCount: this.$store.state.teamChatCount,
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'isPremiumUser', 'user', 'boardMaster']),
    boardStatus: {
      get() {
        const boardStatus = this.$store.state.boardStatus;
        const currentBoard = Number(this.$route.params.id);
        // this.scrollUp();
        return boardStatus.filter((val) => (val.board === currentBoard));
      },
      set(value) {
        this.$store.dispatch('changeOrder', value);
      }
    },
    teamChatCount() {
      if (this.$store.state.teamChatCount === '') {
        return this.$store.state.teamChatCount
      }
      return this.$store.state.teamChatCount
    },
    // scrollUp() {
    //   router.afterEach((to, from) => {
    //     window.scrollTo(0, 0);
    //   });
    // },
  },

  mounted() {
    this.$store.dispatch('getBoardStatus', this.$route.params.id);
    this.$store.dispatch('getTeamChat', this.$route.params.id);
  },
  // updated() {
  //   let scrollBox = document.querySelector('.scrollBox');
  //   scrollBox.scrollTop = scrollBox.clientHeight + scrollBox.scrollHeight;
  // //   if (this.returnAnchorTrue === false) {
  // //     return { anchor: this.activeAnchor };
  // //   }
  //   },
  watch: {
    '$route.params.id'() {
      this.$store.dispatch('getBoardStatus', this.$route.params.id)
    },
  },
  methods: {
    scrollUp() {
      // let scrollBox = document.querySelector('.scrollBox');
      // scrollBox.scrollTop = scrollBox.clientHeight + scrollBox.scrollHeight;


    },
    onInput() {
      if(this.newStatus !== '') {
        const boardId = this.$route.params.id
        const boardStatus = this.$store.state.boardStatus.filter((val) => (val.board === boardId));
        //debugger //eslint-disable-line
        const orderArr = boardStatus.map(val => val.order);
        const max = orderArr.length > 0 ?  Math.max(...orderArr) + 1 : 0;
        this.$store.dispatch('addBoardStatus', {title: this.newStatus, board: boardId, order: max});
        this.newStatus = '';
      }
    },
    toggleWindow() {
      this.memberWindow = !this.memberWindow
    },
    toggleChatWindow() {
      this.chatWindow = !this.chatWindow;
    },
    returnAnchor() {
      return this.returnAnchorTrue = !this.returnAnchorTrue;
    },
  },
  errorCaptured(error, component, info) {
    console.log("An error occured: \n", error,component, info);
    this.hasError = true;
  }
}

</script>
<style scoped>
.nothing {}
.chat-window-position {
  min-width: 300px;
  width: 69%;
  position: fixed;
  bottom: 13px;
  margin-left: auto !important;
  margin-right: auto !important;
}
#scroller * {
    /* don't allow the children of the scrollable element to be selected
    as an anchor node */
    overflow-anchor: none !important;
}

#anchor {
    /* allow the final child to be selected as an anchor node */
    overflow-anchor: revert !important;
    /* anchor nodes are required to have non-zero area */
    height: 1px;
}
</style>
