<template>
  <transition name="modal" v-if="show">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <h3>Add Title</h3>
            </slot>
          </div>
          <div class="modal-body">
            <slot name="body">
              <input class="modal-input" type="text" v-model="form.board_title" placeholder="Title *">
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button class="menu-button order-0 float-left"
                      style="width: 144px;"
                      @click="close">
                Close
              </button>
              <button class="menu-button order-1 float-right"
                      style="width: 144px;"
                      @click="save">
                Save
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <MessageBox :show="isMessage" :setShow="setIsMessage" comment="You should input title field!" type="alert" />
</template>

<script>
import MessageBox from './MessageBox';

export default {
  name: 'AddBoardPanel',
  props: ['show', 'setShow', 'board'],
  components: {
    MessageBox
  },
  data() {
    return {
      form: {
        board_title: '',
        id: ''
      },
      isMessage: false,
    };
  },
  watch: {
    board() {
      this.form.board_title = this.board.board_title;
      this.form.id = this.board.id;
    }
  },
  methods: {
    close() {
      this.board_title = '';
      this.setShow(false);
    },
    save() {
      if (this.form.board_title) {
        if(this.form.id > 0) {
          this.$store.dispatch('updateBoard', {board_title: this.form.board_title, id: this.form.id});
        } else {
          this.$store.dispatch('addBoard', {board_title: this.form.board_title});
        }
        this.close();
      } else this.setIsMessage(true);
    },
    setIsMessage(val) { 
      this.isMessage = val; 
    }
  },
};
</script>
