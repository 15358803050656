<template>
  <navbar-home></navbar-home>
<div class="row">
   <div class="mx-auto col-8">
     <h1>General Terms and Conditions Customer information</h1>



<div class=WordSection1>

<p class=berschrift><span style='font-family:"Calibri",sans-serif'>General
terms and conditions customer information</span></p>

<p class=MsoNormal><b><span lang=DE style='font-family:"Calibri",sans-serif'></span></b></p>

<p class=MsoListParagraph style='text-indent:-18.15pt'><b><span lang=DE
style='font-family:"Calibri",sans-serif'>1.<span style=' "Times New Roman"'>
</span></span></b><b><span lang=DE style='font-family:"Calibri",sans-serif'>Scope,
definitions and subject matter of the contract</span></b></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>1.1.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>The following general terms and
conditions (hereinafter referred to as &quot;GTC&quot;)</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'></span><span
lang=DE style='font-family:"Calibri",sans-serif'>apply to all business
relationships between</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'>Software
Henker</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'>Fellenbergstr.
63</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'>9000
St. Gallen</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'>Tel.:
+ 41 (0) 76 7250 700</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'>E-Mail:
</span><span lang=DE style='font-family:"Calibri",sans-serif;color:#1E68FF'>charles@software-henker.ch</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'>Internet:
</span><span lang=DE style='font-family:"Calibri",sans-serif;color:#1E68FF'>www.software-henker.ch</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'>headquarters
of the company: St. Gallen</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'>Register
court: Amtsgericht St. Gallen</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'>Registernumber:
CHE-225.305.468</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'>(hereinafter
referred to as the &quot;service provider&quot;) and the customer (hereinafter
referred to as the „user&quot;, collectively also called &quot;user
parties&quot;) of the service provider.</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>1.2.</span><span lang=DE
style='font-family:"Calibri",sans-serif'> These terms and conditions apply to
users who make use of the paid web application from www.mysupersuccess.com for
personal or family use and who are not related to the professional or
commercial activity of users. These general terms and conditions also apply to
user parties who do not fall under the above definition.</span></p>

<p class=MsoListParagraph style='margin-left:42.55pt'><span lang=DE
style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>1.3.</span><span lang=DE
style='font-family:"Calibri",sans-serif'> These terms and conditions apply in
particular to contracts for the provision of the paid web application from
www.mysupersuccess.com and its full content. This includes, for example, the
recording of to-do boards, the use of a timer, the display of a timeline for
all to-do boards, etc. - the list is not exhaustive.</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:35.4pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>1.4.</span><span lang=DE
style='font-family:"Calibri",sans-serif'> The general terms and conditions of
the service provider apply exclusively. If users use: internally conflicting or
supplementary general terms and conditions, their validity is hereby contradicted;
they only become part of the contract if the service provider has expressly
consented to this.</span></p>

<p class=MsoNormal style='margin-left:35.4pt'><span lang=DE style='font-family:
"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='text-indent:-18.15pt'><b><span lang=DE
style='font-family:"Calibri",sans-serif'>2.<span style=' "Times New Roman"'>
</span></span></b><b><span lang=DE style='font-family:"Calibri",sans-serif'>Conclusion
of contract</span></b></p>

<p class=MsoNormal><b><span lang=DE style='font-family:"Calibri",sans-serif'></span></b></p>

<p class=MsoListParagraph><span lang=DE>The presentation and promotion of the
web application of the service provider does not constitute represent a binding
offer on the part of the service provider to conclude a usage contract, but
serve to submit a binding offer to conclude a usage contract by users.</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>2.1.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>The service provider will immediately
confirm receipt of the order placed by the user in writing or text form (by
email). Such a confirmation does not constitute a binding acceptance of the
order, unless the acceptance is declared in addition to the confirmation of
receipt. The contract is concluded with the payment of the user contribution.</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoNormal style='margin-left:35.4pt'><span lang=DE>The service
provider stores the contractual provisions including the terms and conditions
Conclusion of a contract while safeguarding data protection and forwards these
users: inside Sending his order in text form (by email).</span></p>

<p class=MsoNormal style='margin-left:35.4pt'><span lang=DE style='font-family:
"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>2.2.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>Users can view the text of the
contract at </span><span class=Hyperlink0><span lang=DE style='color:black'><a
href="https://www.mysupersuccess.com"><span style='font-family:"Calibri",sans-serif;
color:black'><span style='color:black'>www.mysupersuccess.com</span></span></a></span></span><span
lang=DE style='font-family:"Calibri",sans-serif'>. The order data is stored in
the service provider's system and can be viewed and accessed by the user using
his or her access data in the password-protected user account.</span></p>

<p class=MsoListParagraph style='margin-left:19.1pt;text-indent:-19.1pt'><span
lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>2.3.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>Der Vertragsschluss erfolgt ausschließlich
in deutscher Sprache.</span></p>

<p class=MsoListParagraph style='margin-left:37.5pt'><span lang=DE
style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoNormal style='margin-left:35.4pt'><span lang=DE>Users must ensure
that the email address given for processing the order</span></p>

<p class=MsoNormal style='margin-left:35.4pt'><span lang=DE>Address is correct,
so that the e-mails sent by the service provider can be sent to this address
can be received. In particular, when using SPAM filters, users have to ensure
that everyone from the service provider or from the service provider is
involved with the order processing E-mails sent to commissioned third parties
can be delivered.</span></p>

<p class=MsoNormal style='margin-left:35.4pt'><span lang=DE style='font-family:
"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:37.2pt;text-indent:-19.95pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>3.<span style=' "Times New Roman"'>
</span></span><b><span lang=DE style=';font-family:"SofiaPro-Bold",serif'>REGISTER
</span></b></p>

<p class=MsoListParagraph style='margin-left:35.4pt'><span lang=DE
style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:43.1pt;text-indent:-25.25pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>3.1.</span><span lang=DE
style=';font-family:"SofiaPro-Regular",serif'>After registering
on the service provider's website, users receive a personal account. These
access data may not be passed on. Users are responsible for their safe storage
themselves. Registration under a false name and fictitious e-mail accounts is
not permitted. In the case of obviously fictitious information, the service
provider reserves the right to delete the account.</span></p>

<p class=MsoListParagraph style='margin-left:42.55pt'><span lang=DE
style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='text-indent:-18.15pt'><b><span lang=DE
style=';font-family:"SofiaPro-Bold",serif'>4.<span
style=' "Times New Roman"'> </span></span></b><b><span
lang=DE style=';font-family:"SofiaPro-Bold",serif'>USER'S
OBLIGATIONS</span></b></p>

<p class=MsoListParagraph style='margin-left:0'><b><span lang=DE
style=';font-family:"SofiaPro-Bold",serif'></span></b></p>

<p class=MsoListParagraph style='margin-left:35.95pt;text-indent:-25.25pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>4.1.</span><span lang=DE
style=';font-family:"SofiaPro-Regular",serif'>Users undertake
not to misuse the software. Furthermore, users undertake to prevent
unauthorized third party access to the software by taking suitable precautions.
This includes keeping the password secret and not making it accessible to third
parties</span></p>

<p class=MsoListParagraph style='margin-left:19.1pt;text-indent:-19.1pt'><span
lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='text-indent:-18.15pt'><b><span lang=DE
style=';font-family:"SofiaPro-Bold",serif'>5.<span
style=' "Times New Roman"'> </span></span></b><b><span
lang=DE style=';font-family:"SofiaPro-Bold",serif'>SOFTWARE
LICENSE</span></b></p>

<p class=MsoNormal><span lang=DE style=';font-family:"SofiaPro-Regular",serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style=';font-family:"SofiaPro-Regular",serif'>5.1.</span><span
lang=DE style=';font-family:"SofiaPro-Regular",serif'>The
service provider provides users with the latest version of the software over
the Internet for the duration of the subscription. All rights of use not
expressly granted remain with the service provider or, if different, with the
respective author. For the purpose of operating the software, the service
provider saves the software on a server that is accessible to users via the
Internet. The service provider provides users with free updates during the
contract period. There are no additional costs for support and upgrades.</span></p>

<p class=MsoListParagraph style='margin-left:42.55pt'><span lang=DE
style=';font-family:"SofiaPro-Regular",serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style=';font-family:"SofiaPro-Regular",serif'>5.2.</span><span
lang=DE style=';font-family:"SofiaPro-Regular",serif'> The
service provider continuously monitors the functionality of the software and
removes all software errors that limit the use of the software or make it
impossible, depending on the technical possibilities.</span></p>

<p class=MsoListParagraph style='margin-left:42.55pt'><span lang=DE
style=';font-family:"SofiaPro-Regular",serif'></span></p>

<p class=MsoListParagraph style='margin-left:43.1pt;text-indent:-25.25pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>5.3.</span><span lang=DE
style=';font-family:"SofiaPro-Regular",serif'> The service
provider is entitled to add and remove new functions to the software.</span></p>

<p class=MsoListParagraph style='margin-left:19.1pt;text-indent:-19.1pt'><span
lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='text-indent:-18.15pt'><b><span lang=DE
style='font-family:"Calibri",sans-serif'>6.<span style=' "Times New Roman"'>
</span></span></b><b><span lang=DE style='font-family:"Calibri",sans-serif'>Right
of withdrawal</span></b></p>

<p class=MsoListParagraph><b><span lang=DE style='font-family:"Calibri",sans-serif'></span></b></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>6.1.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>Users have a right of withdrawal
within two months at the end of each month from the start of the contract.</span></p>

<p class=MsoListParagraph style='margin-left:19.1pt;text-indent:-19.1pt'><span
lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>6.2.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>Availability and usability continue to
apply until the user contract is revoked.</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>6.3.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>Further information on the right of
withdrawal can be found in the following Cancellation policy of the service
provider.</span></p>

<p class=MsoNormal style='text-indent:35.4pt'><span lang=DE style='font-family:
"Calibri",sans-serif'></span></p>

<p class=MsoNormal style='text-indent:35.4pt'><span lang=DE style='font-family:
"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='text-indent:-18.15pt'><b><span lang=DE
style='font-family:"Calibri",sans-serif'>7.<span style=' "Times New Roman"'>
</span></span></b><b><span lang=DE style='font-family:"Calibri",sans-serif'>Contract
term and termination</span></b></p>

<p class=MsoNormal style='margin-left:35.4pt'><span lang=DE style='font-family:
"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>7.1.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>When the contract is concluded, it is
valid for one month. The contract is extended by a further month if it is not
terminated in good time. The contract can be terminated at any time 14 days to
the end of the month by users in their user profile and this in turn can be
deleted.</span></p>

<p class=MsoListParagraph style='margin-left:42.55pt'><span lang=DE
style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>7.2.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>When registering for the first time
and concluding a contract at the same time, the user service is not due for the
first two months. This means that www.mysupersuccess.com can be used free of
charge for two weeks after the user has registered and signed a contract. If
the contract is not in accordance with point 7.1. canceled, the next commenced
month will be charged.</span></p>

<p class=MsoNormal style='text-indent:35.4pt'><span lang=DE style='font-family:
"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='text-indent:-18.15pt'><b><span lang=DE
style='font-family:"Calibri",sans-serif'>8.<span style=' "Times New Roman"'>
</span></span></b><b><span lang=DE style='font-family:"Calibri",sans-serif'>Availability
and usability</span></b></p>

<p class=MsoListParagraph><b><span lang=DE style='font-family:"Calibri",sans-serif'></span></b></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>8.1.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>Availability for users is guaranteed
immediately after the payment process has been completed. The web application
can thus be used immediately.</span></p>

<p class=MsoListParagraph style='margin-left:42.55pt'><span lang=DE
style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>8.2.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>The availability of the support in the
form of a possibility to contact is given permanently. Inquiries or information
are possible at any time via the contact details mentioned in point 1 by email.
The response time is three days.</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='text-indent:-18.15pt'><b><span lang=DE
style='font-family:"Calibri",sans-serif'>9.<span style=' "Times New Roman"'>
</span></span></b><b><span lang=DE style='font-family:"Calibri",sans-serif'>Prices
and terms of payment</span></b></p>

<p class=MsoListParagraph><b><span lang=DE style='font-family:"Calibri",sans-serif'></span></b></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>9.1.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>Unless otherwise stated in the service
provider's offer, the prices given are total prices. The stated total prices
are in US dollars and are gross prices including the statutory sales tax
applicable on the day of invoicing.</span></p>

<p class=MsoListParagraph style='margin-left:19.1pt;text-indent:-19.1pt'><span
lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoNormal style='margin-left:35.4pt'><span lang=DE>When selecting the
payment method, payment is processed via the</span></p>

<p class=MsoNormal style='margin-left:35.4pt'><span lang=DE>Payment service
provider Stripe Inc. subject to the Stripe Terms of Use. These can be viewed at
https://www.stripe.com/de/ssa#section_a_translation. During the ordering
process, users are redirected from the confirmation page for the paid use of
the service provider's web application to the Stripe Inc. website. After
entering the payment data and selecting the desired payment method, users
confirm a payment instruction to Stripe Inc. by clicking the button that
concludes the order process. After confirming the payment instruction, the
service provider requests Stripe Inc. to initiate the payment transaction and
accepts the offer from the user on.</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>9.2.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>The time at which the contract is
concluded between the parties depends on the payment method chosen by the user.</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='text-indent:-18.15pt'><b><span lang=DE
style='font-family:"Calibri",sans-serif'>10.<span style=' "Times New Roman"'>
</span></span></b><b><span lang=DE style='font-family:"Calibri",sans-serif'>Liability
for Damage</span></b></p>

<p class=MsoListParagraph><b><span lang=DE style='font-family:"Calibri",sans-serif'></span></b></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>10.1.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>With regard to the services provided
by the service provider, the service provider, its legal representatives and
vicarious agents are not liable for damage caused by the use of the software.</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style=';font-family:"SofiaPro-Regular",serif'>10.2.</span><span
lang=DE style='font-family:"Calibri",sans-serif'>Furthermore, the service
provider, his legal representatives and his vicarious agents are not liable for
damage caused by unauthorized access to personal user data by third parties,
e.g. by unauthorized access by hackers to the database. The same applies to
details and information that users themselves have made available to third
parties and are misused by these third parties.</span></p>

<p class=MsoNormal><span lang=DE style=';font-family:"SofiaPro-Regular",serif'></span></p>

<p class=MsoNormal><span lang=DE style=';font-family:"SofiaPro-Regular",serif'></span></p>

<p class=MsoNormal><span lang=DE style=';font-family:"SofiaPro-Regular",serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>10.3.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>Essential contractual obligations are
those obligations that the contract imposes on the service provider imposed on
its content to achieve the purpose of the contract, the fulfilment of which the
proper execution of the contract is only made possible and on their compliance
Users: may regularly trust (so-called cardinal obligations).</span></p>

<p class=MsoListParagraph style='margin-left:19.1pt;text-indent:-19.1pt'><span
lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>10.4.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>The exclusion of gross negligence or
intent remains unaffected by the exclusion of liability.</span></p>

<p class=MsoListParagraph style='margin-left:42.55pt'><span lang=DE
style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>10.5.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>Otherwise, liability on the part of
the service provider is excluded.</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='text-indent:-18.15pt'><b><span lang=DE
style='font-family:"Calibri",sans-serif'>11.<span style=' "Times New Roman"'>
</span></span></b><b><span lang=DE style='font-family:"Calibri",sans-serif'>PRIVACY
POLICY</span></b></p>

<p class=MsoListParagraph><b><span lang=DE style='font-family:"Calibri",sans-serif'></span></b></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>11.1.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>11.1.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>The service provider collects and
stores the user data necessary for business transactions. When processing
users' personal data, the service provider complies with the statutory
provisions. The service provider is entitled to transmit this data to third
parties commissioned with the execution of the order, insofar as this is
necessary for the fulfilment of the contract. Further details can be found in
the data protection provisions of the service provider available at </span><span
class=Hyperlink0><span lang=DE style='color:black'><a
href="https://www.mysupersuccess.com"><span style='font-family:"Calibri",sans-serif;
color:black'><span style='color:black'>www.mysupersuccess.com</span></span></a></span></span><span
lang=DE style='font-family:"Calibri",sans-serif'>.</span></p>

<p class=MsoListParagraph style='margin-left:19.1pt;text-indent:-19.1pt'><span
lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>11.2.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>On request, users receive information
about the data stored on the respective person at any time.</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>11.3.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>In addition, the statutory data
protection provisions apply, in particular the Swiss federal law on data protection.</span></p>

<p class=MsoListParagraph style='margin-left:19.1pt;text-indent:-19.1pt'><span
lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>11.4.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>The service provider holds the
copyrights to all texts, images and films that are published on its website.
Use of the images, films and texts is not permitted without the express consent
of the service provider.</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='text-indent:-18.15pt'><b><span lang=DE
style='font-family:"Calibri",sans-serif'>12.<span style=' "Times New Roman"'>
</span></span></b><b><span lang=DE style='font-family:"Calibri",sans-serif'>Final
provisions</span></b></p>

<p class=MsoListParagraph><b><span lang=DE style='font-family:"Calibri",sans-serif'></span></b></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>12.1.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>For these terms and conditions and the
contractual relationship between the parties, the law of the Swiss
Confederation applies to the exclusion of uniform international law, in
particular the UN sales law. For consumers, this choice of law only applies
insofar as the protection granted by mandatory provisions of the law of the
state in which the consumer has his habitual residence is not withdrawn.</span></p>

<p class=MsoNormal><span lang=DE style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoListParagraph style='margin-left:42.55pt;text-indent:-24.7pt'><span
lang=DE style='font-family:"Calibri",sans-serif'>12.2.</span><span lang=DE
style='font-family:"Calibri",sans-serif'>For users: the general place of
jurisdiction in accordance with relevant European agreements results from the
seat of the service provider.</span></p>

<p class=MsoListParagraph style='margin-left:42.55pt'><span lang=DE
style='font-family:"Calibri",sans-serif'></span></p>

<p class=MsoNormal><b><span lang=DE style='font-family:"Calibri",sans-serif'>DATE:</span></b><span
lang=DE style='font-family:"Calibri",sans-serif'> OCTOBER 2021</span></p>

</div>




 </div>
</div>
</template>

<script>
import NavbarHome from "@/components/navbar/NavbarHome";
export default {
  name: "GeneralTermsAndConditions",
  components: {
    NavbarHome
  },
}
</script>

<style scoped>

</style>