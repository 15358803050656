<template>
<NavbarHome />

		<section class="one-page" style="background-color: black; min-height: 1200px;">
            <div class="form-section">
                <section class="form-title text-white ">
                    <h1 class="pt-5   form-title--pre text-gray-600">Register</h1>
                    <h3 class="form-title--heading my-3">Welcome to Super Success</h3>
                </section>

                <Form @submit="onSubmit"
                      :validation-schema="schema"
                      v-slot="{ errors }"
                      class="form-register flex-1">
                    <div  class="reigster-form">
                        <div class="bg-green-500 text-green-900 absolute py-4 w-100 rounded-xl alert-success"
                             id="registerAlert" style="visibility: hidden; top: -120px; right: 20px">
                          <p class="w-100" style="min-width:300px;">Account created successfully!</p>
                          <p class="w-100" style="min-width:300px;">You can Login now!</p>
                        </div>
                        <div v-if="error"
                           class="alert-danger bg-red-500 text-red-900 rounded-xl py-4">
                          <p class="w-100 px-4 ">{{error}}</p>
                        </div>

                        <div class="form-div text-white ">
                            <label for="" class="form-label">Name</label>
                            <input type="text" v-model="name" name="name" class="form-elem form-elem--name"
                                   data-id="name" autocomplete="off" placeholder="Charly Goodman" required>
                            <span class="note"></span>
                        </div>

                        <div class="form-div text-white ">
                            <label for="" class="form-label">Email</label>
                            <input type="text" v-model="email" name="email" class="form-elem form-elem--email"
                                   data-id="email" autocomplete="off" placeholder="charly.goodman@mail.com" required>
                            <span class="note"></span>
                        </div>

                        <div class="form-div text-white ">
                            <label for="" class="form-label">Password <small>(6 characters minimum)</small></label>
                            <input type="password" v-model="password" name="password" class="form-elem form-elem--pass"
                                   data-id="pass" autocomplete="off" placeholder="********" minlength="6" required>
                            <span class="note"></span>
                        </div>

                        <div class="form-div text-white ">
                            <label for="" class="form-label">Confirm Password</label>
                            <input type="password" v-model="password2" name="password2"
                                   class="form-elem form-elem--pass2" data-id="pass" autocomplete="off"
                                   placeholder="********" minlength="6" required>
                            <span class="note text-red-700 text-sm" id="confirmPass"></span>
                        </div>

                        <br>
                        <Field v-model="confirm"
                               type="checkbox"
                               :value="true"
                               id="acceptTerms"
                               name="acceptTerms"
                               style="height: 21px; width: 21px;" class="m-3"
                               :class="{ 'is-invalid': errors.acceptTerms }"
                               required></Field>
                        <small class="text-white">I have read the
                          <router-link to="/privacypolicy"
                              class="text-white font-weight-bold">
                            Privacy Policy
                          </router-link>
                          &
                          <router-link to="/generaltermsandconditions"
                              class="text-white font-weight-bold">
                            General Terms & Conditions
                          </router-link>
                          and agree.
                        </small>
                        <small class="invalid-feedback">{{errors.acceptTerms}}</small>
                          <br>

                        <div class="form-bottom flex items-center justify-between text-white ">

                          <button type="submit" @click="submit"
                                    class="menu-button my-3"
                                    id="registerBtn">Register
                            <div v-if="loading" class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                            </button>

                            <div class="goto-signup">Already have an account?
                              <br>
                                <router-link to="/login"
                                             style="text-underline: none" exact
                                             class="text-white">
                                  Login
                                </router-link>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
		</section>
</template>

<script>
import api from '../api';
import NavbarHome from "@/components/navbar/NavbarHome";
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';

export default {
    name: "Register",
    components: {
      NavbarHome,
      Form,
      Field,
    },
    data() {
        return{
            email: '',
            password: '',
            password2: '',
            name: '',
            errorMsg: '',
            error: false,
            loading : false,
            confirm: false,
        }
    },
  setup() {
        const schema = Yup.object().shape({
            acceptTerms: Yup.bool()
                .required('* required')
        });
        const onSubmit = values => {
            // display form values on success
            console.log('SUCCESS!! :-)\n\n' + JSON.stringify(values, null, 4));
        }
        return {
            schema,
            onSubmit
        };
    },
    methods: {
        submit(){
            const userRegisterData = {
                'email': this.email,
                'password': this.password,
                'password2': this.password2,
                'name': this.name
            }
            api.register(userRegisterData)
            .then((i) => {
              this.login()
              this.loading = true;
              console.log(i)
                if(i.status === 201) {
                  throw(i.response.data)
                }
                const registerAlert = document.querySelector('#registerAlert');
                registerAlert.style = `
                    visibility: visible;
                    width: 100%;
                `;

            })
            .catch(error => {
                // this.error = true;
                this.error = 'Something went wrong.';
                this.errorMsg = error;
                setTimeout(() => {
                    this.error = false;
                    this.loading = false;
                }, 3400);
            })
            /*setTimeout(() => {
                this.$router.push({ name: 'Login' });
            }, 3400)*/

        },
      async login(){
                this.loading = true;
                this.incorrectAuth = false;

                try {
                  const response = await this.$store.dispatch('userLogin', {
                    email: this.email,
                    password: this.password
                  })
                  // debugger // eslint-disable-line
                  this.loading = false;
                  console.log(response)
                  if (!response) {
                    this.errorMsg = 'invalid login'
                    this.incorrectAuth = true;
                    return;
                  }
                } catch(e) {
                  console.log(e)
                  this.loading = false;
                  this.incorrectAuth = true;
                  return;
                }
                await this.$store.dispatch('fetchMe')
                setTimeout(() => {
                    this.$router.push({ name: 'Home' });
                }, 3400)
            },
    }
}
</script>

<style scoped>
    .reigster-form{
        width: 400px;
        margin-right: auto;
        margin-left: auto;
    }

    .form-div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 1rem;
    }

    .form-label{
        font-size: .9rem;
        margin-bottom: .25rem;
    }

    .form-elem{
        width: 400px;
        height: 50px;
        background: #Ffffff;
        border: 0;
        border-radius: 17px;
        padding: 0em 1rem;
        outline: none;
    }
</style>