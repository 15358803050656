import Vue from 'vue'; //eslint-disable-line
import moment from "moment";


export default {
  install: (app) => {
    // Plugin code goes here
      app.config.globalProperties.formatDate = (dateObj, dateStr) => {
        return moment(dateObj).format(dateStr);
      },
       app.config.globalProperties.formatDuration = (duration) => {
        const etime =  duration ? duration.split(':') : 0;
        // debugger //eslint-disable-line
        const splitted = etime[0].replace(' days,', '').split(' ');
        let hours = etime[0];
        if (splitted.length > 1){
          hours = parseInt(splitted[0]) * 24 + parseInt(splitted[1]);
        }

        let estimated = Number(hours) + Number(etime[1] / 60);


        if (isNaN(estimated)){
          estimated = 1
        }
        return estimated.toFixed(2)
      }
  }
}