<template>
          <input type="checkbox" v-model="user.setting.send_timelog"
                 class="mx-3 mt-1"
                 style="width:34px; height:34px; background:white !important;
                border-radius:8px !important;" @change="saveUser(bool)">

</template>

<script>
import api from "@/api";
import {mapGetters} from "vuex";

export default {
  name: "HustleTime",
  data() {
    return {
      // id: this.user.setting.id
      }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'isPremiumUser', 'user',]),
    hustleTime: {
      get() {
        return this.user ? this.user.setting.send_timelog : true
      },
      set(val) {
        this.user.setting.send_timelog = val
      }
    }
  },
  methods: {
    async saveUser(bool) {
      console.log('this.user.setting.send_timelog', this.user.setting.send_timelog)
      console.log('this.user.setting.id', this.user.setting.id)
      console.log('bool', bool)
      // this.user.setting.send_timelog = bool
      const data = {
        user: this.user.setting.id,
        send_timelog: this.user.setting.send_timelog,
      }
      console.log('data', data)
      await api.updateHustleTime(data)
    },
  }
}
</script>

<style scoped>

table {
  min-width: 210px;
  max-width: 333px;
}
</style>