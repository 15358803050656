<template >

  <div class="row m-0"
       v-if="user"
       v-click-away="onClickAway"
        >
<!--            <span v-if="!teamMembers.request_seen"-->
<!--                  style="color: red; font-weight: 400; font-size: 8px">-->
<!--              new-->
<!--            </span>-->
    <div @click="boxASelected = !boxASelected" class="pt-3"
    style=""
    >

      <button class="menu-button"
              :key="board.id" v-for="board in $store.state.board"
              :to="{'name': 'BoardDetail', params: {id: board.id}}"
              :style="[board.id === Number($route.params.id) ? {fontWeight: '700'} : {},
              board.id !== Number($route.params.id) ? {display: 'none'} : {}]"
              >
              {{ board.board_title }}
      </button>

    </div>
  <div>
<!--    v-if="teamMembers"-->
  <div
      class="navbar-extended-supersuccess-teammembers"
     :class="boxASelected ? 'navbar-extended-supersuccess-teammembers show' : 'hidden'"
      >
    <board-access></board-access>
  </div>

  <div class="navbar-extended-supersuccess"
     :class="boxASelected ? 'navbar-extended-supersuccess show' : 'hidden'"
       @click="order"
      >

      <h3 class="pt-4 pb-2">Boards</h3>

      <table style="text-decoration-line: none !important; list-style: none; text-align: left; width: 100%;"
             class="pb-3">
        <tr :key="board.id"
            v-for="board in $store.state.board">
          <td style="width:100%;">
            <router-link :to="{'name': 'BoardDetail', params: {id: board.id}}"
                         style="text-decoration-line: none !important;">
              <p
                  @click="boxASelected = !boxASelected && this.scrollUp" href="#anchor"
                  :style="[board.id === Number($route.params.id) ? {fontWeight: '900'} : {}]"
                  style="text-decoration-line: none !important; color: black; padding-left: 1.3rem;
                        max-width: 88%; overflow: hidden; padding-top: 1rem;">
                  {{ board.board_title }}
                          <span v-if="board.teammembers.length > 0"
              style="border-radius: 8px; background-color: gold; font-size: 13px; padding: 5px;">TM</span>
<!--                {{ board.order.order }}.-->
<!--                <span :v- if="teamMembers.request_seen === true || board.user">/span>-->
              </p>
            </router-link>
          </td>

          <td
              class="py-2"
              style="max-width:144px; padding-right: 0.34rem">
              <button @click="increaseBoardOrder(board)" class="btn btn-outline-dark p-0" style="width: 34px">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up" viewBox="0 0 16 16">
                  <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z"/>
                </svg>
              </button>
              <button @click="decreaseBoardOrder(board)" class="btn btn-outline-dark p-0" style="width: 34px">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down" viewBox="0 0 16 16">
                  <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                </svg>
              </button>
          </td>
          <td style="width:6%;" class="">
            <i @click="showEditBoard = board" title="update board"
                class="fa fa-pencil p-2 btn btn-outline-dark"
            />
          </td>
          <td style="width:6%; padding-left: 0.34rem; padding-right: 0.34rem" class="">
            <i title="delete board" @click="setIsMessage(board)"
                class="fa fa-trash remove-icon p-2 btn btn-outline-dark"/>
          </td>
        </tr>
      </table>

    <button-add-board></button-add-board>

  </div>

  <MessageBox :show="isMessage" v-if="currentBoard" :setShow="setIsMessage"
              :comment="`Do you want delete ${currentBoard.board_title}?`"
              type="yesno"
              @draggable = "false" :run="deleteBoard" />


    <edit-board :show="shouldShowBoard" :board="showEditBoard"
                :setShow="setShowBoard"></edit-board>

  </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MessageBox from "./MessageBox";
import { mixin as VueClickAway } from "vue3-click-away";
import ButtonAddBoard from "@/components/UI/ButtonAddBoard";
import EditBoard from "@/components/EditBoard";
import BoardAccess from "@/components/UI/BoardAccess";

export default {
  name: "Navigation",
  mixins: [VueClickAway],
  components: {
    EditBoard,
    ButtonAddBoard,
    MessageBox,
    BoardAccess,
  },
  props: ['board'],
  data() {
    return {
      boxASelected: false,
      isMessage: false,
      currentBoard: false,
      showAddBoard: false,
      showEditBoard: false,
    }
  },
  computed: {
    ...mapGetters(['isPremiumUser', 'user', 'boardOrder' ]),
    user() {
      return this.$store.state.user
    },

    shouldShowBoard() {
      if (!this.user) {
        return false;
      }
      if(this.$store.state.boardCount === 0) {
        return true
      }
      return typeof this.showEditBoard === 'object'
    },
    // order() {
    //   debugger // eslint-disable-line
    //   //this.$store.state.board.sort((a, b) => (a.order.order > b.order.order) ? 1 : (a.order.order < b.order.order) ? -1 : 0);
    // },
    boardOrder: {
      get() {
        const id = this.$store.state.user.id
        this.$store.dispatch('getBoardOrder', id);
        return this.$store.state.board
      },
      set() {
        const id = this.$store.state.user.id
        this.$store.dispatch('getBoardOrder', id);
        return this.$store.state.board
      }
    }
  },
  methods: {
    setShowBoard(val) {
      this.showEditBoard = val;
    },
    onClickAway() {
      this.boxASelected = false;
    },
    deleteBoard() {
      const id = this.currentBoard.id;
      this.currentBoard = false;
      this.isMessage = false;
      this.$store.dispatch('deleteBoard', id);
    },
    increaseBoardOrder(board) {
      this.currentBoardId = board.id;
      board.order.order -= 1
      this.currentBoardOrder = board.order;
      this.saveOrder(board)
    },
    decreaseBoardOrder(board) {
      // debugger // eslint-disable-line
      this.currentBoardId = board.id;
      console.log('currentBoardId', this.currentBoardId)

      // TODO: find board.id == boardOrder.board => boardOrder.id
      board.order.order += 1
      this.currentBoardOrder = board.order;
      console.log('currentBoardOrder', this.currentBoardOrder)
      this.saveOrder(board)
    },
    saveOrder(board) {
      console.log(board.id)
      const newOrder = {
        id: board.id,
        user: this.$store.state.user.id,
        boardOrderId: this.currentBoardOrder.id,
        order: this.currentBoardOrder.order,
      }
      this.$store.dispatch('saveBoardOrder', newOrder);
    },
    setIsMessage(board) {
      this.currentBoard = board;
      this.isMessage = true;
    },
    setNewOrder(board) {
      this.currentBoard = board;
    },
    onStart() {
      const html = document.getElementsByTagName('html').item(0);
      html.className = "";
    },
    onEnd() {
      const html = document.getElementsByTagName('html').item(0);
      html.className = "";
    },
  },
}



</script>

<style scoped>
/*.header{*/
/*    border-bottom-left-radius: 17px;*/
/*    border-bottom-right-radius: 17px;*/
/*    background-color: #fff;*/
/*}*/
.navbar-small-supersuccess {
  height: 100vh;
  width: 55px;
  background-color: #f2e641;
  position: fixed;
  overflow: visible;
  z-index: 100; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
}
.navbar-extended-supersuccess {
  /*height: 100vh;*/
  min-width: 377px;
  max-width: 432px;
  /*border: orange solid 3px;*/
  position: absolute;
  overflow: visible;
  z-index: 100;
  top: 5.5rem !important;
  text-decoration-line: none !important;
  /*left: 144px;*/
  /*transform: translateX(+50%);*/
  background-color: #fff;
  border-radius: 13px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.navbar-extended-supersuccess-teammembers {
  min-width: 300px;
  max-width: 610px;
  justify-content: center;
  position: absolute;
  overflow: visible;
  z-index: 100;
  top: 5.5rem !important;
  text-decoration-line: none !important;
  margin-left: 610px;
  /*transform: translateX(+50%);*/
  background-color: #fff;
  border-radius: 13px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.hidden {
  visibility: hidden;
}

.show {
  visibility: visible !important;
}

</style>