<template>
  <transition name="modal" v-if="show">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <h3>Rename Status</h3>
            </slot>
          </div>
          <div class="modal-body">
            <slot name="body">
              <input class="modal-input" type="text" v-model="form.title"
                     placeholder="Title *">
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button class="menu-button order-0" @click="close">
                Close
              </button>
              <button class="menu-button order-1" @click="save">
                Save
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <MessageBox :show="isMessage" :setShow="setIsMessage"
              comment="You should input title field!" type="alert" />
</template>

<script>
import MessageBox from './MessageBox';

export default {
  name: 'EditStatus',
  props: ['show', 'setShow', 'status'],
  components: {
    MessageBox
  },
  data() {
    return {
      form: {
        title: '',
        id: ''
      },
      isMessage: false,
    };
  },
  watch: {
    status() {
      this.form.title = this.status.title;
      this.form.id = this.status.id;
    }
  },
  methods: {
    close() {
      this.title = '';
      this.setShow(false);
    },
    save() {
      if (this.form.title) {
        if(this.form.id > 0) {
          this.$store.dispatch('updateStatus', {title: this.form.title, id: this.form.id});
        }
        this.close();
      } else this.setIsMessage(true);
    },
    setIsMessage(val) { 
      this.isMessage = val; 
    }
  },
};
</script>
