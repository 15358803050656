
export default {
  getBoardMutation(state, data) {
    state.board = data.results;
    state.boardCount = data.count;
  },
  getTeamChatMutation(state, data) {
    // debugger // eslint-disable-line
    state.teamChat = data.results;
    state.teamChatCount = data.count;
    state.teamChat.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : (a.timestamp < b.timestamp) ? -1 : 0);
    // console.log('teamChat:', data);
  },
  getMoreTeamChatMutation(state, data) {
    // debugger // eslint-disable-line
    // state.teamChat = data.links.next;
    state.teamChat = data.results;
    state.teamChatCount = data.count;
    state.teamChat.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : (a.timestamp < b.timestamp) ? -1 : 0);
    // console.log('teamChat:', data);
  },
  updateTeamMemberMutation() {
    // state.teamMembers = data.results.filter(val => val.data !== data);
  },
  updateBlockedUser() {

  },
  getTeamMemberMutation(state, data) {
    state.teamMembers = data.results.filter(val => val.data !== data);
    state.teamMemberCount = data.count;
  },
  getBoardOrderMutation(state, data) {
    // debugger // eslint-disable-line
    state.boardOrder = data;
    state.boardOrder.sort((a, b) => (a.order > b.order) ? 1 : (a.order < b.order) ? -1 : 0);
    // console.log('state.boardOrder =', data);
  },
  getBoardStatusMutation(state, data) {
    //debugger // eslint-disable-line
    state.boardStatus = data.results;
    // console.log(data.results);
    state.boardStatus.sort((a, b) => (a.order > b.order) ? 1 : (a.order < b.order) ? -1 : 0);
  },
  getTaskMutation(state, data) {
    state.tasks = data;
    state.tasks.sort((b, a) => (a.task_order > b.task_order) ? 1 : (a.task_order < b.task_order) ? -1 : 0);
    state.currentBoard = state.currentBoard === 0 ? state.board[0].id : state.currentBoard;
  },
  getTasklogMutation(state, data) {
    state.tasklog = data;
  },
  changeOrderMutation(state, newStatus) {
    const tempStatus = state.boardStatus.filter(val => (!newStatus.some(value => value.id === val.id)));
    state.boardStatus = tempStatus.concat(newStatus);
    
  },
  changeBoardMutation(state, newOrder) {
    const board = state.board.sort((a, b) => (a.order.order > b.order.order) ? 1 : (a.order.order < b.order.order) ? -1 : 0);
    state.board[board] = newOrder;
  },
  addBoardMutation(state, newBoard) {
    state.board.push(newBoard);
    state.boardCount = state.board.length;
  },
  deleteBoardMutation(state, id) {
    state.board = state.board.filter(val => val.id !== id);
  },
  deleteTeamChatMutation(state, id) {
    state.teamChat = state.teamChat.filter(val => val.id !== id);
  },
  deleteTeamMemberMutation(state, id) {
    state.teamMembers = state.teamMembers.filter(val => val.id !== id);
    },
  addBoardStatusMutation(state, newStatus) {
    state.boardStatus.push(newStatus);
    state.boardStatus.sort((a, b) => (a.order > b.order) ? 1 : (a.order < b.order) ? -1 : 0);
  },
  addTeamChatMutation(state, teamChat) {
    state.teamChat.push(teamChat);
    state.teamChat.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : (a.timestamp < b.timestamp) ? -1 : 0);
  },
  addTeamMemberMutation(state, newTeamMember) {
    // debugger // eslint-disable-line
    state.teamMembers.push(newTeamMember);
    //state.teamMembers.sort((a, b) => (a.user > b.user) ? 1 : (a.user < b.user) ? -1 : 0);
  },
  deleteBoardStatusMutation(state, id) {
    state.boardStatus = state.boardStatus.filter(val => val.id !== id);
  },
  editBoardStatusTitle(state, payload) {
    const boardStatusIndex = state.boardStatus.findIndex(i => i.id === payload.id)
    if (boardStatusIndex > -1) {
      state.boardStatus[boardStatusIndex].title = payload.title;
    }
  },
  addTaskMutation(state, task) {
    //state.tasks.push(task);
    const board = state.boardStatus.find(i => i.id === task.status);
    board.tasks.push(task);
  },
  editTaskMutation(state, task) {
    const board = state.boardStatus.find(i => i.id === task.status);

    board.tasks = board.tasks.filter(val => val.id !== task.id).concat(task);
    board.tasks.sort((b, a) => (a.task_order > b.task_order) ? 1 : (a.task_order < b.task_order) ? -1 : 0);
  },
  editEfficientlyTaskMutation(state, task) {
    const board = state.boardStatus.find(i => i.id === task.status);
    board.tasks = board.tasks.filter(val => val.id !== task.id).concat(task);
    // board.tasks.sort((b, a) => (a.task_order > b.task_order) ? 1 : (a.task_order < b.task_order) ? -1 : 0);
  },
  updateTasksMutation(state, { value, id, movedIndex }) {
    if(movedIndex > -1)
      state.tasks.map((val) => (val.id === value[movedIndex].id ? val.status = id : ''));
    value.map(async (val, index) => {
      value[index].task_order = value.length - index;
    });
    const tempTasks = state.tasks.filter((val) => (!value.some(value => value.id === val.id)));
    state.tasks = tempTasks.concat(value);
    state.tasks.sort((b, a) => (a.task_order > b.task_order) ? 1 : (a.task_order < b.task_order) ? -1 : 0);
  },
  deleteTaskMutation(state, id) {
    state.tasks = state.tasks.filter(val => val.id !== id);
  },
  addTasklogMutation(state, tasklog) {
    state.tasklog.push(tasklog)
    // TODO: addTasklogMutation
    // state.tasklog.sort((b, a) => (a.user > b.user) ? 1 : (a.user < b.user) ? -1 : 0);
    // # ordering: 'user__-start_time';
  },
  editTaskLogMutation(state, tasklog) {
    state.tasklog = state.tasklog.filter(val => val.id !== tasklog.id).concat(tasklog);
  },
  deleteTasklogMutation(state, id) {
    state.tasklog = state.tasklog.filter(val => val.id !== id);
  },
  resetToggleMutation(state, val) {
    state.isToggle = val;
  },
  resetTimerMutation(state) {
    state.isTimer = !state.isTimer;
  },
  resetMenuTimerMutation(state,  payload) {
    const {id, title} = payload;
    state.isMenuTimer = id === 0 ? false : true;
    state.timerTaskID = id;
    state.timerTaskTitle = title === '' ? false : true;
    state.timerTaskTitle = title;
  },
  resetTimerTaskIDMutation(state, id, title) {
    state.timerTaskID = id;
    state.timerTaskTitle = title;
  },
  changeWorkCountMutation(state, val) {
    // debugger // eslint-disable-line
    state.workCount = val;
  },
  changeHustleTimeCountMutation(state, val) {
    // debugger // eslint-disable-line

    state.hustleTimeCount = val;
    // console.log(state);
    // console.log(val);
  },
  changeBreakCountMutation(state, val) {
    state.breakCount = val;
  },
  changeBreathCountMutation(state, val) {
    state.breathCount = val;
  },
  setWorkRunMutation(state, val) {
    state.isWorkCount = val;
  },
  setBreakRunMutation(state, val) {
    state.isBreakCount = val;
  },
  setUserLogin(state, val) {
    state.userLoggedIn = val
  },
  setUser(state, user) {
    state.user = user
  },
  setCurrentTask(state, task) {
    state.currentTask = task
  },
  setBoardMaster(state, boardMaster) {
    state.userBoardMaster =  boardMaster
  },
};