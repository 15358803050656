import { createStore } from 'vuex';
import actions from "./actions";
import mutations from "./mutations";
import api from '../api';
import moment from 'moment';


const state = {
  board: [],
  boardOrder: [],
  boardCount: 0,
  boardStatus: [],
  tasks: [],
  tasklog: [],
  teamChat: [],
  teamMembers: [],
  boardAccess: [],
  teamChatCount: 0,
  teamMemberCount: 0,
  currentBoard: 0,
  isToggle: false,
  isTimer: false,
  isMenuTimer: false,
  timerTaskID: 0,
  timerTaskTitle: '',
  workCount: 30,
  breakCount: 10,
  hustleTimeCount: 30,
  isWorkCount: false,
  isBreakCount: false,
  isHustleTimeCount: false,
  currentTask: false,
  priority: [
    {name: 'Easy', color: 'background-color: RGBA(80, 196, 242, 0.8)'},
    {name: 'Medium', color: 'background-color: #8ff580'},
    {name: 'High', color: 'background-color: RGBA(242, 207, 80, 1)'},
    {name: 'Very-High', color: 'background-color: RGBA(242, 126, 80, 1)'},
    {name: 'Priority', color: 'background-color: RGBA(233, 69, 39, 1)'},
  ],
  userLoggedIn: localStorage.getItem('access_token') ,
  user: false,
  boardMaster: false,
};

if (localStorage.getItem('access_token')){
  api.setAuthToken(localStorage.getItem('access_token'));
}

const getters = {
  isLoggedIn: state => {
    return state.userLoggedIn
  },
  isPremiumUser: state => {
    if (!state.user) {
      return false
    }
    if (state.user.setting.premium_until) {
      return moment(state.user.setting.premium_until) > new moment()
    }
    return false
  },
  user: (state) => {
    return state.user;
  },
  boardOrder: (state) => {
    // debugger // eslint-disable-line
    console.log(state.boardOrder);
    return state.boardOrder;
  },
  boardMaster: state => {
    // debugger // eslint-disable-line
    // const board = state.board.findIndex(b => b.id == val.id);
    // state.currentBoard = state.currentBoard === 0 ? state.board[0].user : state.currentBoard;
    // console.log('currentBoard', state.board[0].id , state.user.setting.user);
    //state.board[0].user === state.user.setting.user
    if (state.user.setting.user) {
      return true;
    } else {
      return false;
    }
  },
  hustleTime: (state) => {
    return state.user.setting.hustle_time;
  },
  boardAccess: (state) => {
    return state.user.boardAccess;
  },
}

export default createStore({
  state,
  actions,
  mutations,
  getters,
});
