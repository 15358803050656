<template>
  <NavbarHome />

  <div class="row  py-3 row-max">
    <div class="mx-auto col-xl-6 col-lg-6 col-md-10 col-sm-11 col-xs-11 py-5">
        <h1 class="pb-5">Timeline</h1>
      <h2>TIME MANAGEMENT</h2>
      <div class="w-100">
          <img class="home-img" src="../assets/success.png">
        </div>
      <p>
		A beautiful Timeline is generated to plan & overview your projects.
		Furthermore, our Dashboard show you your time-use & give you a Trackrecord.
		The Promodoro Timer will keep you focused and remind you to make short breaks.

		Timeline to practice long-term thinking every single day.
	</p>
	<h1 class="">Think Longterm</h1>
	<h2 class="">Your Todos create your Future</h2>
	<!--<p>Long-Term organisation.</p>-->
  <div class="w-100">
    <img class="home-img" src="../assets/timeline_sample.png">
  </div>
	<ol>
		<li class="li">
			<span class="span">
				print your timeline,
			</span>
		</li>
		<li class="li">
			<span class="span">
				watch & visualize your Future every day,
			</span>
		</li>
		<li class="li">
			<span class="span">
				believe in your-self, YOU have the plan already!
			</span>
		</li>
	</ol>

    </div>
  </div>
  <div class="px-5">
      <div class="w-100">
    <img class="home-img" src="../assets/timeline.png">
  </div>
  </div>
</template>

<script>
import NavbarHome from "@/components/navbar/NavbarHome";
export default {
  name: "BlogTimeline",
  components: {
    NavbarHome
  },
}
</script>

<style scoped>

</style>